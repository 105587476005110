import {Pipe, PipeTransform} from '@angular/core';
import {BOOKING_TITLES} from '../../const';

@Pipe({
  name: 'exportTitles'
})
export class ExportTitlesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return BOOKING_TITLES.find(b => b.key === value).title;
  }

}
