import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Bookings } from '../../../models/bookings.interface';
import { ConfirmService } from '../../../components/confirm/confirm.service';
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bookingsv2-table',
  templateUrl: './bookingsv2-table.component.html',
  styleUrls: ['./bookingsv2-table.component.scss']
})
export class Bookingsv2TableComponent implements AfterViewInit, OnChanges {
  columnsData = ['status', 'bamba_id', 'res',
    'tour_name', 'pax', 'customer', 'purchase', 'departure', 'lead_traveller', 'gross_price',];

  @Input() data;

  selection = new SelectionModel<Bookings>(true, []);

  @Input() resultsLength = 0;
  @Input() isLoadingResults = true;

  pageSize = 10;

  @Output() pageChange: EventEmitter<{ page: number, pageSize: number }> = new EventEmitter<{ page: number, pageSize: number }>();

  @Input() page: number;

  @Input() filters: any;

  constructor(private confirmService: ConfirmService, private router: Router) {
  }

  sortData(event): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {

  }

  async cancelReservation(id: string): Promise<void> {
    const response = await this.confirmService.open('OK', 'Please contact bamba to cancel this reservation ');
    if (response) {
    }
  }

  detailBooking(id: string): void {
    this.router.navigate(['/darwin/booking/' + id]);
  }

  getCustomerName(row: any): string {
    if(row['BambaReservationID'] === 'b4103') {
      row['Customer'];
      console.log(row['BambaReservationID']);
    }

    const AccountTypeJsonString = (row['AccountUserAgent']) ? row['AccountUserAgent'] : null;

    if (AccountTypeJsonString) {
      const AccountType = JSON.parse(AccountTypeJsonString);
      
      if (AccountType['accountType']["slug"] === 'superadmin') {
        if (!row['Customer']) {
          return '---';
        }

        return (row['Customer']['PersonName']) ? row['Customer']['PersonName'] : "---";
      } else if (AccountType['accountType']["slug"] === 'agency'){
        return (AccountType['agency']['name']) ? AccountType['agency']['name'] : "---";
      } else {
        return (AccountType['store']['name']) ? AccountType['store']['name'] : "---";
      }

    } else {
      if (!row['Customer']) {
        return '---';
      }

      return (row['Customer']['PersonName']) ? row['Customer']['PersonName'] : row['Customer']['CompanyName'];
    }
  }

}
