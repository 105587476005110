import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductTypesComponent} from './product-types/product-types.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CarouselsLoaderComponent} from './carousels-loader/carousels-loader.component';
import {ContinentsLoaderComponent} from './continents-loader/continents-loader.component';
import {MatGridListModule} from '@angular/material';

@NgModule({
  declarations: [ProductTypesComponent, CarouselsLoaderComponent, ContinentsLoaderComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    MatGridListModule
  ],
  exports: [ProductTypesComponent, CarouselsLoaderComponent, ContinentsLoaderComponent]
})
export class SkeletonsLoaderModule {
}
