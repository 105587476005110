import { NgModule } from '@angular/core';
import { BookingJsonConfirmAndPayComponent } from './booking-json-confirm-and-pay.component';
import { MaterialModule } from '../../../../app.material';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BookingJsonConfirmAndPayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  exports: [BookingJsonConfirmAndPayComponent]
})
export class BookingJsonConfirmAndPayModule { }
 