import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlEspacios'
})
export class UrlEspaciosPipe implements PipeTransform {

  transform(url: string): string {
    let espacios:any = url.split(" ");
    espacios = espacios.join("-");
    espacios = espacios.split("/");
    espacios = espacios.join("&");
    espacios = espacios.split("(");
    espacios = espacios.join("&");
    espacios = espacios.split(")");
    espacios = espacios.join("&");
    return espacios;
  }

}
