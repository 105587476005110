import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Bookingsv2Component} from './bookingsv2.component';
import {Bookingsv2FilterComponent} from './bookingsv2-filter/bookingsv2-filter.component';
import {Bookingsv2TableComponent} from './bookingsv2-table/bookingsv2-table.component';
import {MaterialModule} from '../../app.material';
import {MatPaginatorModule, MatSortModule} from '@angular/material';
import {InputFiltersComponent} from './bookingsv2-filter/input-filters/input-filters.component';
import {BambaSharedModule} from '../../shared/bamba-shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {BookingExportComponent} from './booking-export/booking-export.component';
import {RouterModule} from '@angular/router';
import {ExportTitlesPipe} from './export-titles.pipe';
import {PresetsComponent} from './presets/presets.component';

@NgModule({
  declarations: [Bookingsv2Component, Bookingsv2FilterComponent, Bookingsv2TableComponent,
    InputFiltersComponent, BookingExportComponent, ExportTitlesPipe, PresetsComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    BambaSharedModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [Bookingsv2Component, Bookingsv2FilterComponent, Bookingsv2TableComponent],
  entryComponents: [BookingExportComponent]
})
export class Bookingsv2Module {
}
