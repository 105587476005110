<div class="dont-space">
  <div class="gap-travel">
    <p class="h-text">
      <strong>Don’t take our word for it</strong>
    </p>
    <p class="subt-text" style="margin-bottom: 0">Incredible travelers, leave us glowing reviews.</p>
  </div>
  <div class="row1" id="content">
    <div class="rate-box">
      <div class="row margin-space">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
                fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
      </div>
      <p class="review-text margin-expert">
        Loved this tour! If you are going to Peru Machu Picchu is a must and they delivered. Having all transportation, meals, accommodation and adventure meant I could enjoy without any worries. By far the best tour I did during my time in Peru!
      </p>
      <div class="text-img">
        <img class="" src="/assets/img/review/review1.png" alt=""/>
        <p class="reviewer-text" style="margin-bottom: 0">Jono M</p>
      </div>
    </div>
    <div class="rate-box">
      <div class="row margin-space">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
                fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
      </div>
      <p class="review-text margin-expert">
        I can honestly say that it exceeded my expectations. I was kind of nervous the night before thinking if I made the right choice ☺️, and definitely I did!! The service, the people, the expertise of the pilot made me feel secure and comfortable since the beginning.
      </p>
      <div class="text-img">
        <img class="" src="/assets/img/review/review2.png" alt=""/>
        <p class="reviewer-text" style="margin-bottom: 0">Maritza R</p>
      </div>
    </div>
    <div class="rate-box">
      <div class="row margin-space">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
                fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10 14.8391L13.6374 17.039C14.3035 17.4422 15.1186 16.8462 14.9433 16.0924L13.9792 11.9555L17.1959 9.16825C17.7831 8.6599 17.4676 7.69577 16.6963 7.63441L12.4629 7.27506L10.8064 3.36596C10.5084 2.65601 9.49164 2.65601 9.19364 3.36596L7.53709 7.26629L3.30369 7.62565C2.53239 7.687 2.21686 8.65113 2.8041 9.15949L6.02078 11.9467L5.05665 16.0837C4.88136 16.8375 5.69648 17.4335 6.36261 17.0303L10 14.8391Z"
            fill="#006064" fill-opacity="0.6"/>
        </svg>
      </div>
      <p class="review-text margin-expert">
        A lifetime experience in Mexico. A very nice experience on the balloon trip. It took us to see the pyramids from the top and very beautiful landscape. Romantic journey as well with a decent breakfast after the balloon journey.
      </p>
      <div class="text-img">
        <img class="" src="/assets/img/review/review3.png" alt=""/>
        <p class="reviewer-text" style="margin-bottom: 0">Huan Z</p>
      </div>
    </div>

  </div>

  <div style="display: flex; justify-content: center;">
    <a href="https://www.tripadvisor.com/Attraction_Review-g294314-d12361339-Reviews-Bamba-Cusco_Cusco_Region.html" target="_blank" class="word-btn">
      <p class="word-btn-text" style="margin-bottom: 0;">More Reviews</p>
    </a>
  </div>


</div>
