import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmComponent} from './confirm.component';
import {MatButtonModule, MatCardModule, MatDialogModule, MatIconModule} from '@angular/material';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [ConfirmComponent],
  entryComponents: [ConfirmComponent]
})
export class ConfirmModule {
}
