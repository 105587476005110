import {Component, OnInit, ElementRef, OnDestroy, HostListener} from '@angular/core';
import 'rxjs/add/operator/map';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Destinations, Themes} from '../../../models/header/header.model';
import {SistemaService} from '../../../services/sistema/sistema.service';
import {ThemesMenuService} from '../../../services/themes-menu.service';
import {Search, Country, MainTheme, Style} from '../../../models/search.model';
import {QuitSpacesService} from '../../../services/quit-spaces.service';
import {Router} from '@angular/router';

import 'rxjs/add/operator/map';

import * as $ from 'jquery';
import {AuthenticationService} from '../../../services/authentication.service';
import {IUser} from '../../../models/auth.interface';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ACCOUNT_TYPE} from '../../../const';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})

export class HeaderComponent implements OnInit, OnDestroy {
  destinationsRes: any[] = [];
  destinations: Destinations[] = [];
  destinationsSelected: Destinations = null;
  themesSelected: Themes = null;
  themes: Themes[] = [];
  themesRes: any[] = [];
  url: string;
  destinationSmart: MenuSmart[] = [];
  themesSmart: MenuSmart[] = [];
  destinationsSelectedTitle: string = '';
  themesSelectedTitle: string = '';

  // menu
  visibleDestinations: boolean = false;
  visibleThemes: boolean = false;

  // menu responsivo
  visibleSmartDestinations: boolean = true;
  visibleSmartThemes: boolean = true;
  headerCollapse: boolean = false;
  xxxx: boolean = false;

  //search
  browserForm: FormGroup;
  resultSearch: ResultSearch[] = [];
  search: Search;
  respuestasBool: boolean = false;
  letterSelected = '';//para saber de que tipo esta seleccionando el browserText
  boolInput: boolean = false;
  boolLogo: boolean = true;

  isAuthenticated = false;

  user: IUser;
  isStoreManager = false;
  isAgent = false;

  agentLogoURL = '';


  unsubscribe = new Subject();

  isAdmin = false;


  constructor(public _sistemaService: SistemaService,
              private _eref: ElementRef,
              private _themesService: ThemesMenuService,
              private http: HttpClient,
              private router: Router,
              private _quitSpacesService: QuitSpacesService,
              private authService: AuthenticationService) {
    this.url = _sistemaService.url;

    this.authService.getIsAuthenticated()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(authenticated => {
        this.isAuthenticated = authenticated;
        if (this.isAuthenticated) {
          this.user = AuthenticationService.getUser();
          this.isStoreManager = ACCOUNT_TYPE.STORE_MANAGER === AuthenticationService.getUser().accountType._id;
          this.isAgent = ACCOUNT_TYPE.AGENT === AuthenticationService.getUser().accountType._id;
          this.isAdmin = ACCOUNT_TYPE.SUPER_ADMIN === this.user.accountType._id;
          if (this.isAgent || this.isStoreManager) {
            this.agentLogoURL = this.user.store.logo;
          } else {
            if (this.user.agency && this.user.agency.logo) {
              this.agentLogoURL = this.user.agency.logo;
            }
          }
        } else {
          this.user = null;
        }
      });
  }

  onClick(event) {
    // <=768
    const w: number = $(window).width();
    if (w >= 992 && event.target.localName != 'span' && event.target.localName != 'mat-icon') {
      this.visibleDestinations = false;
      this.visibleThemes = false;
    }
  }

  /**
   * agrega el foco al input o quita el foco dependiendo del boolInput
   * @param id [id del elemento html]
   */
  setFocusInput(id: string) {
    if (this.boolInput) {
      $('#' + id).blur();
      // this.respuestasBool = false;

      // if (this.searchForm.controls['search'].value.length >= 3) {
      //   this.setValueText(this.searchForm.controls['search'].value);
      // }
    } else {
      $('#' + id).focus();
      if (this.browserForm.controls['browseText'].value.length >= 3) {
        this.respuestasBool = true;
      }
    }
    this.boolInput = !this.boolInput;
  }


  ngOnInit() {
    if (window.location.hostname.includes('bambaworld') || window.location.hostname.includes('localhost')) {
      this.boolLogo = false;
    } else {
      this.boolLogo = false;
    }

    $('#mat-input-0').attr('placeholder', 'Where');
    this.browserForm = new FormGroup({
      'browseText': new FormControl('', [
        Validators.pattern('([a-zA-Z]|á|é|í|ó|ú|Á|É|Í|Ó|Ú| )*')
      ])
    });

    this._themesService.getDestinations().subscribe(res => {
      this.destinations = res;
      for (let i = 0; i < this.destinations.length; i++) {
        this.destinationSmart.push({
          title: this.destinations[i].title.toUpperCase(),
          visibleCountrie: false,
          visibleDestination: true
        });
      }
    });
    this._themesService.getThemes().subscribe(res => {
      this.themes = res;
      for (let i = 0; i < this.themes.length; i++) {
        this.themesSmart.push({
          title: this.themes[i].name.toUpperCase(),
          visibleCountrie: false,
          visibleDestination: true
        });
      }
    });

  }

  @HostListener('document:keydown.enter', ['$event'])
  enter(): void {

  }

  /**
   * [Agrega el titulo seleccionado en una cookie para ser usado en otros componentes]
   * @param txt [Texto a ser persistido. Si es null se agrega un valor por defecto]
   */
  setMenuHome(txt: string = null) {
    localStorage.setItem('header', 'h');
    if (txt != null) {
      localStorage.setItem('headerTxt', txt);
    }
  }

  seeAllDestinations(id: string): void {
    this.destinationsSelected = this._themesService.seeAllDestinations(id);
  }

  seeAllThemes(id: string): void {
    this.themesSelected = this._themesService.seeAllThemes(id);
  }

  /**
   * [esconde o muestra los destinations dependiendo de el id]
   * @param id [id del componente que se afectara]
   * @param destination [titulo de la destinacion seleccionada]
   */
  destinationsHidden(id: number, destination: string): void {
    let esconderMostrar: boolean = !this.destinationSmart[id].visibleCountrie;
    if (esconderMostrar) {
      this.destinationsSelectedTitle = '';
    } else {
      this.destinationsSelectedTitle = destination;
    }
    for (let i = 0; i < this.destinationSmart.length; i++) {
      if (id == i) {
        this.destinationSmart[id].visibleDestination = true;
      } else {
        this.destinationSmart[i].visibleDestination = esconderMostrar;
        this.destinationSmart[i].visibleCountrie = false;
      }
    }
  }

  /**
   * [esconde o muestra los temas dependiendo de el id]
   * @param id [id del componente que se afectara]
   * @param themes [titulo de los temas seleccionados]
   */
  themesHidden(id: number, themes: string): void {
    let esconderMostrar: boolean = !this.themesSmart[id].visibleCountrie;
    if (esconderMostrar) {
      this.themesSelectedTitle = '';
    } else {
      this.themesSelectedTitle = themes;
    }
    for (let i = 0; i < this.themes.length; i++) {
      if (id == i) {
        this.themesSmart[id].visibleDestination = true;
      } else {
        this.themesSmart[i].visibleDestination = esconderMostrar;
        this.themesSmart[i].visibleCountrie = false;
      }
    }
  }


  /**
   * [cierra el menu del header]
   */
  estadoOriginal: boolean[] = [true, true];

  closeMenu(button: boolean = false) {
    $('#menuCollapse').removeClass('show');
    this.headerCollapse = false;
    this.themesSelectedTitle = '';
    this.destinationsSelectedTitle = '';
    if (this.destinationsSelected == null) {
      for (let i = 0; i < this.destinationSmart.length; i++) {
        this.destinationSmart[i].visibleDestination = true;
        this.destinationSmart[i].visibleCountrie = false;
      }
    }
    if (this.themesSelected == null) {
      for (let i = 0; i < this.themesSmart.length; i++) {
        this.themesSmart[i].visibleDestination = true;
        this.themesSmart[i].visibleCountrie = false;
      }
    }
    this.visibleSmartThemes = true;
    this.visibleSmartDestinations = true;
  }


  /**
   * [cambia de estado los booleanos de destination o de themes dependiendo del tipo]
   * @param type [tipo d destination t de themes]
   */
  back(type: string) {
    // destinationSmart[i].visibleDestination && !destinationSmart[i].visibleCountrie
    if (type == 'd') {
      if (this.destinationsSelected == null) {
        for (let i = 0; i < this.destinationSmart.length; i++) {
          this.destinationSmart[i].visibleDestination = true;
          this.destinationSmart[i].visibleCountrie = false;
        }
      }
    } else {
      if (this.themesSelected == null) {
        for (let i = 0; i < this.themesSmart.length; i++) {
          this.themesSmart[i].visibleDestination = true;
          this.themesSmart[i].visibleCountrie = false;
        }
      }
    }
  }

  /**
   * [cambia de estado los booleanos de destination o de themes dependiendo del tipo]
   * @param type [tipo d destination t de themes]
   */
  next(type: string) {
    if (type == 'd') {
      this.visibleDestinations = !this.visibleDestinations;
    } else {
      this.visibleThemes = !this.visibleThemes;
    }
    // if (this.visibleDestinations) {
    //   this.resetMenu();
    // }
  }

  /**
   * [agrega todas las variables a su estado original]
   */
  principal: boolean[] = [false, false];

  resetMenu() {
    this.themesSelectedTitle = '';
    this.destinationsSelectedTitle = '';
    this.destinationsSelected = null;
    this.themesSelected = null;
    this.visibleDestinations = false;
    this.visibleThemes = false;
    // if (this.destinationsSelected == null) {
    for (let i = 0; i < this.destinationSmart.length; i++) {
      this.destinationSmart[i].visibleDestination = true;
      this.destinationSmart[i].visibleCountrie = false;
    }
    // }
    // if (this.themesSelected == null) {
    for (let i = 0; i < this.themesSmart.length; i++) {
      this.themesSmart[i].visibleDestination = true;
      this.themesSmart[i].visibleCountrie = false;
    }
    // }
    this.visibleSmartThemes = true;
    this.visibleSmartDestinations = true;
    this.principal = [false, false];
  }

  // search
  closeActive: boolean = false;

  setSearchBar(closeActive) {
    this.closeActive = closeActive.closeActive;
  }

  /**
   * [redirecciona al componente shop]
   * @param name [nombre buscado para obtener el lemaxId]
   * @param origen [para saber de donde viene la solicitud de themes o destination]
   */
  filterShop(name: string, origen: string = '') {
    let url: string[] = [];
    url.push('shop');
    url.push('All');
    for (let i = 0; i < 6; i++) {
      url.push('0');
    }
    if (origen == 'seeAll') {
      for (let i = 0; i < this.themesSelected.themes.length; i++) {
        if (this.themesSelected.themes[i].name.toLowerCase() == name.toLowerCase()) {
          url.push(this.themesSelected.themes[i].lemaxId + '');
          break;
        }
      }
    } else {
      for (let i = 0; i < this.themes.length; i++) {
        for (let j = 0; j < this.themes[i].themes.length; j++) {
          if (this.themes[i].themes[j].name.toLowerCase() == name.toLowerCase()) {
            url.push(this.themes[i].themes[j].lemaxId + '');
            break;
          }
        }
      }
    }
    url.push('0');
    url.push('0');
    url.push('Relevance-High-to-Low');
    this.router.navigate(url);
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

export class MenuSmart {
  title: string;
  visibleDestination: boolean;
  visibleCountrie: boolean;
}

export class ResultSearch {
  letter: string;
  names: Data[];
}

export class Data {
  name: string;
  lemaxId: number;
  typeId: number;
  countrie: string;
  style: string;
  mainTheme: string;
}
