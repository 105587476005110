<div class="row filter_open mb-4">
  <div class="col-md-6 filter_booking">
    <mat-accordion>
      <mat-expansion-panel (afterCollapse)="detectExpansionStatus(false)" (afterExpand)="detectExpansionStatus(true)">
        <mat-expansion-panel-header style="max-height:40px">
          <mat-panel-title>
            <input matInput [formControl]="form.get('Search')"
                   (keydown.enter)="$event.stopPropagation();$event.preventDefault();searchBy()"
                   (click)="$event.stopPropagation()" type="text" placeholder="Filter booking by ID">
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filter_float row">
          <app-input-filters [form]="form"></app-input-filters>
          <div fxFlex fxLayoutAlign="end end">
            <button (click)="filter()" mat-raised-button class="btn_filter">Filter</button>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="auth?.accountType?._id === ACCOUNT_TYPE.SUPER_ADMIN" class="col-md-3 selectAllStores">
    <mat-form-field appearance='outline'>
      <mat-label> Purchase Type</mat-label>
      <mat-select [formControl]="form.get('BranchOffice')">
        <mat-option [value]="null">All Purchase Types</mat-option>
        <mat-option *ngFor="let b of buyers" [value]="b['lemaxId']">
          {{ b.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3 filter_lastColum">
    <div class="row filter_btn_icon">
      <div class="col-10 col-md-9 filter_button">
        <button [routerLink]="['']" mat-raised-button>NEW BOOKING</button>
      </div>
      <div class='col-2 col-md-3 filter_icon'>
        <button (click)="export()" mat-icon-button aria-label="Example icon-button with a heart icon">
          <!-- <mat-icon>vertical_align_bottom</mat-icon> -->
          <img src="../../../../assets/icons/Export_Icon.svg" alt="icon_export">
        </button>
      </div>
    </div>
  </div>


</div>
