import {NgModule} from '@angular/core';
import {SearchAutocompleteComponent} from './search-autocomplete.component';
import {BambaSharedModule} from '../../shared/bamba-shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResponsiveModule} from 'ngx-responsive';
import { CorrectSearchTitlesPipe } from './correct-search-titles.pipe';

@NgModule({
  declarations: [SearchAutocompleteComponent, CorrectSearchTitlesPipe],
    imports: [
        BambaSharedModule,
        ReactiveFormsModule,
        FormsModule,
        ResponsiveModule
    ],
  exports: [SearchAutocompleteComponent]
})
export class SearchAutocompleteModule {
}
