import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {BookingsService} from '../../services/bookings.service';
import {BookingRequest, Bookings} from '../../models/bookings.interface';
import {parseFieldDateObject} from '../../core/utils';
// import {Workbook} from 'exceljs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import {ProgressSpinnerOverlayService} from '../../components/progress-spinner-overlay/progress-spinner-overlay.service';
import {BOOKING_TITLES, PASSENGERS_TITLES} from '../../const';
import {Bookingsv2TableComponent} from './bookingsv2-table/bookingsv2-table.component';
import {MatPaginator} from '@angular/material';

@Component({
  selector: 'app-bookingsv2',
  templateUrl: './bookingsv2.component.html',
  styleUrls: ['./bookingsv2.component.scss']
})
export class Bookingsv2Component implements OnInit, AfterViewInit {
  bookings: Bookings[];
  resultsLength = 0;
  isLoadingResults = true;

  filters;

  titles = BOOKING_TITLES;

  @ViewChild(Bookingsv2TableComponent) tableComponent: Bookingsv2TableComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() showFilters = true;

  changePage = true;

  constructor(private bookingService: BookingsService, private overlayService: ProgressSpinnerOverlayService) {
  }

  ngOnInit() {
    this.getBookings();
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .subscribe(page => {
        if (this.changePage) {
          this.pageChange({page: page.pageIndex + 1, pageSize: page.pageSize});
        }
      });
  }

  cleanAgencyAndStore(filters): any {
    const newFilters = {...filters};
    if (filters['Store']) {
      newFilters['Agency'] = null;
      newFilters['SuperAgency'] = null;
      return newFilters;
    }
    if (filters['Agency']) {
      newFilters['SuperAgency'] = null;
      return newFilters;
    }
    return newFilters;
  }

  setFilters(filters, changePage = false): void {
    this.filters = this.cleanAgencyAndStore(filters);
    this.changePage = changePage;
    if (!this.changePage) {
      this.paginator.pageSize = 10;
      this.paginator.pageIndex = 0;
    }
    this.getBookings(parseFieldDateObject(this.filters, ['PurchaseDateFrom', 'PurchaseDateTo', 'DepartureFrom', 'DepartureTo']));
  }

  async getBookings(request?: BookingRequest): Promise<void> {
    this.isLoadingResults = true;
    const response = await this.bookingService.list(request).toPromise();
    this.resultsLength = response.Pagination.TotalResults;
    this.bookings = response.Results.map(r => {
      r.GrossPriceFormated = `$ ${r.GrossPrice} USD`;
      return r;
    });
    this.isLoadingResults = false;
    this.changePage = true;
  }

  async getBookingsForExport(): Promise<any> {
    return await this.bookingService.list({...this.filters, PageSize: this.resultsLength >= 1000 ? 1000 : this.resultsLength}).toPromise();
  }

  export(data: { exported: string[], passenger: false }): void {
    this.generateExcel(data.exported, data.passenger);
  }

  generateExcel(fields: string[], passenger = false): void {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Bookings');
    const overlayRef = this.overlayService.open({hasBackdrop: true});
    worksheet.addRow(this.generateTitles(fields, passenger));
    this.getBookingsForExport().then(response => {
      const fieldsData = passenger ? this.generateDataPassengers(response.Results) : this.generateData(response.Results);
      console.log(fieldsData);
      const data = passenger ? fieldsData.map(f => f.map(ff => ff.value)) : fieldsData.map(d => {
        return d.filter(f => fields.indexOf(f.key) > -1).map(f => f.value);
      });
      console.log(data);
      data.forEach(b => {
        worksheet.addRow(b);
      });
      overlayRef.detach();

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fs.saveAs(blob, 'bookings.xlsx');
      });
    });
  }

  generateTitles(fields: string[], passenger = false): string[] {
    if (passenger) {
      return PASSENGERS_TITLES.map(p => p.title);
    }
    return this.titles.filter(t => fields.indexOf(t.key) > -1).map(t => t.title);
  }

  generateData(data): { key: string, value: any }[][] {
    return data.map(row => {
      const response = [
        {
          key: 'lemax_id',
          value: row['ReservationLemaxID']
        },
        {
          key: 'bamba_id',
          value: row['BambaReservationID']
        },
        {
          key: 'res',
          value: row['AgentReferenceNumber']
        },
        {
          key: 'tour_name',
          value: row['TourName']
        },
        {
          key: 'pax',
          value: row['PassengerForRooms'] ? row['PassengerForRooms'][0]['Passengers'].length : 0
        },
        {
          key: 'customer',
          value: row['Customer'] ? row['Customer']['CompanyName'] : ''
        },
        {
          key: 'purchase',
          value: row['PurchaseDate']
        },
        {
          key: 'departure',
          value: row['DepartureDate']
        },
        {
          key: 'lead_traveller',
          value: row['LeadTraveller'] ? row['LeadTraveller']['FullName'] : ''
        },
        {
          key: 'gross_price',
          value: row['GrossPriceFormated']
        },
        {
          key: 'status',
          value: row['StatusName']
        },
        ...Object.keys(row).map(k => {
          return {key: k, value: row[k]};
        }),
      ];
      return response;
    });
  }

  generatePassenger(data: Bookings) {
    const passengers = [];
    data.PassengerForRooms.forEach(p => {
      p.Passengers.forEach(pp => {
        passengers.push(
          [
            {
              key: 'tour',
              value: data.Adventures && data.Adventures.length ? data.Adventures[0].TourName : ''
            },
            /*{
              key: 'end_date',
              value: (data as any).ConfirmAndPayJson && (data as any).ConfirmAndPayJson.ReservationItems.length ?
                (data as any).ConfirmAndPayJson.ReservationItems[0].EndDate : null
            },*/
            {
              key: 'DepartureDate',
              value: data.DepartureDate
            },
            {
              key: 'customer',
              value: data.Customer.CompanyName
            },
            {
              key: 'customer_email',
              value: data.Customer.Email
            },
            {
              key: 'gross_price',
              value: data.GrossPrice
            },
            {
              key: 'StartDestinationCountry',
              value: (data as any).StartDestinationCountry
            },
            {
              key: 'Age',
              value: pp.Age
            },
            {
              key: 'DateOfBirth',
              value: pp.DateOfBirth
            },
            {
              key: 'Email',
              value: pp.Email
            },
            {
              key: 'Gender',
              value: pp.Gender
            },
            {
              key: 'MobilePhone',
              value: pp.MobilePhone
            },
            {
              key: 'Name',
              value: pp.Name
            },
            {
              key: 'LastName',
              value: pp.Surname
            },
            {
              key: 'Nationality',
              value: pp.Nationality
            },
            {
              key: 'PassengerID',
              value: pp.PassengerID
            },
            {
              key: 'PassportNumber',
              value: pp.PassportNumber
            },
          ]
        );
      });
    });
    return passengers;
  }


  generateDataPassengers(data: Bookings[]): { key: string, value: any }[][] {
    const passengers = [];
    data.filter(d => d.PassengerForRooms && d.PassengerForRooms.length).forEach(d => passengers.push(...this.generatePassenger(d)));
    return passengers;
  }

  pageChange(page): void {
    this.setFilters({...this.filters, ...{PageSize: page.pageSize, CurrentPage: page.page}}, true);
  }
}
