import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SistemaService {
  public url = '';
  footerHeaderVisible: boolean = true;
  urlActual: string[] = [];
  urlAnterior: string[] = [];
  chatAlternoVisible: boolean = false;
  logger: boolean = false;
  baseUrl: string;

  hideFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
    private router: Router) {

    this.baseUrl = environment.baseUrl;
    this.url = environment.apiUrl;
    this.logger = environment.logger;
  }

}
