<div class="padding-responsive">
    <app-home-banner class="parallax" [ngClass]="agent ? 'h-img-logged':'h-img-logged-out'" ></app-home-banner>
</div>
<hr class="hr-vertical hr-1" *ngIf="!agent">
<app-experience-the-word class="home-section-padding" style="background: var(--surface-surface-1, #F9FBFB);" *ngIf="!agent"></app-experience-the-word>
<app-bookingsv2 [showFilters]="false" *ngIf="agent"></app-bookingsv2>
<app-travel-types class="home-section-padding" [travelStyles]="travelStyles" *ngIf="!agent"></app-travel-types>
<!--<app-travel-recommendends *ngIf="!agent" [title]="home?.recommendedTitle"-->
<!--                          [recommendedsForYou]="home?.recommendedForYou"></app-travel-recommendends>-->
<!--<app-travel-continents *ngIf="!agent" [title]="home?.regionsTitle" [regions]="regions"></app-travel-continents>-->
<app-discover-our-top-experiences class="home-section-padding" style="background: var(--surface-surface-1, #F9FBFB);" *ngIf="!agent"></app-discover-our-top-experiences>
<app-dont-take-our-word class="home-section-padding" *ngIf="!agent"></app-dont-take-our-word>
<app-we-are-here-to-do-good *ngIf="!agent"></app-we-are-here-to-do-good>
<!--<app-travel-deals *ngIf="!agent" [title]="home?.dealsTitle" [title2]="home?.dealsText"-->
<!--                  [travelDeals]="home?.travelDeals"></app-travel-deals>-->
<!--<app-travel-themes *ngIf="!agent" [title]="home?.themeTitle" [travelThemes]="home?.theme"></app-travel-themes>-->

<!--<app-why-travel-with-bamba *ngIf="!agent"></app-why-travel-with-bamba>-->
