import * as moment from 'moment';

export const parseFieldDateObject = (obj: object, dateFields: string[], format = 'YYYY-MM-DD'): any => {
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (obj[p] && dateFields.indexOf(p) > -1) {
        obj[p] = moment(obj[p]).format(format);
      }
    }
  }
  return obj;
};

export const objectToQueryParams = (obj: object, prefix = null) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p;
      if (obj[p]) {
        const v = obj[p];
        str.push(typeof v === 'object' ? objectToQueryParams(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }
  }
  return str.join('&');
};
