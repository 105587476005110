import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuitSpacesService {

  constructor() { }

  /**
   * [quitSpaces limpia la url para que no tenga caracteres raros]
   * @param  txt [cadena de texto a convertir]
   * @return     [regresa el url sin caracteres extraños para seo]
   */
  quitSpaces(txt:string):string{
    let espacios:any = "";
    if (txt != "" && txt != undefined) {
      espacios = txt.split(" ");
      espacios = espacios.join("-");
      espacios = espacios.split("/");
      espacios = espacios.join("&");
      espacios = espacios.split("(");
      espacios = espacios.join("&");
      espacios = espacios.split(")");
      espacios = espacios.join("&");
    }else {
      espacios = "";
    }
    return espacios;
  }

  /**
   * [mayusculas procesa la cadena y pone en mayusculas la primera letra]
   * @param  cadena [texto resivo a convertir]
   * @return     [regresa la cadena con la primer letra en mayusculas]
   */
  mayusculas(cadena:string) {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1);
  }

  /**
   * [quitCommas quita las comas de un toString]
   * @param  txt [cadena a quitar las comas]
   * @return     [retorna la cade na sin comas y con un -]
   */
  quitCommas(txt:string):string{
    let espacios:any = txt.split(",");
    espacios = espacios.join(" - ");
    return espacios;
  }

  quitGuion(txt:string) {
    let espacios:any = "";
    if (txt != "" && txt != undefined) {
      espacios = txt.split("-");
      espacios = espacios.join(" ");
    }else {
      espacios = "";
    }
    return espacios;
  }

}
