<div style="width: 100%; position: relative">
  <div class="reverse">
    <img [src]="responsiveImageSrc" style="width: 100%; height: 100%" alt="Your Image">
  </div>
  <div class="good-section">
    <div>
      <h1 class="good-header">We’re here to do good</h1>
      <p class="good-text">We know it’s our responsibility to help protect the planet for
        future generations, so we contribute 1% of our revenue to carbon removal.</p>
    </div>
    <div class="btn-learn">
      <a href="https://climate.stripe.com/2B9ncM" target="_blank" class="good-btn">
        <p class="good-btnText" style="margin-bottom: 0">Learn More</p>
      </a>
    </div>
  </div>
</div>
