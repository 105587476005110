import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonLoadingComponent} from './button-loading.component';
import {MatButtonModule, MatIconModule, MatProgressSpinnerModule} from '@angular/material';

@NgModule({
  declarations: [ButtonLoadingComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [ButtonLoadingComponent]
})
export class ButtonLoadingModule {
}
