import {Injectable} from '@angular/core';
import {IPreset, IUserPreset} from '../models/bookings.interface';

@Injectable({
  providedIn: 'root'
})
export class PresetsService {
  protected PRESET_STORAGE = 'presets';

  constructor() {
    if (!localStorage.getItem(this.PRESET_STORAGE)) {
      localStorage.setItem(this.PRESET_STORAGE, JSON.stringify([]));
    }
  }

  getPresets(): IUserPreset[] {
    const presets = JSON.parse(localStorage.getItem(this.PRESET_STORAGE));
    return presets;
  }

  add(user: string, name: string, presets: IPreset[]): void {
    const allPresets = this.getPresets();
    allPresets.push({
      user,
      name,
      presets: presets
    });
    this.updatePresets(allPresets);
  }

  updatePresets(presets: IUserPreset[]): void {
    localStorage.setItem(this.PRESET_STORAGE, JSON.stringify(presets));
  }

  get(user: string, name: string): IUserPreset {
    const presets = this.getPresets();
    const presetByUser = presets.find(p => p.user === user && p.name === name);
    return presetByUser;
  }

  getPresetsByUser(user): IUserPreset[] {
    const presets = this.getPresets();
    return presets.filter(p => p.user === user);
  }

  remove(user: string, name: string): void {
    const allPresets = this.getPresets();
    const filteredPresets = allPresets.filter(p => !(p.user === user && p.name === name));
    this.updatePresets(filteredPresets);
  }
}
