import {Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {ProgressSpinnerOverlayComponent} from './progress-spinner-overlay.component';
import {PORTAL_DATA} from '../../const';

@Injectable()
export class ProgressSpinnerOverlayService {

  constructor(private injector: Injector, private overlay: Overlay) {
  }

  private _open(config: OverlayConfig, component, data = {}): OverlayRef {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    config['positionStrategy'] = positionStrategy;
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.overlay.create(config);

    // Create ComponentPortal that can be attached to a PortalHost
    const componentPortal = new ComponentPortal(component, null, this.createInjector(data));

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(componentPortal);

    return overlayRef;
  }

  open(config: OverlayConfig, data = {}): OverlayRef {
    return this._open(config, ProgressSpinnerOverlayComponent, data);
  }

  private createInjector(data): PortalInjector {

    const injectorTokens = new WeakMap<any, any>([
      [PORTAL_DATA, data],
    ]);

    return new PortalInjector(this.injector, injectorTokens);
  }
}
