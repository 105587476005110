import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgencyBookingsListComponent} from './agency-bookings-list.component';
import {BambaSharedModule} from '../../../../shared/bamba-shared.module';
import {MatDatepickerModule, MatInputModule, MatPaginatorModule, MatRadioModule, MatSortModule, MatTableModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ShowDocumentPipe} from './show-document.pipe';

@NgModule({
  declarations: [AgencyBookingsListComponent, ShowDocumentPipe],
  imports: [
    BambaSharedModule,
    MatTableModule,
    MatInputModule,
    MatDatepickerModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    RouterModule,
    MatRadioModule,
    MatSortModule
  ],
  exports: [AgencyBookingsListComponent]
})
export class AgencyBookingsListModule {
}
