import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../services/authentication.service';
import {ACCOUNT_TYPE} from '../../const';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = AuthenticationService.getUser();
    if (user) {
      if ([ACCOUNT_TYPE.SUPER_ADMIN, ACCOUNT_TYPE.STORE_MANAGER, ACCOUNT_TYPE.AGENT].indexOf(user.accountType._id) > -1) {
        return true;
      } else {
        this.router.navigate(['login'], {
          queryParams: {
            type: 'admin'
          }
        });
        return false;
      }

    }
    this.router.navigate(['login'], {
      queryParams: {
        type: 'admin'
      }
    });
    return false;
  }
}
