import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-experience-the-word',
  templateUrl: './experience-the-word.component.html',
  styleUrls: ['./experience-the-word.component.scss']
})
export class ExperienceTheWordComponent implements OnInit {
  isSm = false;
  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }
  ngOnInit() {
    this.checkScreenSize();
  }
  private checkScreenSize(): void {
    this.isSm = window.innerWidth <= 480;
  }

}
