import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeBannerComponent implements AfterViewInit {
  logged = false;

  images = `assets/img/parallax/SlideHome_768x600-v4-txt.jpg 360w,
  assets/img/parallax/SlideHome_1024x700-v4-txt.jpg 480w,
  assets/img/parallax/SlideHome_640x520-v5-txt.jpg 640w,
  assets/img/parallax/SlideHome_1366x768-v4-txt.jpg 768w`;

  imagesLogged = `assets/img/parallax/shutterstock_364973210.png 360w,
  assets/img/parallax/shutterstock_364973210.png 480w,
  assets/img/parallax/shutterstock_364973210.png 640w,
  assets/img/parallax/shutterstock_364973210.png 768w`;

  optionsVjs = {
    autoplay: true,
    loop: true,
    sources: [
      {
        src: 'https://res.cloudinary.com/baboo-travel/video/upload/' +
          'f_auto,q_auto/v1724105226/bamba-static-assets/bamba-adventure-travel_gg5k8b.mp4',
        type: 'video/mp4'
      }
    ]
  };

  @ViewChild('myVideo') myVideo: ElementRef<HTMLVideoElement>;

  constructor(private authService: AuthenticationService) {
    this.logged = AuthenticationService.isAuthenticated();

    this.authService.getIsAuthenticated()
      .subscribe(value => {
        this.logged = AuthenticationService.isAuthenticated();
      });
  }

  ngAfterViewInit() {
  }

}
