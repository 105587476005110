// tslint:disable-next-line:max-line-length
export default [
  {
    _id: "5babdddcbee89f7b0699ecab",
    title: "South America",
    hashtag: "bamba",
    lemaxId: 873,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 2,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 1,
    topTours: [],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a0",
        name: "Argentina",
        lemaxId: 107,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865a1",
        name: "Bolivia",
        lemaxId: 122,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865a2",
        name: "Brazil",
        lemaxId: 133,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865a3",
        name: "Colombia",
        lemaxId: 184,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865a7",
        name: "Peru",
        lemaxId: 212,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c6",
        name: "Chile",
        lemaxId: 167,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ce",
        name: "Ecuador",
        lemaxId: 196,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409fe",
        name: "Uruguay",
        lemaxId: 229,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a7",
        name: "Peru",
        lemaxId: 212,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bca21ebb6dcd102d6a865a1",
        name: "Bolivia",
        lemaxId: 122,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bca21ebb6dcd102d6a865a2",
        name: "Brazil",
        lemaxId: 133,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409c6",
        name: "Chile",
        lemaxId: 167,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bca21ebb6dcd102d6a865a0",
        name: "Argentina",
        lemaxId: 107,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "fdshc3riu0vd1oogjphx",
      version: 1537990222,
      signature: "cf1a60c177262f1ab4fb8a48c0087e9d8d7f3297",
      width: 591,
      height: 236,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990222/fdshc3riu0vd1oogjphx.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990222/fdshc3riu0vd1oogjphx.png",
    },
    regionAttractionsWhatToSee: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          " https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    coverPhotoLanding: {
      public_id: "tnfuvq2llwf3ddwbmpwi",
      version: 1537990222,
      signature: "028727712043abd86bcac4da724b481a501b0357",
      width: 591,
      height: 236,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990222/tnfuvq2llwf3ddwbmpwi.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990222/tnfuvq2llwf3ddwbmpwi.png",
    },
    brochurePdf: {
      filename: "xyhpan-eyvBcUbRV.pdf",
      size: 9695553,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "bronzjftizsukbralohp",
      version: 1567107725,
      signature: "760c408b5dc126742eeefbb24a4fbadaf37aa5e2",
      width: 610,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107725/bronzjftizsukbralohp.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107725/bronzjftizsukbralohp.png",
    },
  },
  {
    _id: "5babde5ebee89f7b0699ecac",
    title: "Oceania",
    hashtag: "bamba",
    lemaxId: 880,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 1,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 8,
    topTours: [],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bd10f44d572272b573409be",
        name: "Australia",
        lemaxId: 399,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409eb",
        name: "New Zealand",
        lemaxId: 431,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bdb54252bf60d3dabb9b307",
        name: "Fiji",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        lemaxId: 428,
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bd10f44d572272b573409be",
        name: "Australia",
        lemaxId: 399,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409eb",
        name: "New Zealand",
        lemaxId: 431,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bdb54252bf60d3dabb9b307",
        name: "Fiji",
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        lemaxId: 428,
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "ndxxlnprewui6od7y67n",
      version: 1537990506,
      signature: "109bfedb1b747823a8e2ccb56ad91d27836d53ac",
      width: 296,
      height: 180,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990506/ndxxlnprewui6od7y67n.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990506/ndxxlnprewui6od7y67n.png",
    },
    regionAttractionsWhatToSee: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          "https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "G-qjsY0ui7pmPCoa.pdf",
      size: 6720078,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "sltbxjk65xlsy3e3x593",
      version: 1567107744,
      signature: "fda21cef106b243c0eb1a06708c6c63cc8cc80e9",
      width: 294,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107744/sltbxjk65xlsy3e3x593.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107744/sltbxjk65xlsy3e3x593.png",
    },
  },
  {
    _id: "5babdf93bee89f7b0699ecad",
    title: "North America",
    hashtag: "bamba",
    lemaxId: 875,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 1,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 7,
    topTours: [],
    regionAttractionsTopAttractions: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          "https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a5",
        name: "Mexico",
        lemaxId: 652,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c5",
        name: "Canada",
        lemaxId: 271,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ff",
        name: "USA",
        lemaxId: 2,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a5",
        name: "Mexico",
        lemaxId: 652,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409ff",
        name: "USA",
        lemaxId: 2,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409c5",
        name: "Canada",
        lemaxId: 271,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "ncbcwrv6inrsvcr1w8by",
      version: 1537990685,
      signature: "ac0e6eaf312fc2ea73e1e8702a1984971f2a49ff",
      width: 296,
      height: 180,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990685/ncbcwrv6inrsvcr1w8by.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990685/ncbcwrv6inrsvcr1w8by.png",
    },
    regionAttractionsWhatToSee: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          "https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "MF8eMmbQZi5R1Bro.pdf",
      size: 7244620,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "an0yra3muoi2txk5gwf6",
      version: 1567107755,
      signature: "96186df3801ee600f81c9c547d90b2dd356b8317",
      width: 294,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107755/an0yra3muoi2txk5gwf6.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107755/an0yra3muoi2txk5gwf6.png",
    },
  },
  {
    _id: "5babe03ebee89f7b0699ecae",
    title: "Africa",
    hashtag: "bamba",
    lemaxId: 877,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress",
    cols: 1,
    rows: 2,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 3,
    topTours: [],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a6",
        name: "Morocco",
        lemaxId: 28,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865a8",
        name: "South Africa",
        lemaxId: 82,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865ac",
        name: "Zambia",
        lemaxId: 101,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409cf",
        name: "Egypt",
        lemaxId: 35,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d1",
        name: "Ethiopia",
        lemaxId: 57,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e1",
        name: "Kenya",
        lemaxId: 65,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e8",
        name: "Namibia",
        lemaxId: 68,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f6",
        name: "Rwanda",
        lemaxId: 79,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409fa",
        name: "Tanzania",
        lemaxId: 89,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b57340a01",
        name: "Zimbabwe",
        lemaxId: 104,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bf2d5667196684f0a80db98",
        name: "Uganda",
        lemaxId: 94,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bd10f44d572272b573409e1",
        name: "Kenya",
        lemaxId: 65,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409fa",
        name: "Tanzania",
        lemaxId: 89,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bca21ebb6dcd102d6a865a8",
        name: "South Africa",
        lemaxId: 82,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409e8",
        name: "Namibia",
        lemaxId: 68,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bf2d5667196684f0a80db98",
        name: "Uganda",
        lemaxId: 94,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "bchmfv57xyo675drzsh9",
      version: 1537990785,
      signature: "02504720adcd89b372dcc4ffd9b57f0db0b62586",
      width: 296,
      height: 390,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990785/bchmfv57xyo675drzsh9.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990785/bchmfv57xyo675drzsh9.png",
    },
    regionAttractionsWhatToSee: [],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "duuPKXhRe24bGda9.pdf",
      size: 7939172,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "hx2lzd67volog0tevzk4",
      version: 1567107764,
      signature: "1006ea7d1815072922b0ca1215a3e6fcc2a3a809",
      width: 294,
      height: 387,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107764/hx2lzd67volog0tevzk4.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107764/hx2lzd67volog0tevzk4.png",
    },
  },
  {
    _id: "5babe091bee89f7b0699ecaf",
    title: "Middle East",
    hashtag: "bamba",
    lemaxId: 878,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 1,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 6,
    topTours: [],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bd10f44d572272b573409dc",
        name: "Israel",
        lemaxId: 32,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409df",
        name: "Jordan",
        lemaxId: 62,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ee",
        name: "Oman",
        lemaxId: 73,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f3",
        name: "Qatar",
        lemaxId: 76,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409fc",
        name: "United Arab Emirates",
        lemaxId: 98,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bd10f44d572272b573409df",
        name: "Jordan",
        lemaxId: 62,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409dc",
        name: "Israel",
        lemaxId: 32,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409ee",
        name: "Oman",
        lemaxId: 73,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409f3",
        name: "Qatar",
        lemaxId: 76,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409fc",
        name: "United Arab Emirates",
        lemaxId: 98,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "ga5x1odxdjz2auvqgpu8",
      version: 1537990875,
      signature: "443425cd486a4a54ab5548e8657d3bcb8299e8f0",
      width: 296,
      height: 200,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990875/ga5x1odxdjz2auvqgpu8.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537990875/ga5x1odxdjz2auvqgpu8.png",
    },
    regionAttractionsWhatToSee: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          "https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "4L6lef0D0iajIYhd.pdf",
      size: 6472759,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "jgcucp8iuecskmzeusvv",
      version: 1567107774,
      signature: "57e3a58661a5d19f7885f99f8269f6d7d10e4611",
      width: 294,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107774/jgcucp8iuecskmzeusvv.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107774/jgcucp8iuecskmzeusvv.png",
    },
  },
  {
    _id: "5babe10bbee89f7b0699ecb0",
    title: "C. America & Caribbean ",
    hashtag: "bamba",
    lemaxId: 874,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 2,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 2,
    topTours: [],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a5",
        name: "Mexico",
        lemaxId: 652,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c0",
        name: "Bahamas",
        lemaxId: 590,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c1",
        name: "Belize",
        lemaxId: 608,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c9",
        name: "Costa Rica",
        lemaxId: 594,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409cd",
        name: "Dominican Republic",
        lemaxId: 627,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d0",
        name: "El Salvador",
        lemaxId: 631,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d5",
        name: "Guatemala",
        lemaxId: 635,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d6",
        name: "Honduras",
        lemaxId: 644,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409dd",
        name: "Jamaica",
        lemaxId: 649,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ec",
        name: "Nicaragua",
        lemaxId: 684,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ef",
        name: "Panama",
        lemaxId: 691,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f2",
        name: "Puerto Rico",
        lemaxId: 696,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5d079b798524752709a26c3b",
        name: "Dominica",
        lemaxId: 699,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a5",
        name: "Mexico",
        lemaxId: 652,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409c1",
        name: "Belize",
        lemaxId: 608,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409d5",
        name: "Guatemala",
        lemaxId: 635,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409c9",
        name: "Costa Rica",
        lemaxId: 594,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409ef",
        name: "Panama",
        lemaxId: 691,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "zsupkizdnfmarda1vggb",
      version: 1537991001,
      signature: "643cafbfde6cac870771ba1343ce396a001cb0be",
      width: 296,
      height: 200,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537991001/zsupkizdnfmarda1vggb.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537991001/zsupkizdnfmarda1vggb.png",
    },
    regionAttractionsWhatToSee: [],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "L7Csq9j44gwoY5Ji.pdf",
      size: 9409533,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "gzbaada3tql5noqsuzca",
      version: 1567107783,
      signature: "041e23023edccccb0a4c89ef1f3b209e8c4840ff",
      width: 610,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107783/gzbaada3tql5noqsuzca.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107783/gzbaada3tql5noqsuzca.png",
    },
  },
  {
    _id: "5babe16cbee89f7b0699ecb1",
    title: "Asia",
    hashtag: "bamba",
    lemaxId: 879,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    wordpressCategory: "Wordpress Category",
    cols: 2,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 4,
    topTours: [
      {
        _id: "5ba178142cd73a2c40ac1091",
        key: "active-chiang-mai-4d-3n",
        name: "Active Chiang Mai 4D/3N",
        __v: 1,
        lemaxTourId: 6916,
        tourType: ["5c3cf4fe15f5da4e8218fde0"],
      },
    ],
    regionAttractionsTopAttractions: [],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a9",
        name: "South Korea",
        lemaxId: 551,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865ab",
        name: "Thailand",
        lemaxId: 559,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c2",
        name: "Bhutan",
        lemaxId: 803,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c4",
        name: "Cambodia",
        lemaxId: 445,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c7",
        name: "China",
        lemaxId: 450,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d9",
        name: "India",
        lemaxId: 459,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409da",
        name: "Indonesia",
        lemaxId: 476,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409de",
        name: "Japan",
        lemaxId: 494,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e0",
        name: "Kazakhstan",
        lemaxId: 502,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e2",
        name: "Kyrgyzstan",
        lemaxId: 505,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e3",
        name: "Laos",
        lemaxId: 508,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e4",
        name: "Malaysia",
        lemaxId: 43,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e5",
        name: "Maldives",
        lemaxId: 523,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e7",
        name: "Myanmar",
        lemaxId: 529,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e9",
        name: "Nepal",
        lemaxId: 538,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f7",
        name: "Singapore",
        lemaxId: 40,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f9",
        name: "Sri Lanka",
        lemaxId: 554,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b57340a00",
        name: "Vietnam",
        lemaxId: 580,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bf2d5667196684f0a80db97",
        name: "Philippines",
        lemaxId: 543,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5ccb11a643a6e10c06b59cfc",
        name: "Mongolia",
        lemaxId: 526,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865ab",
        name: "Thailand",
        lemaxId: 559,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b57340a00",
        name: "Vietnam",
        lemaxId: 580,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409e9",
        name: "Nepal",
        lemaxId: 538,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409e5",
        name: "Maldives",
        lemaxId: 523,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409de",
        name: "Japan",
        lemaxId: 494,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    map: {
      public_id: "ss9vuj4kumlnfvx9ulmq",
      version: 1537991080,
      signature: "dddb9c5b37cde1c1f139b25e66050cdf273de8a0",
      width: 296,
      height: 200,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537991080/ss9vuj4kumlnfvx9ulmq.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1537991080/ss9vuj4kumlnfvx9ulmq.png",
    },
    regionAttractionsWhatToSee: [],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "3pyNz-RynNfrdRzM.pdf",
      size: 9414673,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "bzdr4hieefcybomax3ru",
      version: 1567107794,
      signature: "6a1c0177e43429c7c9115c69b9a4d63e21ba98ba",
      width: 610,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107794/bzdr4hieefcybomax3ru.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107794/bzdr4hieefcybomax3ru.png",
    },
  },
  {
    _id: "5babe1c0bee89f7b0699ecb2",
    title: "Europe",
    hashtag: "bamba",
    lemaxId: 976,
    seoDescription:
      "elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie at elementum eu facilisis sed odio morbi quis commodo",
    seoKeywords: "Seo Keywords",
    shortDescription:
      "Europe, second smallest of the world's continents, composed of the westward-projecting peninsulas of Eurasia (the great landmass that it shares with Asia) and occupying nearly one-fifteenth of the world's total land area.",
    wordpressCategory: "Wordpress Category",
    cols: 1,
    rows: 1,
    Permalink: "",
    regionAttractionsURL: "",
    staticContentId: "",
    videoYoutube: "",
    order: 5,
    topTours: [],
    regionAttractionsTopAttractions: [
      {
        _id: "5ba177f22cd73a2c40ac1090",
        key: "south-america-group-tours",
        name: "South America Group Tours",
        __v: 0,
        linkUrl:
          "https://bambatravel.com/shop/all/Regions/Independent-and-customizable-trips-South-America/0/0/0/0/0/0/0/Relevance-High-to-Low",
        shortDescription: "South America Group Tours",
        coverPhotoAttraction: {
          public_id: "exsrigkbvdfe0epgibfj",
          version: 1537308678,
          signature: "4734b0e15204d494eb3c0f4d0efc74684ded37a6",
          width: 540,
          height: 960,
          format: "jpg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1537308678/exsrigkbvdfe0epgibfj.jpg",
        },
      },
    ],
    allCountry: [
      {
        _id: "5bca21ebb6dcd102d6a865a4",
        name: "Italy",
        lemaxId: 337,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bca21ebb6dcd102d6a865aa",
        name: "Spain",
        lemaxId: 359,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409bf",
        name: "Austria",
        lemaxId: 290,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409c3",
        name: "Bulgaria",
        lemaxId: 287,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409cb",
        name: "Czech Republic",
        lemaxId: 299,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d2",
        name: "France",
        lemaxId: 306,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d3",
        name: "Germany",
        lemaxId: 312,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d4",
        name: "Greece",
        lemaxId: 316,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d7",
        name: "Hungary",
        lemaxId: 327,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409d8",
        name: "Iceland",
        lemaxId: 330,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409db",
        name: "Ireland",
        lemaxId: 333,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409e6",
        name: "Malta",
        lemaxId: 342,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ea",
        name: "Netherlands",
        lemaxId: 345,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409ed",
        name: "Norway",
        lemaxId: 348,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f0",
        name: "Poland",
        lemaxId: 715,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f1",
        name: "Portugal",
        lemaxId: 352,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f4",
        name: "Romania",
        lemaxId: 372,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f5",
        name: "Russia",
        lemaxId: 378,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409f8",
        name: "Slovakia",
        lemaxId: 712,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409fb",
        name: "Turkey",
        lemaxId: 382,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5bd10f44d572272b573409fd",
        name: "United Kingdom",
        lemaxId: 22,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5cdc57264e8d75166d4a13bb",
        name: "Finland",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        lemaxId: 302,
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5d010e595a5ed46475bd008f",
        name: "Croatia",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        lemaxId: 293,
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5d012cf45a5ed46475bd0099",
        name: "Montenegro",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        lemaxId: 1105,
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
      {
        _id: "5d012e055a5ed46475bd009a",
        name: "Sweden",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
        __v: 0,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        lemaxId: 1108,
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
      },
    ],
    regionKeyCountry: [
      {
        _id: "5bd10f44d572272b573409d4",
        name: "Greece",
        lemaxId: 316,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bca21ebb6dcd102d6a865aa",
        name: "Spain",
        lemaxId: 359,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409f1",
        name: "Portugal",
        lemaxId: 352,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409d8",
        name: "Iceland",
        lemaxId: 330,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
      {
        _id: "5bd10f44d572272b573409fd",
        name: "United Kingdom",
        lemaxId: 22,
        __v: 1,
        Permalink: "",
        coin: "",
        hashtag: "",
        language: "",
        seoDescription: "",
        seoKeywords: "",
        shortDescription: "",
        tripInfo: "",
        videoYoutube: "",
        weather: "",
        wordpressCategory: "",
        topFlexible: [],
        topGroup: [],
        topIndependent: [],
      },
    ],
    regionAttractionsWhatToSee: [],
    coverPhotoLanding: {
      public_id: "",
      version: 0,
      signature: "",
      format: "",
      resource_type: "",
      url: "",
      width: 0,
      height: 0,
      secure_url: "",
    },
    brochurePdf: {
      filename: "i4ceCp1ycQBYibpW.pdf",
      size: 7261364,
      mimetype: "application/pdf",
    },
    coverPhotoHome: {
      public_id: "sqvovplh1kqqqkgoyb65",
      version: 1567107804,
      signature: "8a5ed4e65d357c77089893ea4900bf4f75edeb22",
      width: 294,
      height: 186,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107804/sqvovplh1kqqqkgoyb65.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567107804/sqvovplh1kqqqkgoyb65.png",
    },
  },
];
