import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BookingsComponent} from './bookings.component';
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule,
  MatSelectModule, MatTableModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {AgencyBookingsListModule} from './components/agency-bookings-list/agency-bookings-list.module';
import {ReactiveFormsModule} from '@angular/forms';
import {BookingDetailComponent} from './booking-detail/booking-detail.component';
import {RouterModule} from '@angular/router';
import {BookingFormModule} from './components/booking-form/booking-form.module';
import {SuccesErrorFormPipe} from './succes-error-form.pipe';
import {BookingAllInclussionsModule} from '../product/booking-all-inclussions/booking-all.inclussions.module';
import {ProgressSpinnerOverlayModule} from '../../components/progress-spinner-overlay/progress-spinner-overlay.module';
import {ReservationItemsListComponent} from './components/reservation-items-list/reservation-items-list.component';
import { BookingJsonConfirmAndPayModule } from './components/booking-json-confirm-and-pay/booking-json-confirm-and-pay.module';
import { ButtonLoadingModule } from 'src/app/components/button-loading/button-loading.module';
@NgModule({
  declarations: [BookingsComponent, BookingDetailComponent, SuccesErrorFormPipe, ReservationItemsListComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDividerModule,
    MatSelectModule,
    MatTabsModule,
    AgencyBookingsListModule,
    ReactiveFormsModule,
    RouterModule,
    MatCardModule,
    MatExpansionModule,
    BookingFormModule,
    BookingJsonConfirmAndPayModule,
    MatButtonModule,
    BookingAllInclussionsModule,
    ProgressSpinnerOverlayModule,
    MatTableModule,
    ButtonLoadingModule
  ],
  exports: [BookingsComponent, BookingDetailComponent]
})
export class BookingsModule {
}
