import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NationalitiesAutocompleteComponent} from './nationalities-autocomplete.component';
import {MatAutocompleteModule, MatIconModule, MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [NationalitiesAutocompleteComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  exports: [NationalitiesAutocompleteComponent]
})
export class NationalitiesAutocompleteModule {
}
