import {Injectable} from '@angular/core';
import {SistemaService} from './sistema/sistema.service';
import {Observable, of} from 'rxjs';
import {Home} from '../models/home/home.model';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {SearchAutocomplete} from '../models/search-result';
import home from '../json/home';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private readonly url: string;
  private readonly key_home_storage = 'home_storage';
  private readonly key_footer_storage = 'footer_storage';

  constructor(private sistemaService: SistemaService, private http: HttpClient) {
    this.url = this.sistemaService.url;
  }

  private getHomeStorage() {
    return sessionStorage.getItem(this.key_home_storage) ? JSON.parse(sessionStorage.getItem(this.key_home_storage)) : null;
  }

  private getFooterStorage() {
    return sessionStorage.getItem(this.key_footer_storage) ? JSON.parse(sessionStorage.getItem(this.key_footer_storage)) : null;
  }

  getHome(): Observable<Home> {
    return of(home as any)
      .pipe(
        map(h => h[0]));
  }

  getFooter(): Observable<any> {
    const homeStorage = this.getFooterStorage();
    if (homeStorage) {
      return of(homeStorage);
    } else {
      return this.http.get(`${this.url}/get-footer`)
        .pipe(
          tap(footer => sessionStorage.setItem(this.key_footer_storage, JSON.stringify(footer))));
    }
  }

  searchAutocomplete(query: string): Observable<SearchAutocomplete> {
    return this.http.get<SearchAutocomplete>(`${this.url}/search-autocomplete/${query}`);
  }
}
