<div class="container m-section-home" *ngIf="!showFilters">
  <div fxLayout="row" fxLayout.xs="column" class="section-booking">
    <div fxFlex>
      <h1 class="title-section">Last Bookings</h1>
      <mat-divider></mat-divider>
    </div>

    <div fxFlex fxFlexAlign="start end">
      <a class="more" href="/darwin/booking">
        View all bookings
        <img src="assets/img/shared/arrowRight.svg" width="10" alt="arrow right">
      </a>
    </div>
  </div>
</div>
<div class="navigation mb-4" *ngIf="showFilters">

  <mat-toolbar>

    <div class="container texto">
      <div>Bookings</div>
    </div>

  </mat-toolbar>

</div>

<div class="container">
  <div *ngIf="showFilters">
    <app-bookingsv2-filter (exportByFields)="export($event)"
                           (filtersChanged)="setFilters($event)"></app-bookingsv2-filter>
  </div>
  <div class="gridV2">
    <app-bookingsv2-table [filters]="filters" (pageChange)="pageChange($event)" [isLoadingResults]="isLoadingResults"
                          [data]="bookings" [resultsLength]="resultsLength"></app-bookingsv2-table>
    <!-- <div class="container"> -->
    <div class="row paginator">
      <mat-paginator [length]="resultsLength"
                     [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>
    <!-- </div> -->
  </div>
</div>
