<div fxLayout="column" [formGroup]="form" class="content_input_carrousel">

  <div class="form-row">

    <label class="col-sm-2">Tour Name</label>
    <div class="col-sm-10">
      <mat-form-field class="example-full-width">
        <input formControlName="TourName" matInput >
      </mat-form-field>
    </div> 

  </div>

  <div class="form-row">

    <label class="col-sm-2">Traveller Name</label>
    <div class="col-sm-10">
      <mat-form-field class="example-full-width">
        <input formControlName="TravellerName" matInput >
      </mat-form-field>
    </div>
    
  </div>

  <!-- <div fxFlex fxLayoutGap="10px"> -->
  <div class="form-row">

    <div class="col-6">
      <div class="row">

        <label class="col-sm-4">Booking Status</label>

        <div class="col-sm-8">
          <mat-form-field fxFlex>
            <mat-select  formControlName="Status">
              <mat-option [value]="null">All Status</mat-option>
              <mat-option *ngFor="let t of reservationStatus" [value]="t.lemaxId">{{ t.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>      

      </div>      
    </div>

    <div class="col-6">
      <div class="row">

        <label class="col-sm-4">Tour Types</label>
        <div class="col-sm-8">
          <mat-form-field fxFlex>
            <mat-select  formControlName="TourType">
              <mat-option [value]="null">All Types</mat-option>
              <mat-option *ngFor="let t of tourTypes" [value]="t.lemaxId">{{ t.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


      </div>
    </div>   
    
  </div>

  <!-- <div fxFlex fxLayoutGap="10px"> -->
  <div class="form-row">

    <label class="col-sm-2">Purchase Date From</label>
    <div class="col-sm-10">
      <div class="row">

        <div class="col-6">

          <mat-form-field>
            <input formControlName="PurchaseDateFrom" matInput [matDatepicker]="picker" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-6">

          <mat-form-field>
            <input formControlName="PurchaseDateTo" matInput [matDatepicker]="picker2" placeholder="Purchase Date To">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>

        </div>

      </div>
    </div>

  </div>



  <!-- <div fxFlex fxLayoutGap="10px"> -->
  <div class="form-row">
    <label class="col-sm-2">Departure Date From</label>

    <div class="col-sm-10">
      <div class="row">

        <div class="col-6">

          <mat-form-field>
            <input formControlName="DepartureFrom" matInput [matDatepicker]="picker3" >
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>

        </div>

        <div class="col-6">

          <mat-form-field>
            <input formControlName="DepartureTo" matInput [matDatepicker]="picker4" placeholder="Departure Date To">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field>

        </div>
      </div>
    </div>   
  </div>


  <!-- <div fxFlex fxLayoutGap="10px"> -->
  <div class="row">

    <div class="col-sm-4">
      <mat-form-field fxFlex appearance="legacy" class="selectAllStores">
        <mat-label>All Super Agencies</mat-label>
        <mat-select formControlName="SuperAgency">
          <mat-option [value]="null">All Super Agencies</mat-option>
          <mat-option [value]="agency._id" *ngFor="let agency of superAgencies">
            {{ agency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field fxFlex appearance="legacy" class="selectAllStores">
        <mat-label>All Agencies</mat-label>
        <mat-select formControlName="Agency">
          <mat-option [value]="null">All Agencies</mat-option>
          <mat-option [value]="agency._id" *ngFor="let agency of agencies">
            {{ agency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field fxFlex appearance="legacy" class="selectAllStores">
        <mat-label>All Stores</mat-label>
        <mat-select formControlName="Store">
          <mat-option [value]="null">All Stores</mat-option>
          <mat-option [value]="store._id" *ngFor="let store of stores">
            {{ store.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>   
    
  </div>
</div>
