import { Component, HostListener, OnInit } from '@angular/core';

export interface Tile {
  cols: number;
  rows: number;
  img: string;
  title: string;
  link: string;
}
export interface Images {
  cols: number;
  rows: number;
  img: string;
  title: string;
  link: string;
}
@Component({
  selector: 'app-discover-our-top-experiences',
  templateUrl: './discover-our-top-experiences.component.html',
  styleUrls: ['./discover-our-top-experiences.component.scss'],
})
export class DiscoverOurTopExperiencesComponent implements OnInit {
  isSmallScreen = false;
  image: Images[] = [
    {
      img: 'assets/img/home-page/japan_adventure.png',
      cols: 1,
      rows: 2,
      title: 'Japan & South Korea Adventures',
      link: 'https://bambatravel.com/shop/All/Countries/Japan,South-Korea/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/amazon.png',
      cols: 1,
      rows: 2,
      title: 'Amazon Experiences',
      link: 'https://bambatravel.com/shop/All/0/0/0/0/0/0/0/0/amazon/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/machu_pichhu.png',
      cols: 2,
      rows: 4,
      title: 'Hiking to Machu Picchu',
      link: 'https://bambatravel.com/shop/All/0/0/0/0/0/0/0/0/machu%20picchu/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/african_safaries.png',
      cols: 1,
      rows: 2,
      title: 'African Safaris',
      link: 'https://bambatravel.com/shop/All/Countries/Kenya,South-Africa,Tanzania,Uganda,Zambia,Zimbabwe/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/costa_rica.png',
      cols: 1,
      rows: 2,
      title: 'Costa Rica & Mexico Adventures',
      link: 'https://bambatravel.com/shop/All/Countries/Costa-Rica,Mexico/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
  ];
  tiles: Tile[] = [
    {
      img: 'assets/img/home-page/japan_adventure.png',
      cols: 1,
      rows: 2,
      title: 'Japan & South Korea Adventures',
      link: 'https://bambatravel.com/shop/All/Countries/Japan,South-Korea/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/machu_pichhu.png',
      cols: 2,
      rows: 4,
      title: 'Hiking to Machu Picchu',
      link: 'https://bambatravel.com/shop/All/0/0/0/0/0/0/0/0/machu%20picchu/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/african_safaries.png',
      cols: 1,
      rows: 2,
      title: 'African Safaris',
      link: 'https://bambatravel.com/shop/All/Countries/Kenya,South-Africa,Tanzania,Uganda,Zambia,Zimbabwe/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/amazon.png',
      cols: 1,
      rows: 2,
      title: 'Amazon Experiences',
      link: 'https://bambatravel.com/shop/All/0/0/0/0/0/0/0/0/amazon/Relevance-High-to-Low'
    },
    {
      img: 'assets/img/home-page/costa_rica.png',
      cols: 1,
      rows: 2,
      title: 'Costa Rica & Mexico Adventures',
      link: 'https://bambatravel.com/shop/All/Countries/Costa-Rica,Mexico/0/0/0/0/0/0/0/Relevance-High-to-Low'
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }

  constructor() { }
  ngOnInit(): void {
    this.checkScreenSize();
  }
  private checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 991;
  }

}
