import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IBuyer} from '../../../models/bookings.interface';
import {MatDialog} from '@angular/material';
import {BookingExportComponent} from '../booking-export/booking-export.component';
import {BookingsService} from '../../../services/bookings.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';
import {ACCOUNT_TYPE} from '../../../const';

@Component({
  selector: 'app-bookingsv2-filter',
  templateUrl: './bookingsv2-filter.component.html',
  styleUrls: ['./bookingsv2-filter.component.scss']
})
export class Bookingsv2FilterComponent implements OnInit, OnDestroy {
  form: FormGroup;

  @Input() buyers: any[] = [];

  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() exportByFields: EventEmitter<string[]> = new EventEmitter<string[]>();

  unsubscribe = new Subject();

  auth = AuthenticationService.getUser();
  ACCOUNT_TYPE = ACCOUNT_TYPE;

  constructor(private fb: FormBuilder, private matDialog: MatDialog, private bookingService: BookingsService) {
    this.createForm();
    this.getBuyers();
  }

  ngOnInit() {
    this.form.get('BranchOffice')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.filter();
      });
  }

  export(): void {
    const dialogRef = this.matDialog.open(BookingExportComponent);
    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.exportByFields.emit(value);
      }
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      TourName: [],
      TravellerName: [],
      BookingStatus: [],
      BookingType: [],
      PurchaseDateFrom: [],
      PurchaseDateTo: [],
      DepartureFrom: [],
      DepartureTo: [],
      Agency: [],
      Store: [],
      Search: [],
      TourType: [],
      Status: [],
      BranchOffice: [],
      SuperAgency: []
    });
  }

  searchBy(): void {
    Object.keys(this.form.controls).filter(k => k !== 'Search')
      .forEach(k => this.form.get(k).reset());
    this.filtersChanged.emit(this.form.getRawValue());
  }

  filter(): void {
    this.filtersChanged.emit(this.form.getRawValue());
  }

  detectExpansionStatus(opened): void {
    if (opened) {
      this.form.get('Search').reset();
      this.form.get('Search').disable();
    } else {
      this.form.get('Search').enable();
    }
  }

  async getBuyers(): Promise<void> {
    const results = await this.bookingService.getBranchOffices().toPromise();
    this.buyers = results.Results;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
