import {NgModule} from '@angular/core';
import {BookingAllInclussionsComponent} from './booking-all-inclussions.component';
import {MaterialModule} from '../../../app.material';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BambaSharedModule} from '../../../shared/bamba-shared.module';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
  declarations: [BookingAllInclussionsComponent],
  imports: [
    CommonModule,
    BambaSharedModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollToModule
  ],
  exports: [BookingAllInclussionsComponent]
})
export class BookingAllInclussionsModule {
}
