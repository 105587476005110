import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {PORTAL_DATA} from '../../const';

@Component({
  selector: 'app-progress-spinner-overlay',
  templateUrl: './progress-spinner-overlay.component.html',
  styleUrls: ['./progress-spinner-overlay.component.scss']
})
export class ProgressSpinnerOverlayComponent implements OnInit, OnDestroy {
  @Input() progressBar = false;

  value = 0;

  interval;

  constructor(@Inject(PORTAL_DATA) public data) {
    if (data) {
      if (data.progressBar) {
        this.progressBar = data.progressBar;
      }
    }
  }

  ngOnInit(): void {
    if (this.progressBar) {
      this.interval = setInterval(() => {
        this.value = this.value + 10;
        if (this.value >= 100) {
          this.value = 0;
        }
        console.log(this.value);
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.progressBar) {
      clearInterval(this.interval);
    }
  }

}
