import {InjectionToken} from '@angular/core';
import {IBuyer} from './models/bookings.interface';
import {Product} from './models/search-autocomplete.interface';
import * as slugify from '@sindresorhus/slugify';
import {OwlOptions} from 'ngx-owl-carousel-o';

export const KEY_TOKEN_STORAGE = '__token';

export const KEY_USER_STORAGE = '__user';

export const ACCOUNT_TYPE = {
  SUPER_ADMIN: '5d5c91508df0951ebcf28052',
  AGENCY_ADMIN: '5d5c91688df0951ebcf28053',
  STORE_MANAGER: '5d5c91a48df0951ebcf28054',
  AGENT: '5d5c92198df0951ebcf28055',
  SUPER_AGENCY: '5daa5a4ef97cd415508b8364'
};

export const PORTAL_DATA = new InjectionToken<{}>('PortalData');

// tslint:disable-next-line:max-line-length
const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm;
export const DATE_REGEX = new RegExp(regex);

export const BUYERS: IBuyer[] = [
  {
    _id: 'sad2d12',
    name: 'B2B'
  },
  {
    _id: 'sad2d15',
    name: 'B2C'
  }
];

export const PASSENGERS_TITLES = [
  {
    key: 'tour',
    title: 'Tour Name',
  },
  /*  {
  key: 'end_date',
    title: 'End Date',
  },*/
  {
    key: 'DepartureDate',
    title: 'DepartureDate',
  },
  {
    key: 'customer',
    title: 'Customer Name',
  },
  {
    key: 'customer_email',
    title: 'Customer Email',
  },
  {
    key: 'gross_price',
    title: 'Price',
  },
  {
    key: 'StartDestinationCountry',
    title: 'StartDestinationCountry',
  },
  {
    key: 'Age',
    title: 'Age',
  },
  {
    key: 'DateOfBirth',
    title: 'Birth Date',
  },
  {
    key: 'Email',
    title: 'Email',
  },
  {
    key: 'Gender',
    title: 'Gender',
  },
  {
    key: 'MobilePhone',
    title: 'Mobile Phone',
  },
  {
    key: 'Name',
    title: 'Name',
  },
  {
    key: 'LastName',
    title: 'Surname',
  },
  {
    key: 'Nationality',
    title: 'Nationality',
  },
  {
    key: 'PassengerID',
    title: 'Passenger ID',
  },
  {
    key: 'PassportNumber',
    title: 'Passport Number',
  },
];

export const BOOKING_TITLES = [
  {
    key: 'lemax_id',
    title: 'ID',
  },
  {
    key: 'bamba_id',
    title: 'Bamba ID',
  },
  {
    key: 'res',
    title: 'Res #',
  },
  {
    key: 'tour_name',
    title: 'Tour Name',
  },
  {
    key: 'pax',
    title: 'Paxes',
  },
  {
    key: 'customer',
    title: 'Customer',
  },
  {
    key: 'purchase',
    title: 'Purchase',
  },
  {
    key: 'departure',
    title: 'Departure',
  },
  {
    key: 'lead_traveller',
    title: 'Lead Traveller',
  },
  {
    key: 'gross_price',
    title: 'Price',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'AgentReferenceNumber',
    title: 'Agent Reference Number',
  },
  {
    key: 'BambaReferenceNumber',
    title: 'Bamba Reference Number',
  },
  {
    key: 'ReservationLemaxID',
    title: 'Reservation LemaxID',
  },
  {
    key: 'ReservationUniqueID',
    title: 'Reservation UniqueID',
  },
  {
    key: 'TourID',
    title: 'TourID',
  },
  {
    key: 'TourType',
    title: 'Tour Type',
  },
  {
    key: 'TourTypeName',
    title: 'Tour Type Name',
  },
  {
    key: 'CreatedBy',
    title: 'Created By',
  },
  {
    key: 'DateCreated',
    title: 'Date Created',
  },
  {
    key: 'PurchaseDate',
    title: 'Purchase Date',
  },
  {
    key: 'Status',
    title: 'Status',
  },
  {
    key: 'StatusName',
    title: 'Status Name',
  },
  {
    key: 'BranchOffice',
    title: 'Branch Office',
  },
  {
    key: 'BranchOfficeSlug',
    title: 'Branch Office Slug',
  },
  {
    key: 'BranchOfficeName',
    title: 'Branch Office Name',
  },
];

export const TRAVEL_STYLE = {
  ALL: 0,
  TRAVEL_PASS: 1,
  GROUP: 2,
  INDEPENDENT: 3,
  TEILOR_MADE: 4,
}

export const WHATSAPP_CHAT = 'https://wa.me/13109934069';

export const CAROUSEL_OPTIONS: OwlOptions = {
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: false,
  navSpeed: 700,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 2
    },
    740: {
      items: 3
    },
    940: {
      items: 4
    }
  },
  nav: false
};

export const generateProductUrl = (product: Product, v2 = false) => {
  return [v2 ? 'adventurev2' : 'adventure', slugify(`${product.Countries[0].name}-${product.Style[0].title}`),
    slugify(product.TourTitle.toLowerCase().trim()), product.TourID, product.Type];
};
