<div class="card_export">
  <mat-card>
    <mat-card-header>
      <div class="title">Export data</div>
      <div class="card_close">
        <img src="../../../../assets/icons/Icon - Cancel - Filled.svg" (click)="dialogRef.close(false)" alt="img_close">
      </div>
    </mat-card-header>
    <div class="container">
      <div class="row">

        <div class="col-sm-5">
          <app-presets (selectPreset)="selectPresets($event)" [formPreset]="formPreset"></app-presets>
        </div>

        <div class="col-sm-7">
          <div class="matSubHeader">Custome fields</div>
          <mat-checkbox [formControl]="selectAllControl" style="padding-left: 26px;">Select all</mat-checkbox>
          <mat-selection-list [formControl]="listControl" #fields class="card_list">
            <mat-list-option checkboxPosition="before" [value]="s" *ngFor="let s of columnsData">
              {{ s | exportTitles }}
            </mat-list-option>
          </mat-selection-list>

          <div class="btn_download" fxLayoutAlign="space-between">
            <button color="primary"
                    (click)="export(fields.selectedOptions.selected, true)" mat-raised-button>Generate Passengers
            </button>
            <button [disabled]="fields.selectedOptions.selected.length === 0" color="primary"
                    (click)="export(fields.selectedOptions.selected, false)" mat-raised-button>Download Excel
            </button>
          </div>
        </div>
      </div>

    </div>
  </mat-card>
</div>
