import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {MatSearchBarComponent} from './search-bar/mat-search-bar.component';
import {MatDividerModule, MatIconModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {BambaSharedModule} from '../../../shared/bamba-shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SearchBarModule} from './search-bar/search-bar.module';
import {ResponsiveModule} from 'ngx-responsive';
import {SkeletonsLoaderModule} from '../../skeletons-loader/skeletons-loader.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    BambaSharedModule,
    MatDividerModule,
    RouterModule,
    MatIconModule,
    ReactiveFormsModule,
    SearchBarModule,
    ResponsiveModule,
    SkeletonsLoaderModule,
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
