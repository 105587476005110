import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {SistemaService} from '../../../services/sistema/sistema.service';
import {Footer} from '../../../models/footer.model';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Router} from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {HomeService} from '../../../services/home.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  isMediumScreen = false;

  url = '';
  footerAbout: Footer[] = [];
  footerResource: Footer[] = [];
  newsLetterForm: FormGroup;
  messageSending = '';
  loaderSending = false;

  hideFooter = false;

  unsubscribe = new Subject();
  newFooterResource = [
    { text: 'About Us', url: '/about-us/5c53783d6086272b0b3c152b' },
    { text: 'Why Bamba', url: '/why-bamba/5c7833a5c23e0e17d1092e9c' },
    { text: 'Animal Welfare', url: '/animal-welfare/5c7da944c23e0e17d1092ea2' },
    { text: 'Sustainable Tourism', url: '/sustainable-tourism/5c7da419c23e0e17d1092ea1' },
    { text: 'Bamba for Good', url: '/bamba-for-good/5c7ea1bac23e0e17d1092ea3' },
    { text: 'Press & Media', url: '/press-media/5ca3fa2e0c78d959a882f4c8' },
    { text: 'FAQ', url: '/faq/5c7833eac23e0e17d1092e9d' },
    { text: 'Happy Nomad Blog', url: 'https://blog.bambatravel.com/' },
    { text: 'Contact Us', url: '/contact-us' }
  ];

  constructor(public _sistemaService: SistemaService,
              public http: HttpClient,
              private _router: Router,
              private homeService: HomeService) {
    this.url = _sistemaService.url;
    this._sistemaService.hideFooter
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(hideFooter => this.hideFooter = hideFooter);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }

  ngOnInit() {
    this.newsLetterForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')
      ])
    });

    this.getFooter();
    this.checkScreenSize();

  }
  private checkScreenSize(): void {
    const screenWidth = window.innerWidth;
    this.isMediumScreen = screenWidth >= 481 && screenWidth <= 991;
  }

  /**
   * [getFooter regresa la respuesta de api con el id de cada elemento
   * de la columna para enviarlo al api de contentPage]
   */
  getFooter(): void {
    this.homeService.getFooter()
      .subscribe((res: any) => {
        for (let i = 0; i < res.length; i++) {
          let tmp: Footer = {
            _id: res[i]._id,
            shortName: res[i].shortName,
            permalink: res[i].permalink,
            order: res[i].order,
            addToFooter: res[i].addToFooter,
          };
          if (res[i].addToFooter == 1) {
            this.footerAbout.push(tmp);
            this.footerAbout.sort(function (a, b) {
              return a.order - b.order;
            });
          } else if (res[i].addToFooter == 2) {
            this.footerResource.push(tmp);
            this.footerResource.sort(function (a, b) {
              return a.order - b.order;
            });
          }
        }
      });
  }

  /**
   * [contentPage nos lleva a la pagina de contenido]
   * @param  id        [lo necesitamos para hacer el match con el api de contentpages]
   * @param  permalink [lo que ponemos en el url para ir a las paginas de contenido]
   */
  contentPage(permalink, id): void {
    let url: string[] = [];
    if (id == '5cd091584e8d75166d4a13b9') {
      window.open('https://blog.bambatravel.com', '_blank');
    } else {
      url.push(permalink);
      url.push(id);
      this._router.navigate(url);
    }
  }

  /**
   * [registra al usuario al netsler de noticias de Sendinblue]
   */
  getSendinblue() {
    this.loaderSending = true;
    let param = {
      Name: this.newsLetterForm.controls['name'].value,
      Email: this.newsLetterForm.controls['email'].value
    };
    this.http.post(`${this.url}/sending-blue-add-list`, param)
      .subscribe((res: any) => {
        this.messageSending = res[0].message;
        this.newsLetterForm.controls['name'].setValue('');
        this.newsLetterForm.controls['email'].setValue('');
        this.loaderSending = false;
        console.log('entre ', res);
      }, e => {
        console.log('Error del sistema. Intentalo de nuevo...', e);
        const temp = e._body.replace('[{"message":"', '');
        this.messageSending = temp.replace('"}]', '');
        this.loaderSending = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
