import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer.component';
import {MatProgressSpinnerModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [FooterComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        RouterModule
    ],
  exports: [FooterComponent]
})
export class FooterModule {
}
