import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ITravelStyles} from '../../../../../models/travel-styles.interface';

@Component({
  selector: 'app-travel-type',
  templateUrl: './travel-type.component.html',
  styleUrls: ['./travel-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravelTypeComponent implements OnInit {
  @HostBinding('class') class = '';
  @Input() travelType: ITravelStyles;

  constructor() {
  }

  ngOnInit() {
  }

}
