import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SistemaService} from './sistema/sistema.service';
import {IAgencyListRequest, IAgencyStore, IStoreFull} from '../models/agents.model';
import {Observable} from 'rxjs';
import {IListReponseData} from '../models/common.interface';
import {objectToQueryParams} from '../core/utils';
import {IAgency, IAgencyListItem, IAgencyRequestPost} from '../models/agency.interface';

@Injectable({
  providedIn: 'root'
})
export class SuperAgencyService {

  private readonly superAgencyEndpoint: string;
  private readonly enableSuperAgencyEndpoint: string;
  private readonly disableSuperAgencyEndpoint: string;

  constructor(private http: HttpClient, sistemaService: SistemaService) {
    this.superAgencyEndpoint = sistemaService.url + '/super-agencies/';
    this.enableSuperAgencyEndpoint = sistemaService.url + '/enable-super-agencies/';
    this.disableSuperAgencyEndpoint = sistemaService.url + '/enable-super-agencies/';
  }

  list(params: IAgencyListRequest): Observable<IListReponseData<IAgencyListItem>> {
    const queryParams = '?' + objectToQueryParams(params);
    return this.http.get<IListReponseData<IAgencyStore>>(this.superAgencyEndpoint + queryParams);
  }

  get(id: string): Observable<IAgency> {
    return this.http.get<IStoreFull>(this.superAgencyEndpoint + id);
  }

  add(agency: IAgencyRequestPost): Observable<IAgency> {
    return this.http.post<IAgency>(this.superAgencyEndpoint, agency);
  }

  put(agency: IAgencyRequestPost): Observable<IAgency> {
    return this.http.put<IAgency>(this.superAgencyEndpoint, agency);
  }

  patch(agency: IAgencyRequestPost): Observable<IAgency> {
    return this.http.patch<IAgency>(this.superAgencyEndpoint, agency);
  }

  delete(ids: string[]): Observable<any> {
    const body = {
      _id: ids
    };
    return this.http.request('delete', this.superAgencyEndpoint, {body});
  }

  enable(ids: string[]): Observable<any> {
    return this.http.put(this.enableSuperAgencyEndpoint, ids);
  }

  disable(ids: string[]): Observable<any> {
    return this.http.put(this.disableSuperAgencyEndpoint, ids);
  }
}
