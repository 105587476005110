import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatListOption, MatSelectionList} from '@angular/material';
import {BOOKING_TITLES} from '../../../const';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PresetsService} from '../../../services/presets.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {IPreset} from '../../../models/bookings.interface';

@Component({
  selector: 'app-booking-export',
  templateUrl: './booking-export.component.html',
  styleUrls: ['./booking-export.component.scss']
})
export class BookingExportComponent implements OnInit {
  columnsData = BOOKING_TITLES.map(b => b.key);

  titles = BOOKING_TITLES;

  formPreset: FormGroup;

  user = AuthenticationService.getUser();

  @ViewChild(MatSelectionList) selectionList: MatSelectionList;

  listControl = new FormControl([]);

  selectAllControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<BookingExportComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private presetsService: PresetsService) {
  }

  ngOnInit() {
    this.createFormPreset();
    this.selectAllControl.valueChanges.subscribe(value => {
      if (value) {
        this.selectionList.selectAll();
      } else {
        this.selectionList.deselectAll();
      }
    });
  }

  createFormPreset(): void {
    this.formPreset = this.fb.group({
      save: [],
      name: []
    });
  }

  export(options: MatListOption[], passenger = false): void {
    const exported = options.map(m => m.value);
    this.addPresets(exported.map(p => {
      return {key: p, title: this.titles.find(t => t.key === p).title};
    }));
    this.dialogRef.close({exported, passenger});
  }

  addPresets(presets: IPreset[]): void {
    const name = this.formPreset.get('name').value;
    if (this.formPreset.get('save').value && name) {
      this.presetsService.add(this.user._id, name, presets);
    }
  }

  selectPresets(presets: IPreset[]): void {
    this.listControl.setValue(presets.map(p => p.key));
  }

}
