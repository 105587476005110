import {NgModule} from '@angular/core';
import {TravelTypesComponent} from './travel-types/travel-types.component';
import {TravelCategoriesComponent} from './travel-categories/travel-categories.component';
import {TravelContinentsComponent} from './travel-continents/travel-continents.component';
import {TravelDealsComponent} from './travel-deals/travel-deals.component';
import {WhyTravelWithBambaComponent} from './why-travel-with-bamba/why-travel-with-bamba.component';
import {BambaSharedModule} from '../../../shared/bamba-shared.module';
import {TravelThemesComponent} from './travel-themes/travel-themes.component';
import {TravelRecommendendComponent} from './travel-recommendends/travel-recommendend/travel-recommendend.component';
import {SkeletonsLoaderModule} from '../../../components/skeletons-loader/skeletons-loader.module';
import {TravelTypeComponent} from './travel-types/travel-type/travel-type.component';
import {RouterModule} from '@angular/router';
import {TravelRecommendendsComponent} from './travel-recommendends/travel-recommendends.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {TravelDealComponent} from './travel-deals/travel-deal/travel-deal.component';
import {InViewportModule} from '@thisissoon/angular-inviewport';
import {ResponsiveModule} from 'ngx-responsive';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { DontTakeOurWordComponent } from './dont-take-our-word/dont-take-our-word.component';
import { WeAreHereToDoGoodComponent } from './we-are-here-to-do-good/we-are-here-to-do-good.component';

@NgModule({
  declarations: [TravelTypesComponent, TravelCategoriesComponent,
    TravelContinentsComponent, TravelDealsComponent,
    WhyTravelWithBambaComponent, TravelThemesComponent, TravelRecommendendComponent,
    TravelTypeComponent, TravelRecommendendsComponent, TravelDealComponent, DontTakeOurWordComponent, WeAreHereToDoGoodComponent],
  imports: [
    BambaSharedModule,
    SkeletonsLoaderModule,
    RouterModule,
    CarouselModule,
    InViewportModule,
    ResponsiveModule,
    ScrollingModule
  ],
  exports: [TravelTypesComponent, TravelCategoriesComponent,
    TravelContinentsComponent, TravelDealsComponent,
    WhyTravelWithBambaComponent, TravelRecommendendComponent, TravelThemesComponent, TravelRecommendendsComponent, DontTakeOurWordComponent, WeAreHereToDoGoodComponent],
})
export class HomeSectionsModule {
}
