<div class="cuerpoModalDelete">

    <div class="row">
      <div class="col-12">
        <span class="mensajeDelete">{{ message }}</span>
      </div>
    </div>

    <div class="row d-flex justify-content-end">
      <div class="col-3">
        <button type="button" (click)="confirm(false)" class="btn btn-sm btnCancel" >
          {{ config.no.toUpperCase() }}
        </button>
      </div>

      <div class="col-3">
        <button type="button" (click)="confirm(true)" class="btn btn-sm btnDelete">
          {{ config.yes.toUpperCase() }}
        </button>
    </div>


    </div>



</div>
