<div class="spacing">
    <div class="gap-travel">
      <p class="h-text">
        <strong>Unique travel styles to explore the world the way you love</strong>
      </p>
      <p class="subt-text" style="margin-bottom: 0">
        Discover your perfect travel style to go off the beaten path into the heart of the destination.
      </p>
    </div>
  <div>
    <ng-container *ngIf="travelStyles && travelStyles.length; else loadingProductTypes">
      <ng-container>
        <app-travel-type></app-travel-type>
      </ng-container>
    </ng-container>
  </div> <!-- row -->
</div>

<ng-template #loadingProductTypes>
  <div *ngFor="let t of iterateSkeletons" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="z-index:1000;">
    <app-product-types></app-product-types>
  </div>
</ng-template>
