import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../app.material';
import {FormsModule} from '@angular/forms';
import {DiscountSeparadorPipe} from '../pipes/discount-separador.pipe';
import {SeparadorMilesPipe} from '../pipes/separador-miles.pipe';
import {TrimPipe} from '../pipes/trim.pipe';
import {SanitizeHtmlPipe} from '../pipes/html.pipe';
import {AsteriscosPipe} from '../pipes/asteriscos.pipe';
import {UrlEspaciosPipe} from '../pipes/url-espacios.pipe';
import {RoundDecimalPipe} from '../pipes/round-decimal.pipe';
import {BloqueoComponent} from '../components/bloqueo.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SafePipe} from '../pipes/safe.pipe';
import {ValidDateFormatPipe} from '../pipes/valid-date-format.pipe';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    DiscountSeparadorPipe,
    SeparadorMilesPipe,
    TrimPipe,
    SanitizeHtmlPipe,
    AsteriscosPipe,
    UrlEspaciosPipe,
    RoundDecimalPipe,
    BloqueoComponent,
    SafePipe, ValidDateFormatPipe, PdfViewerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    LazyLoadImageModule,
    FlexLayoutModule,
    PdfViewerModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,

    SeparadorMilesPipe,
    DiscountSeparadorPipe,
    TrimPipe,
    SanitizeHtmlPipe,
    AsteriscosPipe,
    UrlEspaciosPipe,
    SafePipe,
    ValidDateFormatPipe,
    RoundDecimalPipe,
    BloqueoComponent,
    LazyLoadImageModule,
    FlexLayoutModule,
    PdfViewerComponent
  ]
})
export class BambaSharedModule {
}
