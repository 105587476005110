<div style="display: block">
  <div class="discover-section-padding">
    <div class="gap-travel">
      <p class="h-text">
        <strong>Discover Our Top Experiences</strong>
      </p>
      <p class="subt-text" style="margin-bottom: 0">Find your perfect adventure with our curated selection
        of the world's most thrilling destinations.</p>
    </div>

    <div class="top-banner" *ngIf="isSmallScreen">
      <!--small and medium screen -->
      <mat-grid-list cols="2" rowHeight="50px" gutterSize="16px">
        <mat-grid-tile *ngFor="let i of image"
                       [colspan]="i.cols"
                       [rowspan]="i.rows" style="border-radius: 12px">
          <a [href]="[i.link]" class="a-region" style="display: flex; height: 100%;">
            <p class="title-region" style="align-self: flex-end;"> {{ i.title }} </p>
            <img [src]="i.img" alt="Image" class="card-img-top" style="width: 100%; height: 100%;">
          </a>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

      <!-- large screen & above -->
    <div class="top-banner" *ngIf="!isSmallScreen">
      <mat-grid-list cols="4" rowHeight="60px" gutterSize="24px">
        <mat-grid-tile *ngFor="let tile of tiles"
                       [colspan]="tile.cols"
                       [rowspan]="tile.rows" style="border-radius: 12px">
          <a [href]="[tile.link]" class="a-region" style="display: flex; height: 100%;">
            <p class="title-region" style="align-self: flex-end;"> {{ tile.title }} </p>
            <img [src]="tile.img" alt="Image" class="card-img-top" style="width: 100%; height: 100%;">
          </a>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
