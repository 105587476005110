import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PresetsService} from '../../../services/presets.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {IPreset, IUserPreset} from '../../../models/bookings.interface';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss']
})
export class PresetsComponent implements OnInit {
  user = AuthenticationService.getUser();

  userPresets: IUserPreset[];

  @Input() formPreset: FormGroup;

  @Output() selectPreset: EventEmitter<IPreset[]> = new EventEmitter<IPreset[]>();

  constructor(private presetsService: PresetsService) {
  }

  ngOnInit() {
    this.getPresetsByUser();
  }

  getPresetsByUser(): void {
    this.userPresets = this.presetsService.getPresetsByUser(this.user._id);
  }

  deletePreset(name): void {
    this.presetsService.remove(this.user._id, name);
    this.getPresetsByUser();
  }

}
