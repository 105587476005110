import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {HTTP_STATUS_CODE} from '../utils/http-status-code';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private authService: AuthenticationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = AuthenticationService.token();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'es',
          Authorization: token
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'es'
        }
      });
    }


    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => event, (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
              // this.authService.logout();
            }
          }
        })
      );
  }
}
