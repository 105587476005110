import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SistemaService} from './sistema/sistema.service';
import {IAgentDetail, IAgentListRequest} from '../models/agents.model';
import {Observable} from 'rxjs';
import {IListReponseData} from '../models/common.interface';
import {objectToQueryParams} from '../core/utils';
import {IAgency} from '../models/agency.interface';

@Injectable({providedIn: 'root'})
export class AgenciesService {
  private readonly agentEndpoint: string;
  private readonly enableAgentEndpoint: string;
  private readonly disableAgentEndpoint: string;

  constructor(private http: HttpClient, sistemaService: SistemaService) {
    this.agentEndpoint = sistemaService.url + '/agencies/';
    this.enableAgentEndpoint = sistemaService.url + '/enable-agencies/';
    this.disableAgentEndpoint = sistemaService.url + '/disable-agencies/';
  }

  list(params: IAgentListRequest): Observable<IListReponseData<IAgency>> {
    const queryParams = '?' + objectToQueryParams(params);
    return this.http.get<IListReponseData<IAgency>>(this.agentEndpoint + queryParams);
  }

  get(id: string): Observable<IAgency> {
    return this.http.get<IAgency>(this.agentEndpoint + id);
  }

  add(agency: IAgency): Observable<IAgentDetail> {
    return this.http.post<IAgentDetail>(this.agentEndpoint, agency);
  }

  put(agency: IAgency): Observable<IAgentDetail> {
    return this.http.put<IAgentDetail>(this.agentEndpoint, agency);
  }

  patch(agency: IAgency): Observable<IAgentDetail> {
    return this.http.patch<IAgentDetail>(this.agentEndpoint, agency);
  }

  delete(ids: string[]): Observable<any> {
    const body = {
      _id: ids
    };
    return this.http.request('delete', this.agentEndpoint, {body});
  }

  enable(ids: string[]): Observable<any> {
    return this.http.put(this.enableAgentEndpoint, ids);
  }

  disable(ids: string[]): Observable<any> {
    return this.http.put(this.disableAgentEndpoint, ids);
  }
}
