import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ITravelStyles} from '../../../../models/travel-styles.interface';

@Component({
  selector: 'app-travel-types',
  templateUrl: './travel-types.component.html',
  styleUrls: ['./travel-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravelTypesComponent implements OnInit {
  @Input() travelStyles: ITravelStyles[];
  iterateSkeletons = [1, 2, 3, 4];

  constructor() {
  }

  ngOnInit() {
  }

}
