import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  message: string;
  title: string;
  config: { yes: string, no: string };

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = this.data.message;
    this.title = this.data.title;
    this.config = this.data.config;
  }

  ngOnInit() {
  }

  confirm(response: boolean): void {
    this.dialogRef.close(response);
  }

}
