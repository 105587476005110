import {NgModule} from '@angular/core';
import {BookingFormComponent} from './booking-form.component';
import {MaterialModule} from '../../../../app.material';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {ProgressSpinnerOverlayModule} from '../../../../components/progress-spinner-overlay/progress-spinner-overlay.module';
import {NationalitiesAutocompleteModule} from '../../../../components/nationalities-autocomplete/nationalities-autocomplete.module';

@NgModule({
  declarations: [BookingFormComponent],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        ProgressSpinnerOverlayModule,
        NationalitiesAutocompleteModule
    ],
  exports: [BookingFormComponent]
})
export class BookingFormModule {
}
