<div class="defaultPresets">

  <div class="matSubHeader">Default</div>
  <mat-list>

    <mat-list-item (click)="selectPreset.emit(p.presets)" *ngFor="let p of userPresets">
      <mat-divider></mat-divider>
      <h3 matLine class="matLine"> {{p.name}} </h3>
      <div>
        <mat-icon matListIcon (click)="deletePreset(p.name)" checkboxPosition="after">clear</mat-icon>
      </div>

    </mat-list-item>

  </mat-list>


  <div class="presetsSave">

    <mat-checkbox class="save_preset" [formControl]="formPreset.get('save')">Save Preset</mat-checkbox>

    <input class="input-preset"
           [disabled]="!(formPreset.get('save').value)" placeholder="Type a Name" [formControl]="formPreset.get('name')"
           matInput>


  </div>


</div>
