import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Homev2Service} from '../../services/homev2.service';
import {IHome} from '../../models/homev2.interface';
import {ITravelStyles} from '../../models/travel-styles.interface';
import {IThemeDetail} from '../../models/theme.interface';
import {IRegion} from '../../models/region.interface';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-homev2',
  templateUrl: './homev2.component.html',
  styleUrls: ['./homev2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Homev2Component implements OnInit {
  home: IHome;
  travelStyles: ITravelStyles[];
  themes: IThemeDetail[];
  regions: IRegion[];

  agent = false;

  constructor(private homev2Service: Homev2Service, private authService: AuthenticationService) {
    this.agent = AuthenticationService.isAuthenticated();
  }

  ngOnInit(): void {
    this.getInitData();

    this.authService.getIsAuthenticated().subscribe(value => {
      this.agent = AuthenticationService.isAuthenticated();
    });
  }

  async getInitData(): Promise<void> {
    this.home = await this.homev2Service.getHome().toPromise();
    this.travelStyles = await this.homev2Service.getTravelStyles().toPromise();
    this.themes = await this.homev2Service.getThemes().toPromise();
    this.regions = await this.homev2Service.getRegions().toPromise();

    console.log(this.home);
  }
}
