import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SistemaService} from './sistema/sistema.service';
import {IAgencyListRequest, IAgencyStore, IStoreFull} from '../models/agents.model';
import {Observable} from 'rxjs';
import {IListReponseData} from '../models/common.interface';
import {objectToQueryParams} from '../core/utils';

@Injectable({providedIn: 'root'})
export class StoresService {
  private readonly storeEndpoint: string;
  private readonly enableStoreEndpoint: string;
  private readonly disableStoreEndpoint: string;

  constructor(private http: HttpClient, sistemaService: SistemaService) {
    this.storeEndpoint = sistemaService.url + '/agency-store/';
    this.enableStoreEndpoint = sistemaService.url + '/enable-agency-store/';
    this.disableStoreEndpoint = sistemaService.url + '/enable-agency-store/';
  }

  list(params: IAgencyListRequest): Observable<IListReponseData<IAgencyStore>> {
    const queryParams = '?' + objectToQueryParams(params);
    return this.http.get<IListReponseData<IAgencyStore>>(this.storeEndpoint + queryParams);
  }

  get(id: string): Observable<IStoreFull> {
    return this.http.get<IStoreFull>(this.storeEndpoint + id);
  }

  add(agency: IAgencyStore): Observable<IAgencyStore> {
    return this.http.post<IAgencyStore>(this.storeEndpoint, agency);
  }

  put(agency: IAgencyStore): Observable<IAgencyStore> {
    return this.http.put<IAgencyStore>(this.storeEndpoint, agency);
  }

  patch(agency: IAgencyStore): Observable<IAgencyStore> {
    return this.http.patch<IAgencyStore>(this.storeEndpoint, agency);
  }

  delete(ids: string[]): Observable<any> {
    const body = {
      _id: ids
    };
    return this.http.request('delete', this.storeEndpoint, {body});
  }

  enable(ids: string[]): Observable<any> {
    return this.http.put(this.enableStoreEndpoint, ids);
  }

  disable(ids: string[]): Observable<any> {
    return this.http.put(this.disableStoreEndpoint, ids);
  }
}
