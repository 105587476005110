import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import 'hammerjs';


if (environment.production) {

  // //load manually gtm script
  // const scriptGtm = document.createElement('script');
  // scriptGtm.async = true;
  // scriptGtm.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NBQ59HH';
  // document.head.appendChild(scriptGtm);
  //
  // // init gtm
  // const gtmScript = document.createElement('link');
  // gtmScript.innerHTML = `<script>
  // (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  // })(window,document,'script','dataLayer','GTM-NBQ59HH');
  // </script>`;
  // document.head.appendChild(gtmScript.firstChild);
  //
  // const gtmSrc = document.createElement('link');
  // gtmSrc.innerHTML = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NBQ59HH"
  //   height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
  // document.body.prepend(gtmSrc.firstChild);
  //
  //
  // const script = document.createElement('script');
  // script.innerHTML = `(function (w,i,d,g,e,t,s) {w[d] = w[d]||[];t= i.createElement(g);
  // t.async=1;t.src=e;s=i.getElementsByTagName(g)[0];s.parentNode.insertBefore(t, s);
  // })(window, document, '_gscq','script','//widgets.getsitecontrol.com/193018/script.js');`;
  // document.body.appendChild(script);

  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
