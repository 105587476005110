import {NgModule} from '@angular/core';
import {HomeBannerComponent} from './home-banner/home-banner.component';
import {TravelSearchComponent} from './travel-search/travel-search.component';
import {Homev2Component} from './homev2.component';
import {HomeSectionsModule} from './home-sections/home-sections.module';
import {BambaSharedModule} from '../../shared/bamba-shared.module';
import {ParallaxModule} from '../../components/parallax/parallax.module';
import {SearchAutocompleteModule} from '../../components/search-autocomplete/search-autocomplete.module';
import {HomeBookingsComponent} from './home-bookings/home-bookings.component';
import {BookingsModule} from '../bookings/bookings.module';
import {ResponsiveModule} from 'ngx-responsive';
import {Bookingsv2Module} from '../bookingsv2/bookingsv2.module';
import { ExperienceTheWordComponent } from './experience-the-word/experience-the-word.component';
import {DiscoverOurTopExperiencesComponent} from "./home-sections/discover-our-top-experiences/discover-our-top-experiences.component";
import { VjsPlayerComponent  } from './home-banner/vjs-player/vjs-player.component'
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    HomeBannerComponent, 
    TravelSearchComponent, 
    Homev2Component, 
    HomeBookingsComponent, 
    ExperienceTheWordComponent, 
    DiscoverOurTopExperiencesComponent,
    VjsPlayerComponent
  ],
    imports: [
        HomeSectionsModule,
        BambaSharedModule,
        ParallaxModule,
        SearchAutocompleteModule,
        BookingsModule,
        ResponsiveModule,
        Bookingsv2Module,
        RouterModule
    ],
  exports: [
    Homev2Component, 
    HomeBannerComponent, 
    TravelSearchComponent,
    VjsPlayerComponent
  ]
})
export class Homev2Module {
}
