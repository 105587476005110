export default [
    {
        _id: "5bb22989c8570e17cdf98b67",
        title: "home 1",
        dealsText: "View more deals",
        dealsTitle:
            '<p style="text-align: justify;"><strong>Take advantage</strong>of our dazzling deals</p>',
        recommendedText: "More recommendations",
        recommendedTitle:
            "<p><strong>Excitingly unique experiences</strong> for you!</p>",
        regionsTitle: "<p><strong>Experience our awe-inspiring world</strong></p>",
        themeTitle:
            "<p><strong>Curated experiences </strong>for your interests</p>",
        theme: [
            {
                _id: "5c6c3e65c8e8021433fd9733",
                name: "Bamba Authentic Backpacker",
                cols: 2,
                rows: 1,
                coverPhotoHome: {
                    public_id: "pjvh6czjdxob6odlg2t3",
                    version: 1567110512,
                    signature: "d2d8963e14512a443c06b0f3e52fdc33c7844da5",
                    width: 610,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110512/pjvh6czjdxob6odlg2t3.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110512/pjvh6czjdxob6odlg2t3.png",
                },
                lemaxId: 1009,
            },
            {
                _id: "5bc0f861be234623734713f5",
                name: "Voluntour",
                cols: 1,
                rows: 2,
                coverPhotoHome: {
                    public_id: "np3pc5afucigyqoqiyhv",
                    version: 1567110683,
                    signature: "296931613a0b2fda4661c930432eb508ccc05e60",
                    width: 294,
                    height: 387,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110683/np3pc5afucigyqoqiyhv.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110683/np3pc5afucigyqoqiyhv.png",
                },
                lemaxId: 968,
            },
            {
                _id: "5bc0f842be234623734713f3",
                name: "Yoga & Wellness",
                cols: 1,
                rows: 1,
                coverPhotoHome: {
                    public_id: "bwqxdis72nx5n8grbq6p",
                    version: 1567110705,
                    signature: "1dfb1802046ea1f3aac7ffc510ce0f4e6bc6174a",
                    width: 294,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110705/bwqxdis72nx5n8grbq6p.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110705/bwqxdis72nx5n8grbq6p.png",
                },
                lemaxId: 885,
            },
            {
                _id: "5bc0f22ebe234623734713c5",
                name: "Hiking & Trekking",
                cols: 2,
                rows: 1,
                coverPhotoHome: {
                    public_id: "ijn7o4mx8cdfldidtmg7",
                    version: 1567110560,
                    signature: "39a69f566b7cc6c5ed45aa43f402d05ebf15ba4a",
                    width: 610,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110560/ijn7o4mx8cdfldidtmg7.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110560/ijn7o4mx8cdfldidtmg7.png",
                },
                lemaxId: 863,
            },
            {
                _id: "5bc0f8c3be234623734713fc",
                name: "18 to 30 something",
                cols: 1,
                rows: 1,
                coverPhotoHome: {
                    public_id: "kylklbh3cbapgns8wa11",
                    version: 1567110503,
                    signature: "0d1beee75571292da5cf71b9ddbff08dae505cee",
                    width: 294,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110503/kylklbh3cbapgns8wa11.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110503/kylklbh3cbapgns8wa11.png",
                },
                lemaxId: 977,
            },
            {
                _id: "5bc0ea77be234623734713c2",
                name: "Safari",
                cols: 2,
                rows: 1,
                coverPhotoHome: {
                    public_id: "rkgeq93cjnryiuxltfr5",
                    version: 1567110628,
                    signature: "ffc47514ae63dac481948ec6ac9c460b98891f43",
                    width: 610,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110628/rkgeq93cjnryiuxltfr5.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110628/rkgeq93cjnryiuxltfr5.png",
                },
                lemaxId: 923,
            },
            {
                _id: "5bc0e9f7be234623734713c1",
                name: "Adventure",
                cols: 1,
                rows: 1,
                coverPhotoHome: {
                    public_id: "iyebubopfsihuk1zj5xf",
                    version: 1567110600,
                    signature: "0cb4358bfafdc45d75f36aaa5043b672309055c9",
                    width: 294,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110600/iyebubopfsihuk1zj5xf.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110600/iyebubopfsihuk1zj5xf.png",
                },
                lemaxId: 858,
            },
            {
                _id: "5bc0f44bbe234623734713de",
                name: "Surfing",
                cols: 1,
                rows: 1,
                coverPhotoHome: {
                    public_id: "buaibctrhkw0j0qdtlep",
                    version: 1567110654,
                    signature: "c34742b643ba63c64257da175874da7f5a78ba7c",
                    width: 294,
                    height: 186,
                    format: "png",
                    resource_type: "image",
                    url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110654/buaibctrhkw0j0qdtlep.png",
                    secure_url:
                        "https://res.cloudinary.com/keystone-demo/image/upload/v1567110654/buaibctrhkw0j0qdtlep.png",
                },
                lemaxId: 823,
            },
        ],
        search: [
            {
                _id: "5d670a25cc24de59a1a35747",
                key: "where-s-your-next-experience",
                name: "Where's your next experience?",
                __v: 0,
            },
        ],
        nameInstagram: [
            {
                _id: "5b9c411a74d2b16f6733f636",
                key: "instagram",
                name: "Instagram",
                __v: 1,
                url: "Url",
                photo: [
                    {
                        _id: "5b9c414a74d2b16f6733f637",
                        secure_url:
                            "https://res.cloudinary.com/keystone-demo/image/upload/v1536966985/wjxng8tjczrtgt9ot3ky.jpg",
                        url:
                            "https://res.cloudinary.com/keystone-demo/image/upload/v1536966985/wjxng8tjczrtgt9ot3ky.jpg",
                        resource_type: "image",
                        format: "jpg",
                        height: 4032,
                        width: 3024,
                        signature: "4aed02e7de791fa87e0c682ad74b58aa8a645ec1",
                        version: 1536966985,
                        public_id: "wjxng8tjczrtgt9ot3ky",
                    },
                ],
            },
        ],
        travelDeals: [
            {
                _id: "5cf05d794e8d75166d4a13e1",
                key: "torres-del-paine-w-trek-5d-4n",
                name: "Torres del Paine W Trek 5D/4N",
                __v: 1,
                lemaxTourId: 46297,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1876422ed7a16d4272fa33",
                    TourID: 46297,
                    TourTitle: "Torres del Paine W Trek 5D/4N (Self-Guided)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28743,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Torres del Paine W Trek 5D-4N (Self-Guided)/TorresdelPaineWTrek5D01.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Torres del Paine W Trek 5D-4N (Self-Guided)/TorresdelPaineWTrek5D01.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409c6",
                            name: "Chile",
                            lemaxId: 167,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1876422ed7a16d4272fa34",
                        Discount: {
                            Amount: "1187",
                            Percent: "20",
                            Discount: 237.4,
                            Total: 949.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1187,
                        PriceOriginalFormatted: "1187.00",
                        Price: 949.6,
                        PriceFormatted: "949.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5c33d00251a87a07bb92d652",
                key: "uyuni-salt-flats-and-desert-adventure-4d-3n-la-paz-to-atacama",
                name: "Uyuni Salt Flats & Desert Adventure 4D/3N (La Paz to Atacama)",
                __v: 1,
                lemaxTourId: 38161,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e1895882ed7a16d42733a1c",
                    TourID: 38161,
                    TourTitle:
                        "Uyuni Salt Flats & Desert Adventure 4D/3N (La Paz to Atacama)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28278,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Uyuni Salt Flats & Desert Adventure 4D-3N (La Paz to Atacama)/UyuniSaltFlatsDesertAdventure4DLaPaztoAtacama02.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Uyuni Salt Flats & Desert Adventure 4D-3N (La Paz to Atacama)/UyuniSaltFlatsDesertAdventure4DLaPaztoAtacama02.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a1",
                            name: "Bolivia",
                            lemaxId: 122,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c6",
                            name: "Chile",
                            lemaxId: 167,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 2,
                    DurationDD: 4,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1895882ed7a16d42733a1d",
                        Discount: {
                            Amount: "431",
                            Percent: "20",
                            Discount: 86.2,
                            Total: 344.8,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 431,
                        PriceOriginalFormatted: "431.00",
                        Price: 344.8,
                        PriceFormatted: "344.80",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf147cb4e8d75166d4a13ee",
                key: "mykonos-and-santorini-island-hopping-7d-6n",
                name: "Mykonos & Santorini Island Hopping 7D/6N",
                __v: 1,
                lemaxTourId: 5665,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e11831aacae2d291c468d89",
                    TourID: 5665,
                    TourTitle: "Mykonos & Santorini Island Hopping Adventure 7D/6N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28211,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Mykonos & Santorini Island Hopping Adventure 7D-6N/MykonosSantoriniIslandHoppingAdventure7D04.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Mykonos & Santorini Island Hopping Adventure 7D-6N/MykonosSantoriniIslandHoppingAdventure7D04.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 988,
                            MainThemeName: "Marine Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409d4",
                            name: "Greece",
                            lemaxId: 316,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 7,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e11831aacae2d291c468d8a",
                        Discount: {
                            Amount: "770",
                            Percent: "20",
                            Discount: 154,
                            Total: 616,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 770,
                        PriceOriginalFormatted: "770.00",
                        Price: 616,
                        PriceFormatted: "616.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf148f14e8d75166d4a13ef",
                key: "kenya-and-tanzania-4wd-safari-adventure-10d-9n",
                name: "Kenya & Tanzania 4WD Safari Adventure 10D/9N",
                __v: 1,
                lemaxTourId: 38960,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e14c1a9fcf5e3370153ffcd",
                    TourID: 38960,
                    TourTitle: "Kenya & Tanzania 4WD Safari Adventure 10D/9N",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28230,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Kenya & Tanzania 4WD Safari Adventure 10D-9N/KenyaTanzania4WDSafariAdventure10D01.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Kenya & Tanzania 4WD Safari Adventure 10D-9N/KenyaTanzania4WDSafariAdventure10D01.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 985,
                            MainThemeName: "Safari",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409e1",
                            name: "Kenya",
                            lemaxId: 65,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409fa",
                            name: "Tanzania",
                            lemaxId: 89,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 2,
                    DurationDD: 10,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c1a9fcf5e3370153ffce",
                        Discount: {
                            Amount: "2407",
                            Percent: "20",
                            Discount: 481.40000000000003,
                            Total: 1925.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 2407,
                        PriceOriginalFormatted: "2407.00",
                        Price: 1925.6,
                        PriceFormatted: "1925.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5c33cf8b51a87a07bb92d651",
                key: "mount-kilimanjaro-bamba-trek-machame-route-9d-8n",
                name: "Mount Kilimanjaro bamba Trek (Machame Route) 9D/8N",
                __v: 1,
                lemaxTourId: 50650,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c207fcf5e3370153ffeb",
                    TourID: 50650,
                    TourTitle: "Mount Kilimanjaro Bamba Trek (Machame Route) 9D/8N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 30861,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Mount Kilimanjaro Bamba Trek (Machame Route) 9D-8N/MountKilimanjaroBambaTrek9D4.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Mount Kilimanjaro Bamba Trek (Machame Route) 9D-8N/MountKilimanjaroBambaTrek9D4.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 1011,
                            MainThemeName: "Bamba Group Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409fa",
                            name: "Tanzania",
                            lemaxId: 89,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 9,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c207fcf5e3370153ffec",
                        Discount: {
                            Amount: "2750",
                            Percent: "20",
                            Discount: 550,
                            Total: 2200,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 2750,
                        PriceOriginalFormatted: "2750.00",
                        Price: 2200,
                        PriceFormatted: "2200.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf14a554e8d75166d4a13f2",
                key:
                    "highlights-of-tanzania-safari-6d-5n-tarangire-serengeti-and-ngorongoro",
                name:
                    "Highlights of Tanzania Safari 6D/5N (Tarangire, Serengeti & Ngorongoro)",
                __v: 1,
                lemaxTourId: 21607,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c226fcf5e3370154001e",
                    TourID: 21607,
                    TourTitle:
                        "Highlights of Tanzania Safari 6D/5N (Tarangire, Serengeti & Ngorongoro)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 29155,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Tanzania Safari 6D-5N (Tarangire, Serengeti & Ngorongoro)/HighlightsofTanzaniaSafari6DTarangireSerengetiNgorongoro1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Tanzania Safari 6D-5N (Tarangire, Serengeti & Ngorongoro)/HighlightsofTanzaniaSafari6DTarangireSerengetiNgorongoro1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 985,
                            MainThemeName: "Safari",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409fa",
                            name: "Tanzania",
                            lemaxId: 89,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 6,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c226fcf5e3370154001f",
                        Discount: {
                            Amount: "1862",
                            Percent: "20",
                            Discount: 372.40000000000003,
                            Total: 1489.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1862,
                        PriceOriginalFormatted: "1862.00",
                        Price: 1489.6,
                        PriceFormatted: "1489.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf143dc4e8d75166d4a13e8",
                key: "costa-rica-voluntour-15d-14n",
                name: "Costa Rica Voluntour 15D/14N",
                __v: 1,
                lemaxTourId: 45576,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e1899a02ed7a16d42733b0d",
                    TourID: 45576,
                    TourTitle: "Costa Rica Voluntour 15D/14N",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28076,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Costa Rica Voluntour 15D-14N/CostaRicaVoluntour15D19.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Costa Rica Voluntour 15D-14N/CostaRicaVoluntour15D19.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 986,
                            MainThemeName: "Volunteer / Voluntour",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409c9",
                            name: "Costa Rica",
                            lemaxId: 594,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 15,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1899a02ed7a16d42733b0e",
                        Discount: {
                            Amount: "1259",
                            Percent: "20",
                            Discount: 251.8,
                            Total: 1007.2,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1259,
                        PriceOriginalFormatted: "1259.00",
                        Price: 1007.2,
                        PriceFormatted: "1007.20",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf144e74e8d75166d4a13ea",
                key: "highlights-of-mexico-guided-experience-10d-9n",
                name: "Highlights of Mexico Guided Experience 10D/9N",
                __v: 1,
                lemaxTourId: 48250,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c332fcf5e337015400a3",
                    TourID: 48250,
                    TourTitle: "Highlights of Mexico Guided Experience 10D/9N",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 29176,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Mexico Guided Experience 10D-9N/HighlightsofMexicoGuidedExperience10D10.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Mexico Guided Experience 10D-9N/HighlightsofMexicoGuidedExperience10D10.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 881,
                            MainThemeName: "Cultural",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a5",
                            name: "Mexico",
                            lemaxId: 652,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 10,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c332fcf5e337015400a4",
                        Discount: {
                            Amount: "1802",
                            Percent: "20",
                            Discount: 360.40000000000003,
                            Total: 1441.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1802,
                        PriceOriginalFormatted: "1802.00",
                        Price: 1441.6,
                        PriceFormatted: "1441.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf063664e8d75166d4a13e3",
                key: "lorenzo-ways-from-lima",
                name: "Lorenzo Ways (from Lima)",
                __v: 1,
                lemaxTourId: 29232,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e1896fd2ed7a16d42733aac",
                    TourID: 29232,
                    TourTitle: "Lorenzo Ways (from Lima)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 30017,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Lorenzo Ways (from Lima)/LorenzoWaysFromLima1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Lorenzo Ways (from Lima)/LorenzoWaysFromLima1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865a1",
                            name: "Bolivia",
                            lemaxId: 122,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c6",
                            name: "Chile",
                            lemaxId: 167,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 3,
                    DurationDD: 35,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1896fd2ed7a16d42733aad",
                        Discount: {
                            Amount: "1535",
                            Percent: "20",
                            Discount: 307,
                            Total: 1228,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1535,
                        PriceOriginalFormatted: "1535.00",
                        Price: 1228,
                        PriceFormatted: "1228.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf143ae4e8d75166d4a13e7",
                key: "puerto-maldonado-amazon-eco-lodge-5d-4n",
                name: "Puerto Maldonado Amazon Eco-Lodge 5D/4N",
                __v: 1,
                lemaxTourId: 36692,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e14c49bfcf5e3370154014e",
                    TourID: 36692,
                    TourTitle: "Puerto Maldonado Amazon Eco-Lodge 5D/4N (from Cuzco)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 30684,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Puerto Maldonado Amazon Eco-Lodge 5D-4N (from Cuzco)/PuertoMaldonadoAmazonEcoLodge5DfromCuzco07.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Puerto Maldonado Amazon Eco-Lodge 5D-4N (from Cuzco)/PuertoMaldonadoAmazonEcoLodge5DfromCuzco07.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c49bfcf5e3370154014f",
                        Discount: {
                            Amount: "508",
                            Percent: "20",
                            Discount: 101.60000000000001,
                            Total: 406.4,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 508,
                        PriceOriginalFormatted: "508.00",
                        Price: 406.4,
                        PriceFormatted: "406.40",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf1475f4e8d75166d4a13ed",
                key: "iceland-adventurer-6d-5n",
                name: "Iceland Adventurer 6D/5N",
                __v: 1,
                lemaxTourId: 29716,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c4b2fcf5e33701540151",
                    TourID: 29716,
                    TourTitle: "Iceland Adventurer 6D/5N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28790,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Iceland Adventurer 6D-5N/IcelandAdventurer6D11.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Iceland Adventurer 6D-5N/IcelandAdventurer6D11.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409d8",
                            name: "Iceland",
                            lemaxId: 330,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 6,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c4b2fcf5e33701540152",
                        Discount: {
                            Amount: "2662",
                            Percent: "20",
                            Discount: 532.4,
                            Total: 2129.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 2662,
                        PriceOriginalFormatted: "2662.00",
                        Price: 2129.6,
                        PriceFormatted: "2129.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5c33d2c451a87a07bb92d658",
                key: "mai-pen-rai-ways-from-hanoi",
                name: "Mai Pen Rai Ways (from Hanoi)",
                __v: 1,
                lemaxTourId: 16627,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e160406fcf5e3370154378a",
                    TourID: 16627,
                    TourTitle: "Mai Pen Rai Ways (from Hanoi)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 29565,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Mai Pen Rai Ways (from Hanoi)/MaiPenRaiWaysFromHanoi1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Mai Pen Rai Ways (from Hanoi)/MaiPenRaiWaysFromHanoi1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b57340a00",
                            name: "Vietnam",
                            lemaxId: 580,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c4",
                            name: "Cambodia",
                            lemaxId: 445,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865ab",
                            name: "Thailand",
                            lemaxId: 559,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409e4",
                            name: "Malaysia",
                            lemaxId: 43,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409f7",
                            name: "Singapore",
                            lemaxId: 40,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 5,
                    DurationDD: 43,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e160406fcf5e3370154378b",
                        Discount: {
                            Amount: "1969",
                            Percent: "20",
                            Discount: 393.8,
                            Total: 1575.2,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1969,
                        PriceOriginalFormatted: "1969.00",
                        Price: 1575.2,
                        PriceFormatted: "1575.20",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf149544e8d75166d4a13f0",
                key: "masai-mara-interactive-lake-nakuru-and-amboseli-safari-6d-5n",
                name: "Masai Mara Interactive, Lake Nakuru & Amboseli Safari 6D/5N",
                __v: 1,
                lemaxTourId: 5613,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c5c1fcf5e337015401ba",
                    TourID: 5613,
                    TourTitle:
                        "Masai Mara Interactive, Lake Nakuru & Amboseli Safari 6D/5N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 29061,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Masai Mara Interactive, Lake Nakuru & Amboseli Safari 6D-5N/MasaiMaraInteractiveLakeNakuruAmboseliSafari6D08.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Masai Mara Interactive, Lake Nakuru & Amboseli Safari 6D-5N/MasaiMaraInteractiveLakeNakuruAmboseliSafari6D08.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 985,
                            MainThemeName: "Safari",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409e1",
                            name: "Kenya",
                            lemaxId: 65,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 6,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c5c1fcf5e337015401bb",
                        Discount: {
                            Amount: "1582",
                            Percent: "20",
                            Discount: 316.40000000000003,
                            Total: 1265.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1582,
                        PriceOriginalFormatted: "1582.00",
                        Price: 1265.6,
                        PriceFormatted: "1265.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf1421e4e8d75166d4a13e6",
                key: "uyuni-salt-flats-air-expedition-3d-2n",
                name: "Uyuni Salt Flats Air-Expedition 3D/2N",
                __v: 1,
                lemaxTourId: 38164,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e1899c92ed7a16d42733b34",
                    TourID: 38164,
                    TourTitle: "Uyuni Salt Flats Air-Expedition 3D/2N (Budget)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 24707,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Uyuni Salt Flats Air-Expedition 3D-2N (Budget)/Uyunitoamazon-7.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Uyuni Salt Flats Air-Expedition 3D-2N (Budget)/Uyunitoamazon-7.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a1",
                            name: "Bolivia",
                            lemaxId: 122,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 3,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1899c92ed7a16d42733b35",
                        Discount: {
                            Amount: "779",
                            Percent: "20",
                            Discount: 155.8,
                            Total: 623.2,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 779,
                        PriceOriginalFormatted: "779.00",
                        Price: 623.2,
                        PriceFormatted: "623.20",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf141b34e8d75166d4a13e5",
                key: "machu-picchu-by-train-2d-1n",
                name: "Machu Picchu by Train 2D/1N",
                __v: 1,
                lemaxTourId: 10097,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1885d02ed7a16d42733682",
                    TourID: 10097,
                    TourTitle: "Machu Picchu by Train 2D/1N (Sleep in Aguas Calientes)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28712,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Machu Picchu by Train 2D-1N (Sleep in Aguas Calientes)/MachuPicchubyTrain2D06.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Machu Picchu by Train 2D-1N (Sleep in Aguas Calientes)/MachuPicchubyTrain2D06.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 2,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1885d02ed7a16d42733683",
                        Discount: {
                            Amount: "575",
                            Percent: "20",
                            Discount: 115,
                            Total: 460,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 575,
                        PriceOriginalFormatted: "575.00",
                        Price: 460,
                        PriceFormatted: "460.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf144774e8d75166d4a13e9",
                key: "conchita-ways-from-antigua",
                name: "Conchita Ways (from Antigua)",
                __v: 1,
                lemaxTourId: 35984,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e18a9132ed7a16d42733dcf",
                    TourID: 35984,
                    TourTitle: "Conchita Ways (from Antigua)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28125,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Conchita Ways (from Antigua)/ConchitaWaysfromAntigua1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Conchita Ways (from Antigua)/ConchitaWaysfromAntigua1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409d5",
                            name: "Guatemala",
                            lemaxId: 635,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c1",
                            name: "Belize",
                            lemaxId: 608,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865a5",
                            name: "Mexico",
                            lemaxId: 652,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 3,
                    DurationDD: 18,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e18a9132ed7a16d42733dd0",
                        Discount: {
                            Amount: "737",
                            Percent: "20",
                            Discount: 147.4,
                            Total: 589.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 737,
                        PriceOriginalFormatted: "737.00",
                        Price: 589.6,
                        PriceFormatted: "589.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf146294e8d75166d4a13eb",
                key:
                    "sedona-monument-valley-and-antelope-canyon-experience-3d-2n-from-las-vegas",
                name:
                    "Sedona, Monument Valley & Antelope Canyon Experience 3D/2N (from Las Vegas)\t",
                __v: 1,
                lemaxTourId: 6873,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1189dcacae2d291c468dff",
                    TourID: 6873,
                    TourTitle:
                        "Sedona, Monument Valley & Antelope Canyon Experience 3D/2N (from Las Vegas)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28219,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Sedona, Monument Valley & Antelope Canyon Experience 3D-2N (from Las Vegas)/SedonaMonumentValleyAntelopeCanyonExperience3D01.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Sedona, Monument Valley & Antelope Canyon Experience 3D-2N (from Las Vegas)/SedonaMonumentValleyAntelopeCanyonExperience3D01.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409ff",
                            name: "USA",
                            lemaxId: 2,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 3,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1189dcacae2d291c468e00",
                        Discount: {
                            Amount: "635",
                            Percent: "20",
                            Discount: 127,
                            Total: 508,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 635,
                        PriceOriginalFormatted: "635.00",
                        Price: 508,
                        PriceFormatted: "508.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee7094e8d75166d4a13cd",
                key: "japan-highlights-8d-7n",
                name: "Japan Highlights 8D/7N",
                __v: 1,
                lemaxTourId: 50540,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e11a5beacae2d291c469055",
                    TourID: 50540,
                    TourTitle: "Japan Highlights 8D/7N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 30501,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Japan Highlights 8D-7N/JapanHighlights8D5.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Japan Highlights 8D-7N/JapanHighlights8D5.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 881,
                            MainThemeName: "Cultural",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409de",
                            name: "Japan",
                            lemaxId: 494,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 8,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e11a5beacae2d291c469056",
                        Discount: {
                            Amount: "1412",
                            Percent: "20",
                            Discount: 282.40000000000003,
                            Total: 1129.6,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 1412,
                        PriceOriginalFormatted: "1412.00",
                        Price: 1129.6,
                        PriceFormatted: "1129.60",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf147124e8d75166d4a13ec",
                key: "niagara-falls-experience-2d-1n-from-nyc",
                name: "Niagara Falls Experience 2D/1N (from NYC)",
                __v: 1,
                lemaxTourId: 6821,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14c939fcf5e33701540355",
                    TourID: 6821,
                    TourTitle: "Niagara Falls Experience 2D/1N (from New York)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 2222,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Niagara Falls Experience 2D-1N (from New York)/NiagaraFallsExp2DNYC_google_9.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Niagara Falls Experience 2D-1N (from New York)/NiagaraFallsExp2DNYC_google_9.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409ff",
                            name: "USA",
                            lemaxId: 2,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 2,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14c939fcf5e33701540356",
                        Discount: {
                            Amount: "396",
                            Percent: "20",
                            Discount: 79.2,
                            Total: 316.8,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 396,
                        PriceOriginalFormatted: "396.00",
                        Price: 316.8,
                        PriceFormatted: "316.80",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cf141774e8d75166d4a13e4",
                key: "lost-city-trek-5d-4n",
                name: "Lost City Trek 5D/4N",
                __v: 1,
                lemaxTourId: 19668,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e172e492ed7a16d4272195f",
                    TourID: 19668,
                    TourTitle: "Lost City Trek 5D/4N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28662,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Lost City Trek 5D-4N/LostCityTrek5D4.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Lost City Trek 5D-4N/LostCityTrek5D4.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a3",
                            name: "Colombia",
                            lemaxId: 184,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e172e492ed7a16d42721960",
                        Discount: {
                            Amount: "559",
                            Percent: "20",
                            Discount: 111.80000000000001,
                            Total: 447.2,
                        },
                        SpecialOffer: null,
                        PriceOriginal: 559,
                        PriceOriginalFormatted: "559.00",
                        Price: 447.2,
                        PriceFormatted: "447.20",
                        BillingTypeName: "",
                    },
                },
            },
        ],
        recommendedForYou: [
            {
                _id: "5c06e8f8b9e1b24a22f10e1a",
                key: "inca-trail-trek-to-machu-picchu-4d-3n",
                name: "Inca Trail Trek to Machu Picchu 4D/3N",
                __v: 1,
                lemaxTourId: 425,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1860142ed7a16d42729026",
                    TourID: 425,
                    TourTitle:
                        "Inca Trail Trek to Machu Picchu 4D/3N (Start Trek on Day 1)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 27793,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Inca Trail Trek to Machu Picchu 4D-3N (Start Trek on Day 1)/IncaOk12.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Inca Trail Trek to Machu Picchu 4D-3N (Start Trek on Day 1)/IncaOk12.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 4,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1860142ed7a16d42729027",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 780,
                        PriceFormatted: "780.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee4304e8d75166d4a13c4",
                key: "buddha-bamba-ways-from-bangkok-clockwise",
                name: "Buddha Bamba Ways (from Bangkok / Clockwise)",
                __v: 1,
                lemaxTourId: 16214,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e1255e9adba8b341ad323ad",
                    TourID: 16214,
                    TourTitle: "Buddha bamba Ways (from Bangkok / Clockwise)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28518,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Buddha bamba Ways (from Bangkok - Clockwise)/BuddhaBambaWays1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Buddha bamba Ways (from Bangkok - Clockwise)/BuddhaBambaWays1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865ab",
                            name: "Thailand",
                            lemaxId: 559,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409e3",
                            name: "Laos",
                            lemaxId: 508,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b57340a00",
                            name: "Vietnam",
                            lemaxId: 580,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c4",
                            name: "Cambodia",
                            lemaxId: 445,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865ab",
                            name: "Thailand",
                            lemaxId: 559,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 5,
                    DurationDD: 37,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1255e9adba8b341ad323ae",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 1923,
                        PriceFormatted: "1923.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5e14f24e7533a26c3898b022",
                key: "bamba-salkantay-eco-dome-hike-and-zip-line-to-machu-picchu-5d-4n",
                name: "bamba Salkantay Eco-dome Hike & Zip-line to Machu Picchu 5D/4N",
                __v: 1,
                lemaxTourId: 50236,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e160d18c16dc26a9793f684",
                    TourID: 50236,
                    TourTitle:
                        "bamba Salkantay Eco-dome Hike & Zip-line to Machu Picchu 5D/4N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 30230,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/bamba Salkantay Eco-dome Hike & Zip-line to Machu Picchu 5D-4N/BambaSalkantayEcodomeHikeZiplinetoMachuPicchu5D21.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/bamba Salkantay Eco-dome Hike & Zip-line to Machu Picchu 5D-4N/BambaSalkantayEcodomeHikeZiplinetoMachuPicchu5D21.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e160d18c16dc26a9793f685",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 753,
                        PriceFormatted: "753.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5e14f2797533a26c3898b023",
                key:
                    "highlights-of-tanzania-safari-5d-4n-tarangire-serengeti-and-ngorongoro",
                name:
                    "Highlights of Tanzania Safari 5D/4N (Tarangire, Serengeti & Ngorongoro)",
                __v: 1,
                lemaxTourId: 10277,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14f7ecfcf5e33701540e7b",
                    TourID: 10277,
                    TourTitle:
                        "Highlights of Tanzania Safari 5D/4N (Tarangire, Serengeti & Ngorongoro)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 29144,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Tanzania Safari 5D-4N (Tarangire, Serengeti & Ngorongoro)/HighlightsofTanzaniaSafari5DTarangireSerengetiNgorongoro02.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Highlights of Tanzania Safari 5D-4N (Tarangire, Serengeti & Ngorongoro)/HighlightsofTanzaniaSafari5DTarangireSerengetiNgorongoro02.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 985,
                            MainThemeName: "Safari",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409fa",
                            name: "Tanzania",
                            lemaxId: 89,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14f7ecfcf5e33701540e7c",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 1552,
                        PriceFormatted: "1552.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee17b4e8d75166d4a13bd",
                key: "machu-picchu-jungle-trek-4d-3n-biking-zip-line-and-rafting",
                name: "Machu Picchu Jungle Trek 4D/3N (Biking, Zip-Line & Rafting)",
                __v: 1,
                lemaxTourId: 11848,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e186e672ed7a16d4272f524",
                    TourID: 11848,
                    TourTitle:
                        "Machu Picchu Jungle Trek 4D/3N (Biking, Zip-Line & Rafting)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28118,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Machu Picchu Jungle Trek 4D-3N (Biking, Zip-Line & Rafting)/MachuPicchuJungleTrek4DBikingZipLine20.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Machu Picchu Jungle Trek 4D-3N (Biking, Zip-Line & Rafting)/MachuPicchuJungleTrek4DBikingZipLine20.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 4,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e186e672ed7a16d4272f525",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 686,
                        PriceFormatted: "686.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5e14f2b87533a26c3898b024",
                key: "halong-bay-party-cruise-3d-2n-from-hanoi",
                name: "Halong Bay Party Cruise 3D/2N (from Hanoi)",
                __v: 1,
                lemaxTourId: 6589,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e160846fcf5e337015438d4",
                    TourID: 6589,
                    TourTitle: "Halong Bay Party Cruise 3D/2N (from Hanoi)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 30110,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Halong Bay Party Cruise 3D-2N (from Hanoi)/HalongBayPartyCruise03.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Halong Bay Party Cruise 3D-2N (from Hanoi)/HalongBayPartyCruise03.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 988,
                            MainThemeName: "Marine Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b57340a00",
                            name: "Vietnam",
                            lemaxId: 580,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 3,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e160846fcf5e337015438d5",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 293,
                        PriceFormatted: "293.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee2e64e8d75166d4a13c0",
                key: "chicha-ways-from-lima",
                name: "Chicha Ways (from Lima)",
                __v: 1,
                lemaxTourId: 36287,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e14d8e2fcf5e33701540855",
                    TourID: 36287,
                    TourTitle: "Chicha Ways (from Lima)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28670,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Chicha Ways (from Lima)/ChichaWaysfromLima1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Chicha Ways (from Lima)/ChichaWaysfromLima1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865a1",
                            name: "Bolivia",
                            lemaxId: 122,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 2,
                    DurationDD: 20,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14d8e2fcf5e33701540856",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 771,
                        PriceFormatted: "771.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5d152de8b50ce06af324cfe1",
                key: "masai-mara-voluntour-10d-9n",
                name: "Masai Mara Voluntour 10D/9N",
                __v: 1,
                lemaxTourId: 5620,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e118300acae2d291c468d85",
                    TourID: 5620,
                    TourTitle: "Masai Mara Voluntour 10D/9N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28555,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Masai Mara Voluntour 10D-9N/MasaiMaraVoluntour10D13.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Masai Mara Voluntour 10D-9N/MasaiMaraVoluntour10D13.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 986,
                            MainThemeName: "Volunteer / Voluntour",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409e1",
                            name: "Kenya",
                            lemaxId: 65,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 10,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Independent",
                            shortName: "INDEPENDENT",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e118300acae2d291c468d86",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 1323,
                        PriceFormatted: "1323.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee0574e8d75166d4a13bc",
                key: "lares-trek-to-machu-picchu-4d-3n-start-trek-on-day-1",
                name: "Lares Trek to Machu Picchu 4D/3N (Start Trek on Day 1)",
                __v: 1,
                lemaxTourId: 10085,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1865142ed7a16d4272ad03",
                    TourID: 10085,
                    TourTitle: "Lares Trek to Machu Picchu 4D/3N (Start Trek on Day 1)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28489,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Lares Trek to Machu Picchu 4D-3N (Start Trek on Day 1)/LaresTrektoMachuPicchu4DStartTrekonDay118.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Lares Trek to Machu Picchu 4D-3N (Start Trek on Day 1)/LaresTrektoMachuPicchu4DStartTrekonDay118.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 4,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1865142ed7a16d4272ad04",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 769,
                        PriceFormatted: "769.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee65a4e8d75166d4a13cb",
                key: "lolita-ways-from-mexico-city",
                name: "Lolita Ways (from Mexico City)",
                __v: 1,
                lemaxTourId: 35777,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e14ecf8fcf5e33701540b11",
                    TourID: 35777,
                    TourTitle: "Lolita Ways (from Mexico City)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 28123,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Lolita Ways (from Mexico City)/LolitaWaysFromMexicoCity1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Lolita Ways (from Mexico City)/LolitaWaysFromMexicoCity1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a5",
                            name: "Mexico",
                            lemaxId: 652,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 14,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14ecf8fcf5e33701540b12",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 738,
                        PriceFormatted: "738.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee38f4e8d75166d4a13c2",
                key: "japan-backpacker-adventure-10d-9n",
                name: "Japan Backpacker Adventure 10D/9N",
                __v: 1,
                lemaxTourId: 46781,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e14d951fcf5e33701540866",
                    TourID: 46781,
                    TourTitle: "Japan Backpacker Adventure 10D/9N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28683,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Japan Backpacker Adventure 10D-9N/JapanBackpackerAdventure10D14.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Japan Backpacker Adventure 10D-9N/JapanBackpackerAdventure10D14.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 1009,
                            MainThemeName: "bamba Authentic Backpacker",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409de",
                            name: "Japan",
                            lemaxId: 494,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 10,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e14d951fcf5e33701540867",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 2172,
                        PriceFormatted: "2172.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee6694e8d75166d4a13cc",
                key: "teotihuacan-hot-air-balloon-day-tour",
                name: "Teotihuacan Hot Air Balloon Day Tour",
                __v: 1,
                lemaxTourId: 4384,
                tourType: ["5c3cf4ed15f5da4e8218fddf"],
                tour: {
                    _id: "5e13d6ccadba8b341ad32c4f",
                    TourID: 4384,
                    TourTitle: "Teotihuacan Hot Air Balloon Day Tour",
                    Type: 115,
                    TypeName: "Activity",
                    Galery: [
                        {
                            PhotoID: 28297,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Teotihuacan Hot Air Balloon Day Tour/TeotihuacanHotAirBalloon01.jpeg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Teotihuacan Hot Air Balloon Day Tour/TeotihuacanHotAirBalloon01.jpeg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a5",
                            name: "Mexico",
                            lemaxId: 652,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 1,
                    DurationHR: "8",
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e13d6ccadba8b341ad32c50",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 228,
                        PriceFormatted: "228.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee4d54e8d75166d4a13c7",
                key: "tortuguero-national-park-adventure-4d-3n",
                name: "Tortuguero National Park Adventure 4D/3N",
                __v: 1,
                lemaxTourId: 21653,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e119154acae2d291c468eab",
                    TourID: 21653,
                    TourTitle: "Tortuguero National Park Adventure 4D/3N",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 27989,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Tortuguero National Park Adventure 4D-3N/TortugueroNationalParkAdv4D01.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Tortuguero National Park Adventure 4D-3N/TortugueroNationalParkAdv4D01.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 983,
                            MainThemeName: "Nature & Adventure",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b573409c9",
                            name: "Costa Rica",
                            lemaxId: 594,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 4,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e119154acae2d291c468eac",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 467,
                        PriceFormatted: "467.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5c1949c6b00b477e3ec66301",
                key: "salkantay-trek-to-machu-picchu-5d-4n",
                name: "Salkantay Trek to Machu Picchu 5D/4N",
                __v: 1,
                lemaxTourId: 10190,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e1861512ed7a16d42729030",
                    TourID: 10190,
                    TourTitle:
                        "Salkantay Trek to Machu Picchu 5D/4N (Start Trek on Day 1)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28918,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Salkantay Trek to Machu Picchu 5D-4N (Start Trek on Day 1)/SalkantayTrektoMachuPicchu5D21.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Salkantay Trek to Machu Picchu 5D-4N (Start Trek on Day 1)/SalkantayTrektoMachuPicchu5D21.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 984,
                            MainThemeName: "Hiking & Trekking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a7",
                            name: "Peru",
                            lemaxId: 212,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e1861512ed7a16d42729031",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 710,
                        PriceFormatted: "710.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5cdee4b24e8d75166d4a13c6",
                key: "rice-field-bamba-express-ways-from-hanoi",
                name: "Rice Field Bamba Express Ways (from Hanoi)",
                __v: 1,
                lemaxTourId: 34929,
                tourType: ["5c3cf50c15f5da4e8218fde1"],
                tour: {
                    _id: "5e125f88adba8b341ad323e3",
                    TourID: 34929,
                    TourTitle: "Rice Field Bamba Express Ways (from Hanoi)",
                    Type: 162,
                    TypeName: "FIT tour",
                    Galery: [
                        {
                            PhotoID: 27119,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Rice Field Bamba Express Ways (from Hanoi)/RiceFieldBambaExpressWaysfromHanoi1.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Rice Field Bamba Express Ways (from Hanoi)/RiceFieldBambaExpressWaysfromHanoi1.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 989,
                            MainThemeName: "Backpacking",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bd10f44d572272b57340a00",
                            name: "Vietnam",
                            lemaxId: 580,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bd10f44d572272b573409c4",
                            name: "Cambodia",
                            lemaxId: 445,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                        {
                            _id: "5bca21ebb6dcd102d6a865ab",
                            name: "Thailand",
                            lemaxId: 559,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 3,
                    DurationDD: 21,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Travel-Passes",
                            shortName: "TRAVEL PASSES",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e125f88adba8b341ad323e4",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 897,
                        PriceFormatted: "897.00",
                        BillingTypeName: "",
                    },
                },
            },
            {
                _id: "5d152e4cb50ce06af324cfe7",
                key: "day-of-the-dead-experience-5d-4n",
                name: "Day of the Dead Experience 5D/4N",
                __v: 1,
                lemaxTourId: 34238,
                tourType: ["5c3cf4fe15f5da4e8218fde0"],
                tour: {
                    _id: "5e11996facae2d291c468f39",
                    TourID: 34238,
                    TourTitle: "Day of the Dead Experience 5D/4N (from Mexico City)",
                    Type: 122,
                    TypeName: "Simple tour",
                    Galery: [
                        {
                            PhotoID: 28357,
                            PhotoUrl:
                                "https://assets.bambatravel.com/content/home/Day of the Dead Experience 5D - 4N (from Mexico City)/DayoftheDeadExperience5D18.jpg",
                            ThumbnailUrl:
                                "https://assets.bambatravel.com/content/home/Day of the Dead Experience 5D - 4N (from Mexico City)/DayoftheDeadExperience5D18.jpg",
                            Position: 0,
                            ObjectID: 0,
                            UnitID: 0,
                            PackageTourID: 0,
                            TransportationID: 0,
                        },
                    ],
                    MainTheme: [
                        {
                            MainThemeID: 881,
                            MainThemeName: "Cultural",
                            MainThemeValue: "1",
                        },
                    ],
                    Countries: [
                        {
                            _id: "5bca21ebb6dcd102d6a865a5",
                            name: "Mexico",
                            lemaxId: 652,
                            __v: 1,
                            Permalink: "",
                            coin: "",
                            hashtag: "",
                            language: "",
                            seoDescription: "",
                            seoKeywords: "",
                            shortDescription: "",
                            topFlexible: [],
                            topGroup: [],
                            topIndependent: [],
                            tripInfo: "",
                            videoYoutube: "",
                            weather: "",
                            wordpressCategory: "",
                        },
                    ],
                    NumberOfCountries: 1,
                    DurationDD: 5,
                    DurationHR: null,
                    Style: [
                        {
                            Permalink: "Group-Adventures",
                            shortName: "GROUP",
                        },
                    ],
                    PriceInfo: {
                        _id: "5e11996facae2d291c468f3a",
                        Discount: null,
                        SpecialOffer: null,
                        PriceOriginal: null,
                        PriceOriginalFormatted: null,
                        Price: 419,
                        PriceFormatted: "419.00",
                        BillingTypeName: "",
                    },
                },
            },
        ],
    },
];
