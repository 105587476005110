import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-are-here-to-do-good',
  templateUrl: './we-are-here-to-do-good.component.html',
  styleUrls: ['./we-are-here-to-do-good.component.scss']
})
export class WeAreHereToDoGoodComponent implements OnInit {

  responsiveImageSrc: string = '';

  constructor() { }

  ngOnInit() {
    this.setResponsiveImage();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setResponsiveImage(); // Call the function when the window is resized
  }

  setResponsiveImage() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 991) {
      this.responsiveImageSrc = 'assets/img/home-page/bamba_for_good-SM-MD.jpg';
    } else if (screenWidth <= 1280) {
      this.responsiveImageSrc = 'assets/img/home-page/bamba_for_good-LG.jpg';
    } else {
      this.responsiveImageSrc = 'assets/img/home-page/bamba_for_good-XL-2XL.jpg';
    }
  }

}
