import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SistemaService} from './sistema/sistema.service';
import {Observable, of} from 'rxjs';
import {BookingRequest, Bookings, ITourType, IBuyer, Passenger, ReservationItemRequest} from '../models/bookings.interface';
import {IListReponseData} from '../models/common.interface';
import {objectToQueryParams} from '../core/utils';
import {BUYERS} from '../const';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private readonly bookingsEndpoint: string;
  private readonly passengersEndpoint: string;
  private readonly passengerUpdate: string;
  private readonly cancelReservationEndpoint: string;
  private readonly bookingSyncPassenger: string;
  private readonly tourTypesEndpoint: string;
  private readonly reservationStatus: string;
  private readonly branchOfficesEndpoint: string;
  private readonly voucherEndpoint: string;

  constructor(private http: HttpClient, sistemaService: SistemaService) {
    this.bookingsEndpoint = sistemaService.url + '/bookings/';
    this.passengersEndpoint = sistemaService.url + '/get-passenger-reservation';
    this.passengerUpdate = sistemaService.url + '/update-passengers-list';
    this.cancelReservationEndpoint = sistemaService.url + '/cancell-reservation-item/';

    this.bookingSyncPassenger = sistemaService.url + '/sync-bookings-by-id';
    this.tourTypesEndpoint = sistemaService.url + '/tour-type/';
    this.reservationStatus = sistemaService.url + '/reservation-status';
    this.branchOfficesEndpoint = sistemaService.url + '/branch-office';
    this.voucherEndpoint = sistemaService.url + '/voucher/';
  }

  detail(id: string): Observable<Bookings> {
    return this.http.get<Bookings>(this.bookingsEndpoint + id);
  }

  list(bookingRequest: BookingRequest): Observable<IListReponseData<Bookings>> {
    const queryParams = '?' + objectToQueryParams(bookingRequest);
    return this.http.get<IListReponseData<Bookings>>(this.bookingsEndpoint + queryParams);
  }

  getPassengers(reservationId: string, language = 'en'): Observable<Passenger[]> {
    const request = {ReservationUniqueID: reservationId, LanguageID: language};
    return this.http.request<Passenger[]>('post', this.passengersEndpoint, {body: request});
  }

  updatePassenger(data: any): Observable<any> {
    return this.http.put(this.passengerUpdate, data);
  }

  syncPassenger(data: { ReservationID: number, LanguageID: string, Remplace: boolean }): Observable<any> {
    return this.http.post(this.bookingSyncPassenger, data);
  }

  cancelReservation(reservation: ReservationItemRequest): Observable<any> {
    return this.http.put(this.cancelReservationEndpoint, reservation);
  }

  getBuyers(): Observable<IBuyer[]> {
    return of(BUYERS);
  }

  getTourTypes(): Observable<ITourType[]> {
    return this.http.get<ITourType[]>(this.tourTypesEndpoint);
  }

  getReservationStatus(): Observable<ITourType[]> {
    return this.http.get<ITourType[]>(this.reservationStatus);
  }

  getBranchOffices(): Observable<any> {
    return this.http.get(this.branchOfficesEndpoint);
  }

  getVoucher(reservationId: number): Observable<any> {
    const options = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.voucherEndpoint+reservationId, options);
  }


}
