import {Pipe, PipeTransform} from '@angular/core';

const SEARCH_TITLES = {
  'regions': 'Regions',
  'region': 'Regions',
  'countries': 'Countries',
  'themes': 'Themes',
  'products': 'Trips',
  'destinations': 'Destinos'
};

@Pipe({
  name: 'correctSearchTitles'
})
export class CorrectSearchTitlesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return SEARCH_TITLES[value];
  }

}
