import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SistemaService} from './sistema/sistema.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ICredentials, ITokenResponse, IUser, IUserResponse} from '../models/auth.interface';
import {tap} from 'rxjs/operators';
import {KEY_TOKEN_STORAGE, KEY_USER_STORAGE} from '../const';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private readonly accountLoginUrl: string;
  private readonly accountByTokenUrl: string;

  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(AuthenticationService.isAuthenticated());

  constructor(private http: HttpClient,
              private sistema: SistemaService,
              private router: Router) {
    this.accountLoginUrl = this.sistema.url + '/accounts-login';
    this.accountByTokenUrl = this.sistema.url + '/accounts-by-token';
  }

  public static isAuthenticated(): boolean {
    return !!localStorage.getItem(KEY_USER_STORAGE);
  }

  public static token(): string {
    return localStorage.getItem(KEY_TOKEN_STORAGE);
  }

  public static getUser(): IUser {
    return JSON.parse(localStorage.getItem(KEY_USER_STORAGE));
  }

  getIsAuthenticated(): BehaviorSubject<boolean> {
    return this.isAuthenticatedSubject;
  }

  getToken(credentials: ICredentials): Observable<ITokenResponse> {
    return this.http.post<ITokenResponse>(this.accountLoginUrl, credentials)
      .pipe(tap((tokenResponse) => {
        localStorage.setItem(KEY_TOKEN_STORAGE, tokenResponse.Token);
      }));
  }

  getAccountByToken(): Observable<IUserResponse> {
    return this.http.get<IUserResponse>(this.accountByTokenUrl)
      .pipe(tap((userResponse: IUserResponse) => {
        localStorage.setItem(KEY_USER_STORAGE, JSON.stringify(userResponse.Account));
        this.isAuthenticatedSubject.next(true);
      }));
  }

  logout(): void {
    localStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['']);
  }
}
