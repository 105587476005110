<!-- Footer -->
<footer *ngIf="!hideFooter" id="footer" class="page-footer font-small stylish-color-dark footer-padding">
  <!-- Footer Links -->
  <div class="text-left">
    <!-- Grid row -->
    <div class="">
      <!-- Grid column -->
      <div class="mx-auto justify-content-center footer-gap" style="display: flex;">
        <!-- Content -->
        <h5 class="" style="margin-bottom: 0">
          <img [src]="'assets/img/home-page/vector.svg'"
               alt="Bamba Logo"
               width="175" />
        </h5>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="mx-auto col-about small-gap main-point-column" *ngIf="!isMediumScreen">
          <ul class="list-unstyled column-gap row">
            <li *ngFor="let link of newFooterResource.slice(0, 5)">
                <a class="text-subtitle" [href]="link.url">{{ link.text }}</a>
            </li>
          </ul>
          <ul class="list-unstyled column-gap row">
            <li *ngFor="let link of newFooterResource.slice(5)">
                <a class="text-subtitle" [href]="link.url">{{ link.text }}</a>
            </li>
          </ul>
      </div>

      <!-- md screen -->
      <div class="mx-auto col-about small-gap main-point-column" *ngIf="isMediumScreen">
        <ul class="list-unstyled column-gap row">
          <li *ngFor="let link of newFooterResource.slice(0, 3)">
            <a class="text-subtitle" [href]="link.url">{{ link.text }}</a>
          </li>
        </ul>
        <ul class="list-unstyled column-gap row">
          <li *ngFor="let link of newFooterResource.slice(3, 6)">
            <a class="text-subtitle" [href]="link.url">{{ link.text }}</a>
          </li>
        </ul>
        <ul class="list-unstyled column-gap row">
          <li *ngFor="let link of newFooterResource.slice(6, 9)">
            <a class="text-subtitle" [href]="link.url">{{ link.text }}</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
<!--      <div class="col-12 col-md-6 col-lg-3 col-xl-3 mx-auto col-about">-->
<!--        &lt;!&ndash; Links &ndash;&gt;-->
<!--        <h5 class="mb-4 text-title">RESOURCES</h5>-->
<!--        <ul class="list-unstyled">-->
<!--          <li *ngFor="let r of footerResource">-->
<!--            <a (click)="contentPage(r.permalink, r._id)" class="text-subtitle">-->
<!--              {{ r.shortName }}-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="text-subtitle"-->
<!--              href="https://www.dropbox.com/s/wdpgqxoq50hlzh4/The%20Bamba%20Manifesto%20Responsible%20Travel%20Policy.pdf?dl=0"-->
<!--              target="_blank">Responsible Travel Policy</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

<!--    <hr />-->
    <div class="social-icon-layout">
      <a href="https://www.instagram.com/bambatravel/" target="_blank">
        <img src="assets/icons/social/instagram.png" alt="insta">
      </a>
      <a href="https://www.facebook.com/bambaexperience" target="_blank">
        <img src="assets/icons/social/facebook.png" alt="fb">
      </a>
      <a href="https://www.youtube.com/@bambaExperiencetheworld" target="_blank">
        <img src="assets/icons/social/youtube.png" alt="youtube">
      </a>
      <a href="https://api.whatsapp.com/send/?phone=17185504750&text&type=phone_number&app_absent=0" target="_blank">
        <img src="assets/icons/social/whatsapp.png" alt="whatsapp">
      </a>
    </div>

    <!-- Copyright -->
    <div class="footer-copyright text-center">
      © 2023 Bamba · All Rights Reserved ·
      <a class="footer-copyright" routerLink="/privacy-policy/5c7ebb7fc23e0e17d1092ea4"><u>Privacy policy</u></a> ·
      <a class="footer-copyright" routerLink="/terms/5c78342ec23e0e17d1092e9f"><u>Terms</u></a> ·
      <a class="footer-copyright"
         href="https://www.dropbox.com/s/wdpgqxoq50hlzh4/The%20Bamba%20Manifesto%20Responsible%20Travel%20Policy.pdf?dl=0"
         target="_blank"><u>Responsible Travel Policy</u></a>
    </div>
  </div>
  <!-- Footer Links -->
</footer>
<!-- Footer -->
