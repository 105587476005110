<div class="fb-item">
  <div class="first-section-wrapper">
    <div class="gravatar">
      <ngx-skeleton-loader [theme]="{ width: '100%', height: '130px' }">
      </ngx-skeleton-loader>
    </div>
  </div>
  <div class="second-section-wrapper">
    <div class="wrapper">
      <ngx-skeleton-loader
        [theme]="{width: '100%','border-radius': '0',height: '15px','margin-bottom': '10px'}">
      </ngx-skeleton-loader>
    </div>
    <div class="wrapper">
      <ngx-skeleton-loader
        [theme]="{width: '100%','border-radius': '0',height: '15px','margin-bottom': '10px'}">
      </ngx-skeleton-loader>
    </div>
    <div class="wrapper">
      <ngx-skeleton-loader
        [theme]="{width: '100%','border-radius': '0',height: '15px','margin-bottom': '10px'}">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>
