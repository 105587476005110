import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dont-take-our-word',
  templateUrl: './dont-take-our-word.component.html',
  styleUrls: ['./dont-take-our-word.component.scss']
})
export class DontTakeOurWordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
