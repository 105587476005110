<form [ngClass]="logged ? 'col-md-12 ng-untouched display search-home ng-pristine ng-valid': 'display dp search-home'" id="formulario">
  <div class="content-width">
    <div class="b-padding" *ngIf="!logged">
      <p class="text-padding text-center">Your Independent Adventure Starts Here</p>
      <p class="headline-4">Travel your way on any budget</p>
    </div>
    <div class="search searchPadding" *ngIf="!logged"
         style="border-radius: 44px !important; width: 100%; justify-content: space-between;display: inline-flex; background: var(--surface-surface, #FFF);">
      <ng-template *ngTemplateOutlet="searchAutocompleteTemplate"></ng-template>

      <div class="search-icon">
        <button (click)="explore()">
          <i class="material-icons">search</i>
        </button>
      </div>

    </div>
  </div>
  <!--  darwin start-->
  <div *showItBootstrap="['md','lg','xl']"
       class="row row-search ocultar-smartphone d-md-block d-lg-block d-xl-block"
       id="searchFade">
    <div *ngIf="logged" class="d-inline-block position-absolute" [ngClass]="logged ? 'search logged-in' : ''">
      <ng-template *ngTemplateOutlet="searchAutocompleteTemplate"></ng-template>
      <hr class="hr-vertical">
      <ng-template *ngTemplateOutlet="howLongTemplate"></ng-template>
      <hr class="hr-vertical">
      <ng-template *ngTemplateOutlet="travelTypesTemplate"></ng-template>
    </div>
    <div *ngIf="logged" class="form-group d-inline-block" style="position:absolute;right: 0;">
      <input [ngClass]="{'btnBg': !logged}" class="seo-searchbar btn-primary:hover btn btn-primary" type="button" value="EXPLORE" (click)="explore()">
    </div>
  </div>
  <div *showItBootstrap="['xs','sm']"
       class="row smartphone d-block d-sm-block">
    <div class="container" *ngIf="logged">
      <div class="row w-100">
        <!--        <div class="search-smartphone w-100">-->
        <div class="w-100 col-10 pl-0 pr-0 mx-auto smSearch my-auto">
          <ng-template *ngTemplateOutlet="searchAutocompleteTemplate"></ng-template>
        </div>
        <div class="w-100 col-10 pl-0 pr-0 mx-auto smSearch my-1">
          <ng-template *ngTemplateOutlet="howLongTemplate"></ng-template>
        </div>
        <div class="w-100 col-10 pl-0 pr-0 mx-auto smSearch my-auto">
          <ng-template *ngTemplateOutlet="travelTypesTemplate"></ng-template>
        </div>
        <!--        </div>-->
        <div class="col-12 mt-3">
          <div class="form-group">
            <input class="seo-searchbar btn btn-primary btn-block w-50 mx-auto" type="submit" value="EXPLORE"
                   style="border: none; color: black; border-radius: 16px; background: var(--Secondary, linear-gradient(97deg, #18FFFF 0%, #1FAEFF 100%));"
                   (click)="explore()">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  darwin end-->
</form>

<ng-template #searchAutocompleteTemplate>
  <mat-form-field [formGroup]="browserForm"
                  appearance="fill"
                  [ngClass.xs]="logged?'search-parallax' : 'search-field'"
                  [ngClass.sm]="logged?'search-parallax' : 'search-field'"
                  [ngClass.md]="logged?'tam-auto-complite search-parallax' : 'auto-complite search-field'"
                  [ngClass.lg]="logged?'tam-auto-complite search-parallax' : 'auto-complite search-field'"
                  [ngClass.xl]="logged?'tam-auto-complite search-parallax' : 'auto-complite search-field'"
  [ngClass]="'mat-padding'">
    <input type="text" formControlName="browseText" matInput style="align-items: center !important; padding: 0"
           [matAutocomplete]="autoGroup"
           [placeholder]="placeholderText">
    <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayFn">
      <ng-container *ngFor="let r of (resultSearch ? Object.keys(resultSearch) : [])">
        <mat-optgroup *ngIf="resultSearch[r].length>0" [label]="r | correctSearchTitles">

          <mat-option (click)="type=r;goToProduct(name, r) || explore()" *ngFor="let name of resultSearch[r] | slice:0:5"
                      [value]="name"
                      [ngClass]="{'bold-option': name.TourTitle == 'VIEW ALL'}">

            {{ name.name || name.TourTitle || name.title}}
          </mat-option>
          <mat-option *ngIf="r == 'products'" (click)="$event.stopPropagation();type=r;goToProduct(null, r, true)"
                      [value]="null"
                      [ngClass]="{'bold-option':true }">
            {{ 'VIEW ALL' }}
          </mat-option>

        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<ng-template #travelTypesTemplate>
  <mat-form-field [formGroup]="browserForm"
                  [ngClass.xs]="'w-100 search-parallax'"
                  [ngClass.sm]="'w-100 search-parallax'"
                  [ngClass.md]="'tam-travel search-parallax'"
                  [ngClass.lg]="'tam-travel search-parallax'"
                  [ngClass.xl]="'tam-travel search-parallax'">
    <mat-select formControlName="travel" placeholder="Travel style">
      <ng-container *ngIf="onAgents" >
        <mat-option *ngFor="let ts of travelStyleAgents" [value]="ts.viewValue" class="border-option-select">
          {{ ts.viewValue }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="!onAgents">
        <mat-option *ngFor="let ts of travelStyle" [value]="ts.viewValue" class="border-option-select">
          {{ ts.viewValue }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #howLongTemplate>
  <mat-form-field [formGroup]="browserForm"
                  [ngClass.xs]="'w-100 search-parallax'"
                  [ngClass.sm]="'w-100 search-parallax'"
                  [ngClass.md]="'tam-howLong search-parallax'"
                  [ngClass.lg]="'tam-howLong search-parallax'"
                  [ngClass.xl]="'tam-howLong search-parallax'">
    <mat-select formControlName="howLong" placeholder="How long?">
      <mat-option *ngFor="let h of howLong" [value]="h.viewValue" class="border-option-select">
        {{ h.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
