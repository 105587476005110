import {NgModule} from '@angular/core';
import {BambaSharedModule} from '../../../../shared/bamba-shared.module';
import {MatSearchBarComponent} from './mat-search-bar.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ResponsiveModule} from 'ngx-responsive';
import {SkeletonsLoaderModule} from '../../../skeletons-loader/skeletons-loader.module';

@NgModule({
  declarations: [MatSearchBarComponent],
  imports: [
    BambaSharedModule,
    ReactiveFormsModule,
    ResponsiveModule,
    SkeletonsLoaderModule,
  ],
  exports: [MatSearchBarComponent]
})
export class SearchBarModule {
}
