import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import home from '../json/home';
import {IHome} from '../models/homev2.interface';
import travelstyles from '../json/travelstyles';
import {SistemaService} from './sistema/sistema.service';
import {map} from 'rxjs/operators';
import regions from '../json/regions';
import {IRegion} from '../models/region.interface';
import {ITravelStyles} from '../models/travel-styles.interface';
import {IThemeDetail} from '../models/theme.interface';
import {SearchAutocomplete} from '../models/search-result';
import {ISearchAutocomplete} from '../models/search-autocomplete.interface';

@Injectable({
  providedIn: 'root'
})
export class Homev2Service {
  url: string;

  constructor(private http: HttpClient, private sistemaService: SistemaService) {
    this.url = sistemaService.url;
  }

  getHome(): Observable<IHome> {
    return of(home[0]);
  }

  getTravelStyles(): Observable<ITravelStyles[]> {
    return of(travelstyles as ITravelStyles[]);
  }

  getThemes(): Observable<IThemeDetail[]> {
    return this.http.get<IThemeDetail[]>(`${this.url}/get-theme-menu/`);
  }

  getRegions(): Observable<IRegion[]> {
    return of(regions as IRegion[]);
  }

  searchAutocomplete(query: string): Observable<ISearchAutocomplete> {
    return this.http.get<ISearchAutocomplete>(`${this.url}/search-autocomplete/${query}`);
  }
}
