import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AgenciesService} from '../../../../services/agencies.service';
import {StoresService} from '../../../../services/stores.service';
import {BookingsService} from '../../../../services/bookings.service';
import {ITourType} from '../../../../models/bookings.interface';
import {SuperAgencyService} from '../../../../services/super-agency.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-input-filters',
  templateUrl: './input-filters.component.html',
  styleUrls: ['./input-filters.component.scss']
})
export class InputFiltersComponent implements OnInit {
  @Input() form: FormGroup;

  superAgencies;

  agencies;

  stores;

  tourTypes: ITourType[];

  reservationStatus: ITourType[];

  unsubscribe = new Subject();

  constructor(private agencyService: AgenciesService,
              private storeService: StoresService,
              private bookingService: BookingsService,
              private superAgencyService: SuperAgencyService) {
    this.getSuperAgencies();
    this.getTourTypes();
    this.getReservationStatus();
  }

  ngOnInit() {
    this.form.get('SuperAgency')
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.getAgencies(value);
      });
    this.form.get('Agency').valueChanges
      .subscribe(agency => {
        if (agency) {
          this.getStores(agency);
        } else {
          this.stores = [];
        }
      });
  }

  async getSuperAgencies(): Promise<void> {
    const superAgencies = await this.superAgencyService.list({PageSize: 1000, CurrentPage: 1}).toPromise();
    this.superAgencies = superAgencies.Results;
  }

  async getAgencies(superAgency: any): Promise<void> {
    const agenciesResponse = await this.agencyService.list({PageSize: 1000, CurrentPage: 1, SuperAgency: superAgency}).toPromise();
    this.agencies = agenciesResponse.Results;
  }

  async getTourTypes(): Promise<void> {
    this.tourTypes = await this.bookingService.getTourTypes().toPromise();
  }

  async getReservationStatus(): Promise<void> {
    this.reservationStatus = await this.bookingService.getReservationStatus().toPromise();
  }

  async getStores(agency: string): Promise<void> {
    const params = {PageSize: 1000, CurrentPage: 1};
    if (agency) {
      params['Agency'] = agency;
    }
    const storesResponse = await this.storeService
      .list(params).toPromise();
    this.stores = storesResponse.Results;
  }

}
