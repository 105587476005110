<ng-container *ngIf="_sistemaService.footerHeaderVisible">

  <div class="" [ngStyle]="{ height: headerCollapse ? '100vh' : '' }">
    <!--    <div class="row">-->

    <nav class="navbar navbar-expand-lg nav-padding" [ngClass]="isAuthenticated ? 'dar-nav' : '' ">
      <div class="des_cont">
        <li class="desktop destinationsTravel nav-item align-end d-none d-lg-block d-xl-block space">
                <span (click)="
                    visibleDestinations = !visibleDestinations;
                    destinationsSelectedTitle = '';
                    destinationsSelected = null;
                    visibleThemes = false
                  ">
                  Destinations
                </span>
        </li>
        <!-- Contact Us -->
        <li class="desktop align-end d-none d-lg-block d-xl-block">
          <a class="" href="#" id="navbarDropdownMenuContact" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false" [routerLink]="['/', 'contact-us']">
            <div class="d-flex flex-column bd-highlight">
              <p class="bd-highlight destinationsTravel">Contact Us</p>
            </div>
          </a>
        </li>

        <button class="navbar-toggler" (click)="headerCollapse = !headerCollapse; resetMenu()" type="button"
                data-toggle="collapse" data-target="#menuCollapse" aria-controls="menuCollapse" aria-expanded="false"
                aria-label="Toggle navigation" [ngClass]="{ 'visibility-elements': closeActive }">
          <img width="24" height="24" src="assets/icons/navigation/menu.svg" alt="" class="iconMenu" />
        </button>
      </div>

      <div class="logo_icon">
        <a class="mx-auto" [routerLink]="['']" (click)="closeMenu()">
          <ng-container *ngIf="boolLogo && !isAuthenticated">
            <div class="tapaIMG"></div>
            <label class="test" id="logoNoLogin">TEST BAMBA</label>
          </ng-container>
          <div class="tapaIMG2"></div>
          <!-- iconos cuando no estas logueado -->
          <div *ngIf="!boolLogo && !isAuthenticated" id="logoNoLoginBamba">
            <img *ngIf="!boolLogo && !isAuthenticated" class="logoBambaNoLogin imgStyle d-none d-md-block"
                 [ngClass]="{ 'visibility-elements': closeActive }" src="assets/img/product/vector.svg" alt="bamba"
            />

            <img *ngIf="!boolLogo && !isAuthenticated" class="iconoBambaNoLogin d-block d-md-none"
                 [ngClass]="{ 'visibility-elements': closeActive }" src="assets/img/product/vector.svg" alt="bamba" width="104" height="28" />
          </div>

          <!-- iconos cuando no estas logueado -->

          <!-- iconos cuando esta logueado -->

          <div *ngIf="isAuthenticated" style="display: flex; align-items: center; gap: 8px">
            <img class="d-none d-md-block" src="assets/img/product/darwin_logo.svg" width="40" alt="Darwin bamba Admin" />
            <img class="d-none d-md-block" src="assets/img/product/darwin.svg" width="94" alt="Darwin bamba Admin" />
          </div>

          <img *ngIf="isAuthenticated" class="logoDarwin2 d-block d-md-none" src="assets/img/logoDarwinRespon.svg"
               width="70" alt=" Darwin bamba Admin" />
          <!-- fin de iconos cuando esta logueado -->

          <!-- <img *ngIf="isAuthenticated" class="logoDarwin" src="assets/img/LogoDarwin.svg" width="200"
            alt=" Darwin bamba Admin"> -->
          <svg *ngIf="!boolLogo && !isAuthenticated" [ngStyle]="{ visibility: !closeActive ? 'visible' : 'hidden' }"
               class="logo-headerNo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 792.31 216">
            <defs>
              <style>
                .a {
                  fill: #75787b;
                }

                .b {
                  fill: #2fc1d1;
                }
              </style>
            </defs>

            <title *ngIf="!isAuthenticated">logo1_web_1</title>
            <title *ngIf="isAuthenticated">LogoDarwin</title>
            <path class="a" d="M267.37,165.46H255.53v8.21H267v2.9H255.53V187.3h11.84v2.9H252.31V162.56h15.06Z" />
            <path class="a"
                  d="M283.81,175.9,276,162.56h3.65l6,10.4,6.17-10.4h3.66l-8,13.34,8.39,14.3h-3.65l-6.66-11.36-6.74,11.36h-3.65Z" />
            <path class="a"
                  d="M310.64,162.56c2.92,0,5.35.33,7.31,1.95a7.51,7.51,0,0,1,2.65,5.92c0,3.9-2.56,7.75-9.17,7.75H308.6v12h-3.22V162.56Zm-2,12.72h2.65c3.83,0,6.14-1.82,6.14-5,0-4.39-4.31-4.81-6.4-4.81H308.6Z" />
            <path class="a" d="M345.35,165.46H333.52v8.21H345v2.9H333.52V187.3h11.83v2.9H330.3V162.56h15.05Z" />
            <path class="a"
                  d="M361,162.56c2,0,4.87.13,7.05,1.53a7.15,7.15,0,0,1,3.17,6.14,7.41,7.41,0,0,1-7.31,7.62l9.23,12.35h-3.92l-8.83-12.1h-.83v12.1h-3.21V162.56Zm-1.44,12.81h2.05c4,0,6.39-1.83,6.39-5.06a4.24,4.24,0,0,0-2.3-4.06,8.6,8.6,0,0,0-4.18-.79h-2Z" />
            <path class="a" d="M386.5,162.56V190.2h-3.22V162.56Z" />
            <path class="a" d="M414.12,165.46H402.29v8.21h11.48v2.9H402.29V187.3h11.83v2.9H399.07V162.56h15.05Z" />
            <path class="a" d="M425.12,190.2V161.4L447.74,184V162.56H451v28.88l-22.62-22.58V190.2Z" />
            <path class="a"
                  d="M485.71,168.65a14.38,14.38,0,0,0-3.78-2.57,12.93,12.93,0,0,0-5-1.07,11.41,11.41,0,0,0-11.66,11.51c0,7.5,6.39,11.27,11.7,11.27a12.45,12.45,0,0,0,5.39-1.28,13,13,0,0,0,3.35-2.32V188a15.58,15.58,0,0,1-8.74,2.7c-8.61,0-15-6.22-15-14.22,0-8.24,6.48-14.37,15.18-14.37a14.12,14.12,0,0,1,8.57,2.73Z" />
            <path class="a" d="M511.77,165.46H499.94v8.21h11.48v2.9H499.94V187.3h11.83v2.9h-15V162.56h15Z" />
            <path class="a" d="M545.13,165.46V190.2h-3.22V165.46h-7v-2.9h17.31v2.9Z" />
            <path class="a" d="M564.83,173.79h14.88V162.56h3.22V190.2h-3.22V176.69H564.83V190.2h-3.22V162.56h3.22Z" />
            <path class="a" d="M610.54,165.46H598.71v8.21H610.2v2.9H598.71V187.3h11.83v2.9h-15V162.56h15Z" />
            <path class="a"
                  d="M636.94,162.56l7.66,20.72,9.09-22.13,8.62,22.13,8.48-20.72h3.57l-12.19,29.09-8.52-22.13-9.14,22.13-11.13-29.09Z" />
            <path class="a"
                  d="M682,176.4c0-8.25,6.74-14.29,15.1-14.29s15.09,6,15.09,14.29-6.7,14.3-15.09,14.3S682,184.6,682,176.4Zm3.31,0A11.8,11.8,0,1,0,697.06,165,11.55,11.55,0,0,0,685.27,176.4Z" />
            <path class="a"
                  d="M727.81,162.56c2,0,4.87.13,7,1.53a7.13,7.13,0,0,1,3.18,6.14,7.41,7.41,0,0,1-7.31,7.62l9.22,12.35H736l-8.83-12.1h-.83v12.1h-3.22V162.56Zm-1.44,12.81h2c4,0,6.39-1.83,6.39-5.06a4.26,4.26,0,0,0-2.3-4.06,8.6,8.6,0,0,0-4.18-.79h-2Z" />
            <path class="a" d="M753.3,162.56V187.3h8v2.9H750.08V162.56Z" />
            <path class="a"
                  d="M770.74,162.56h5.91c2.92,0,7.05.29,10.7,3.23a13.22,13.22,0,0,1,5,10.61A13,13,0,0,1,787.22,187c-2.65,2.15-5.43,3.19-10.61,3.19h-5.87ZM774,187.3h2.82a12.82,12.82,0,0,0,8.31-2.49A10.36,10.36,0,0,0,789,176.4c0-6.63-4.87-10.94-12.23-10.94H774Z" />
            <path class="b"
                  d="M298.87,49.6A46.58,46.58,0,0,0,266,63.12V32.3a6.93,6.93,0,1,0-13.85,0V97.48h0A46.73,46.73,0,1,0,298.87,49.6Zm0,79.62a32.89,32.89,0,1,1,32.89-32.91A32.89,32.89,0,0,1,298.87,129.22Z" />
            <path class="b"
                  d="M645.16,49.6a46.58,46.58,0,0,0-32.88,13.52V32.3a6.93,6.93,0,1,0-13.85,0V97.48h0A46.73,46.73,0,1,0,645.16,49.6Zm0,79.62a32.89,32.89,0,1,1,32.89-32.91A32.89,32.89,0,0,1,645.16,129.22Z" />
            <path class="b"
                  d="M792.14,96.16a46.74,46.74,0,1,0-13.85,33.37v6.61a6.92,6.92,0,0,0,6.93,6.92h0a6.92,6.92,0,0,0,6.92-6.92v-40Zm-46.73,33.06a32.89,32.89,0,1,1,32.88-32.91A32.9,32.9,0,0,1,745.41,129.22Z" />
            <path class="b"
                  d="M446,96.16a46.72,46.72,0,1,0-13.84,33.37v6.61a6.92,6.92,0,0,0,6.92,6.92h0a6.92,6.92,0,0,0,6.93-6.92v-40Zm-46.72,33.06a32.89,32.89,0,1,1,32.88-32.91A32.89,32.89,0,0,1,399.26,129.22Z" />
            <path class="b"
                  d="M584.58,84.1a34.62,34.62,0,0,0-62.3-20.67A34.63,34.63,0,0,0,460,84.1h0v52a6.92,6.92,0,0,0,6.92,6.92h0a6.91,6.91,0,0,0,6.92-6.92V84.55h0c0-.12,0-.23,0-.34a20.77,20.77,0,0,1,41.54,0c0,.11,0,.22,0,.34h0v51.59a6.93,6.93,0,0,0,13.85,0V84.55h0c0-.12,0-.23,0-.34a20.77,20.77,0,0,1,41.54,0c0,.11,0,.22,0,.34h0v51.59a6.92,6.92,0,0,0,13.84,0v-52Z" />
            <path class="b"
                  d="M90.39,106.5h0l-.07.08c1.92,1.72,3.81,3.58,5.65,5.56q2,2.12,3.73,4.28c.21.25.42.51.62.77q1.6,2,3,4.08l.07-.05q1.68-1.21,3.37-2.67a53,53,0,0,0,16.47-28.16A62.4,62.4,0,0,0,93,104.06C92.09,104.85,91.22,105.67,90.39,106.5Z" />
            <path class="b"
                  d="M85.8,112c-4,5.78-5.36,11.34-2.88,14.06s8.48,2.13,15.4-1.59h0l.82-.46c-.71-1-1.46-2-2.26-2.92h0q-1.53-1.95-3.3-3.83c-.51-.54-1-1.06-1.56-1.57l-.59-.59c-.41-.41-.82-.8-1.23-1.18q-1.78-1.58-3.64-2.94c-.25.34-.51.69-.75,1Z" />
            <path class="b"
                  d="M108,0A108,108,0,1,0,216,108,108,108,0,0,0,108,0Zm26.93,179.48c-17.85,6.58-34.45,7.82-48.55,5.89h-.13l-.3-.05-1-.15c-.55-.08-1.1-.17-1.64-.27l-.13,0-.17,0h0l-.66-.14c-.92-.18-1.84-.36-2.73-.57L79,184c-.92-.22-1.83-.45-2.71-.7l-.41-.13q-1.38-.39-2.7-.84l-.3-.11c-.91-.31-1.8-.63-2.66-1l-.15-.06c-.91-.37-1.8-.74-2.66-1.13h0c-19-8.66-27.41-24.65-31.1-36.78,0-.05,0-.1,0-.14q-.24-.79-.45-1.56s0-.1-.05-.15c-.13-.49-.26-1-.38-1.44a1.6,1.6,0,0,0-.05-.21c-.12-.48-.23-1-.34-1.43a.74.74,0,0,0,0-.14c-.1-.48-.2-.93-.29-1.38l0-.13c-.09-.45-.18-.89-.26-1.33a.24.24,0,0,0,0-.08c-.08-.46-.15-.89-.22-1.32h0v-.07c-.21-1.82-.35-3.67-.44-5.54,0-.15,0-.3,0-.45,0-.77-.06-1.54-.08-2.31,0-.23,0-.46,0-.69,0-.7,0-1.41,0-2.11,0-.26,0-.53,0-.79,0-.68,0-1.36.06-2l0-.81c0-.7.08-1.39.12-2.09,0-.25,0-.51,0-.76.06-.78.13-1.55.2-2.33,0-.17,0-.34,0-.5,5-49.23,44.07-101.16,80.7-77.9,8.5,5.4,15.36,20.43,15.62,37.91h0a69.66,69.66,0,0,1-1.71,16.39c-.31,1.35-.67,2.7-1.07,4a66.08,66.08,0,0,1-18.22,29.4c-.92.87-1.9,1.71-2.92,2.51h0l.07.11c12.28,19.65,12.92,38,2,51.48h0l1.1-.23a60.76,60.76,0,0,0,15.85-5.5c34-16.56,46.51-52.06,32.81-70.62-5.45-7.38-14.33-10.86-24.18-11.3-.06-.82-.71-12,6-16.72,14.71-.15,27.77,5.53,36.55,18.36C190.58,111.76,180.69,162.59,134.93,179.48Z" />
            <path class="b"
                  d="M100.94,129.54c-10.77,6.37-23.86,8.84-29.62,2.54-5.43-5.94-4.24-16.31,4-26.57h0c.15-.18.31-.36.46-.55a32.78,32.78,0,0,0-3.55-1.11l-.1,0-.13,0a34.85,34.85,0,0,0-5.57-1h0l-.75-.06-.3,0c-.93-.06-1.85-.08-2.75,0H62.6c-.42,0-.84,0-1.26.07l-.28,0-1,.11-.33.05c-.33,0-.65.09-1,.15l-.33.06-1,.22-.25.06c-.4.1-.79.21-1.18.33h0c-.37.12-.74.24-1.1.38l-.22.08-1,.41c-.49.23-1,.47-1.44.74a16.63,16.63,0,0,0-5.23,4.78c-3.23,4.16-4.72,9.91-4.36,16.21.11,1.26.24,2.5.4,3.72,0,.24.08.49.11.73.13.93.28,1.84.44,2.74.05.33.12.65.18,1,.17.86.35,1.72.54,2.56l.18.78q.41,1.65.87,3.24h0l0,.1a1.42,1.42,0,0,1,.07.22c.2.64.4,1.27.62,1.9.09.29.2.57.3.86.16.45.33.9.5,1.35.11.29.23.58.34.86.2.49.41,1,.62,1.45.09.22.18.43.28.65.29.66.61,1.3.93,1.94.11.23.23.45.35.67.23.44.46.87.7,1.3l.44.77c.22.38.45.76.69,1.13s.32.53.49.79h0c.31.51.64,1,1,1.5.1.14.19.3.29.45.34.49.7,1,1.05,1.46l.28.39c.37.5.76,1,1.15,1.45a3.92,3.92,0,0,0,.25.32c.47.58,1,1.14,1.46,1.69a35.41,35.41,0,0,0,6.77,5.57v0l1.45.91.38.22c.37.22.74.44,1.12.64l.37.21c.4.22.81.43,1.21.63l.26.13c.49.25,1,.49,1.48.71,11.92,5.42,24.24,4.72,31.81-3a21.57,21.57,0,0,0,3.42-4.6h0c5.18-9.34,3.88-22.72-3.1-34.89C101.82,129,101.38,129.3,100.94,129.54Z" />
            <path class="b"
                  d="M45.56,93.32c11.81-3.89,24.24-1.17,35.59,6.08h0l1-.93C95.42,87.06,109.93,79,123.85,75.33a31.55,31.55,0,0,0-10.69-19.52C101,45.31,81.8,44.89,61.29,65.34A62.12,62.12,0,0,0,47.35,87.87c-.68,1.76-1.3,3.6-1.87,5.49Z" />
          </svg>
        </a>
      </div>

      <!-- small screen -->
      <div style="display: inline-flex" class="icon-gap d-lg-none end">
        <li class="nav-item align-end d-block d-lg-none searchFloat d-xl-none mat-search-bar-component">
          <mat-search-bar></mat-search-bar>
        </li>

        <!-- user -->
        <li class="nav-item d-block d-lg-none d-xl-none dropdown"
            [ngClass]="{ 'visibility-elements': closeActive }">
          <a class="nav-link collapse-menu mtop" href="#" id=""
             role="button"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closeMenu()">
            <img class="user" src="assets/img/action/account_circle.svg" alt="login"/>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLinkUser">
            <!-- <a class="dropdown-item" href="#">
              SINGUP
              <mat-divider></mat-divider>
            </a>
            <a class="dropdown-item" href="#">
              LOGIN
              <mat-divider></mat-divider>
            </a>
            <a class="dropdown-item" href="#">
              MANAGE MY TRIP
              <mat-divider></mat-divider>
            </a> -->
            <a class="dropdown-item" href="/login"> Agent Log-in </a>
          </div>
        </li>

      </div>

      <div class="collapse navbar-collapse end" id="menuCollapse">
        <ul class="navbar-nav ml-auto icon-gap">
          <li class="nav-item align-end d-none d-lg-block d-xl-block mat-search-bar-component">
            <mat-search-bar></mat-search-bar>
          </li>

          <!-- DESTINATIONS-->
          <li class="smart nav-item align-self-start d-lg-none d-xl-none"
              [ngClass]="{ 'active-nav': visibleDestinations }" [ngStyle]="{
                  display: visibleSmartDestinations ? 'inline-block' : 'none'
                }">
            <ng-container *ngIf="!visibleDestinations">
                  <span class="nav-link border-right destinationsTravel" (click)="
                      next('d');
                      destinationsSelectedTitle = '';
                      destinationsSelected = null;
                      visibleThemes = false;
                      visibleSmartThemes = false;
                      principal[0] = true
                    " [ngStyle]="{
                      display: visibleSmartDestinations ? 'inline-block' : 'none'
                    }">
                    Destinations
                  </span>
              <mat-icon class="icon-menu" [ngStyle]="{
                      display: visibleSmartDestinations ? 'inline-block' : 'none'
                    }">
                chevron_right
              </mat-icon>
            </ng-container>
            <ng-container *ngIf="principal[0]">
              <mat-icon class="icon-menu">chevron_left</mat-icon>
              <span class="nav-link border-right destinationsTravel" (click)="resetMenu()" [ngStyle]="{
                      display: visibleSmartDestinations ? 'inline-block' : 'none'
                    }">
                    Destinations
                  </span>
            </ng-container>
            <ng-container *ngIf="visibleDestinations && !principal[0]">
              <mat-icon class="icon-menu">chevron_left</mat-icon>
              <span class="nav-link border-right destinationsTravel" (click)="
                      visibleDestinations = true;
                      destinationsSelectedTitle = '';
                      back('d');
                      principal[0] = true
                    " [ngStyle]="{
                      display: visibleSmartDestinations ? 'inline-block' : 'none'
                    }">
                    Destinations &nbsp;&nbsp;{{ destinationsSelectedTitle }}
                  </span>
            </ng-container>
          </li>
          <hr class="hr-menu d-block d-sm-block d-md-block d-lg-none d-xl-none" [ngStyle]="{
                  visibility: visibleSmartDestinations ? 'visible' : 'hidden'
                }" />
          <div class="menu d-none d-sm-none d-md-none d-lg-block d-xl-block" *ngIf="visibleDestinations">
            <ul class="col-12 pr-0" *ngIf="destinationsSelected == null">
              <div class="row">
                <ng-container *ngFor="let d of destinations">
                  <div class="col-2 col-menu menu-header">
                    <div class="title-header">
                      <a class="seo-title-destination title-destination" [routerLink]="[
                              '/',
                              'shop',
                              'All',
                              'Regions',
                              d.title | urlEspacios,
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              'Relevance-High-to-Low'
                            ]" (click)="setMenuHome(); visibleDestinations = false">
                        {{ d.title }}
                      </a>
                    </div>
                    <li *ngFor="let c of d.regionKeyCountry" class="menu-body">
                      <a class="seo-subitem-destination subtitle-country" [routerLink]="[
                              '/',
                              'shop',
                              'All',
                              'Countries',
                              c.name | urlEspacios,
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              'Relevance-High-to-Low'
                            ]" (click)="setMenuHome(); visibleDestinations = false">
                        {{ c.name }}
                      </a>
                    </li>
                    <mat-divider></mat-divider>
                    <ul class="menu-seeAll">
                      <li>
                            <span (click)="seeAllDestinations(d.id)">
                              See all
                            </span>
                      </li>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </ul>
            <!-- see all -->
            <ul class="d-flex bd-highlight" *ngIf="destinationsSelected != null">
              <li class="menu-header flex-fill bd-highlight">
                <div class="d-flex bd-highlight">
                      <span class="dropdown-header back p-2 flex-fill bd-highlight my-auto"
                            (click)="destinationsSelected = null">
                        <mat-icon>arrow_back_ios</mat-icon>
                      </span>
                  <div class="p-2 flex-fill bd-highlight">
                    <a class="dropdown-header" [routerLink]="[
                            '/',
                            'shop',
                            'All',
                            'Regions',
                            destinationsSelected.title | urlEspacios,
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            'Relevance-High-to-Low'
                          ]">
                      {{ destinationsSelected.title }}
                    </a>
                  </div>
                </div>
                <ul class="list-inline">
                  <li class="list-inline-item col-3 see-all" *ngFor="let c of destinationsSelected.allCountry">
                    <a class="seo-subitem-destination subtitle-country" (click)="setMenuHome()" [routerLink]="[
                            '/',
                            'shop',
                            'All',
                            'Countries',
                            c.name | urlEspacios,
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            'Relevance-High-to-Low'
                          ]">
                      {{ c.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="menu-smart d-block d-sm-block d-md-block d-lg-none d-xl-none" *ngIf="visibleDestinations">
            <ul class="ul-smart" *ngIf="destinationsSelected == null">
              <li class="li-smart" *ngFor="let d of destinations; let i = index">
                <ng-container *ngIf="
                        destinationSmart[i].visibleDestination &&
                        !destinationSmart[i].visibleCountrie
                      ">
                  <a (click)="
                          destinationSmart[i].visibleCountrie = !destinationSmart[
                            i
                          ].visibleCountrie;
                          destinationsHidden(i, d.title);
                          principal[0] = false
                        ">
                    {{ d.title }}
                    <mat-icon *ngIf="!destinationSmart[i].visibleCountrie" class="icon-menu">
                      chevron_right
                    </mat-icon>
                  </a>
                  <mat-divider></mat-divider>
                </ng-container>
                <ng-container *ngIf="destinationSmart[i].visibleCountrie">
                  <ul class="menu-body">
                    <li *ngFor="let c of d.allCountry">
                      <a class="seo-subitem-destination" [routerLink]="[
                              '/',
                              'shop',
                              'All',
                              'Countries',
                              c.name | urlEspacios,
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              '0',
                              'Relevance-High-to-Low'
                            ]" (click)="
                              setMenuHome(c.name);
                              visibleDestinations = false;
                              closeMenu();
                              destinationsHidden(i, d.title)
                            ">
                        {{ c.name }}
                      </a>
                    </li>
                    <mat-divider></mat-divider>
                  </ul>
                </ng-container>
              </li>
            </ul>
          </div>

          <!-- heart -->
          <!-- <li class="nav-item d-none d-sm-none d-md-none d-lg-block d-xl-block align-self-end">
            <a class="nav-link">
              <svg style="width:34px;height:34px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.13 42.03">
                <defs><style>.a{fill:#75787B;}.b{fill:#fe6064;}</style></defs>
                <title>favIcon_headerMenu</title>
                <path class="a" d="M24.48,42,5.35,26.4A14.27,14.27,0,0,1,.7,19.68C-2.82,8.57,7.52-4.24,19,1.36c.27.13.53.26.8.41l4.69,2.62,4.69-2.62c.26-.15.52-.28.79-.41,11.47-5.6,21.81,7.21,18.3,18.32A14.35,14.35,0,0,1,43.6,26.4Z"/>
                <path class="b" d="M30.55,31.74A10.29,10.29,0,1,0,40.84,21.46,10.29,10.29,0,0,0,30.55,31.74"/>
              </svg>
            </a>
          </li> -->
          <!-- user -->
          <li class="nav-item d-none d-sm-none d-md-none d-lg-block d-xl-block align-self-end dropdown">
            <a class="nav-link" href="#" id="navbarDropdownMenuLinkUser" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <img class="bamba-avatar" src="assets/img/action/account_circle.svg" *ngIf="isAuthenticated; else notLogged" alt="" />
              <!--                <span *ngIf="isAuthenticated">Hello, {{ user?.name }}</span>-->
              <ng-template #notLogged>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <g clip-path="url(#clip0_1574_232)">
                    <path d="M16.0001 2.66666C8.64008 2.66666 2.66675 8.63999 2.66675 16C2.66675 23.36 8.64008 29.3333 16.0001 29.3333C23.3601 29.3333 29.3334 23.36 29.3334 16C29.3334 8.63999 23.3601 2.66666 16.0001 2.66666ZM16.0001 6.66666C18.2134 6.66666 20.0001 8.45332 20.0001 10.6667C20.0001 12.88 18.2134 14.6667 16.0001 14.6667C13.7867 14.6667 12.0001 12.88 12.0001 10.6667C12.0001 8.45332 13.7867 6.66666 16.0001 6.66666ZM16.0001 25.6C12.6667 25.6 9.72008 23.8933 8.00008 21.3067C8.04008 18.6533 13.3334 17.2 16.0001 17.2C18.6534 17.2 23.9601 18.6533 24.0001 21.3067C22.2801 23.8933 19.3334 25.6 16.0001 25.6Z" fill="black" fill-opacity="0.6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1574_232">
                      <rect width="32" height="32" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </ng-template>
            </a>
            <div class="dropdown-menu drop-right" aria-labelledby="navbarDropdownMenuLinkUser">
              <a *ngIf="!isAuthenticated; else tmpLogout" class="dropdown-item" href="/login">
                Agent Log-in
              </a>
              <ng-template #tmpLogout>
                <a class="dropdown-item info-user-dropdown">
                  <span>{{ user?.email }}</span>
                  <span>{{ user?.accountType?.name }}</span>
                </a>
                <mat-divider></mat-divider>
                <a class="dropdown-item" [routerLink]="['darwin', 'booking']">
                  Bookings
                </a>
                <a class="dropdown-item" [routerLink]="['darwin']">
                  Settings
                </a>
                <mat-divider></mat-divider>
                <a class="dropdown-item" (click)="logout()"> Log-out </a>
              </ng-template>
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <div class="search-ul d-block d-lg-none d-xl-none" *ngIf="respuestasBool">
      <ul *ngFor="let r of resultSearch">
        {{
        r.letter
        }}
        <li *ngFor="let name of r.names" [ngStyle]="{
                'font-weight': name.name == 'VIEW ALL' ? 'bold' : '300'
              }" (click)="
                letterSelected = r.letter;
                respuestasBool = false;
                setValueText(name.name)
              ">
          {{ name.name }}
        </li>
      </ul>
    </div>
    <!--    </div>-->
    <hr class="hr-menu marzin d-block d-sm-block d-md-block d-lg-none d-xl-none"/>
  </div>
</ng-container>

