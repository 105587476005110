import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerOverlayComponent} from './progress-spinner-overlay.component';
import {MatProgressBarModule, MatProgressSpinnerModule} from '@angular/material';
import {ProgressSpinnerOverlayService} from './progress-spinner-overlay.service';

@NgModule({
  declarations: [ProgressSpinnerOverlayComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  exports: [ProgressSpinnerOverlayComponent],
  providers: [ProgressSpinnerOverlayService],
  entryComponents: [ProgressSpinnerOverlayComponent]
})
export class ProgressSpinnerOverlayModule {
}
