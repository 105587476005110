// tslint:disable-next-line:max-line-length
export default [
  {
    _id: "5bbe181262cde6490bcc38e5",
    lemaxId: 0,
    title: "ALL",
    seoDescription: "",
    seoKeywords: "",
    shortDescription: "",
    shortName: "ALL",
    Permalink: "ALL",
    staticContentId: "",
    s1buttonName: "",
    s1buttonUrl: "",
    s1slogan: "",
    s2text: "",
    s2title: "",
    s3text: "",
    s4title: "",
    s5description: "",
    s5title: "",
    s6BNote: "",
    s6BTitle: "",
    s6buttonName: "",
    s6buttonUrl: "",
    s6description: "",
    s6title: "",
    s7title: "",
    productDetailIcons: [],
    s7recommendedForYou: [],
    s6BElements: [],
    s5themeGrid: [],
    s4elements: [],
    s2icons: [],
    socialCover: {
      public_id: "tfzk8ufn0tgnkjpkezlq",
      version: 1564794785,
      signature: "fcc2bce63de3e87e72ceaaf1943b2dbcb9bb28e5",
      width: 1920,
      height: 1080,
      format: "jpg",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1564794785/tfzk8ufn0tgnkjpkezlq.jpg",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1564794785/tfzk8ufn0tgnkjpkezlq.jpg",
    },
    coverPhoto: {
      public_id: "u8z052xr9ot7hwstkaw2",
      version: 1539184681,
      signature: "57bfd0d061fd10203e15763ef606e8707edee9a2",
      width: 592,
      height: 440,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1539184681/u8z052xr9ot7hwstkaw2.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1539184681/u8z052xr9ot7hwstkaw2.png",
    },
  },
  {
    _id: "5bbe54f012b2fe13949894ec",
    title: "Travel Passes",
    lemaxId: 1,
    seoDescription: "Flexible Travel Passes Description\r\n",
    seoKeywords: "Flexible Travel Passes Keywords ",
    shortDescription:
      "include all your transport and experiences to navigate on your own with the full flexibility to create your own itinerary on the go.\r\n",
    shortName: "TRAVEL PASSES",
    Permalink: "Travel-Passes",
    staticContentId: "",
    s1buttonName: "All Travel Passes",
    s1buttonUrl: "Travel-Passes",
    s1slogan:
      "Just choose the route you want to follow, and then make your way along it as you please.",
    s2text:
      "<p>Include all of your <strong>transport</strong> and <strong>experiences</strong> while giving you <strong>full flexibility</strong> to change the dates and pick your accommodation on the go.</p>",
    s2title: "",
    s3text:
      "<p>Travellers looking for a flexible way to travel, with no fixed schedules, can choose from our <strong>Hop-On Hop-Off</strong> options all around the world.</p>\r\n<p>With the Bamba Flexible Travel Passes, you&rsquo;ll have the freedom to <strong>travel at your own pace</strong>, plan your own experiences and choose your own accommodation.</p>\r\n<p>All you have to do is decide on <strong>the route that suits you best</strong>, depending on where you want to go and how much time you want to travel!</p>",
    s4title: "Travel Passes Pros!",
    s5description: "",
    s5title: "",
    s6BTitle: "How Travel Passes Work:",
    s6buttonName: "",
    s6buttonUrl: "",
    s6description: "",
    s6title: "",
    s6BNote:
      "*Some changes are subject to availability and must be made between 24 and 48 hours before the transfer/activity.",
    s7title: "Top Travel Passes",
    productDetailIcons: [
      {
        _id: "5c5c810577ef8c4ba5eea058",
        key: "hop-on-hop-off",
        description: "Flexibility to travel at your own pace",
        __v: 0,
        image: {
          public_id: "xgiz1nnswx54wo8dznbo",
          version: 1549652599,
          signature: "2bc27d6cd141e12015beecae54b9026767858d48",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652599/xgiz1nnswx54wo8dznbo.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652599/xgiz1nnswx54wo8dznbo.svg",
        },
        name: "Hop-on Hop-off ",
      },
      {
        _id: "5c5dd2af77ef8c4ba5eea059",
        key: "bamba-app",
        description: "Request your services easily on the go",
        __v: 0,
        image: {
          public_id: "j7jsnedo9pm7vpcxop0a",
          version: 1549652689,
          signature: "98314c07095a1c52d60580755a0a887741d71643",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652689/j7jsnedo9pm7vpcxop0a.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652689/j7jsnedo9pm7vpcxop0a.svg",
        },
        name: "Bamba App",
      },
    ],
    s7recommendedForYou: [
      {
        _id: "5db3f5c8b26d98556777bbfd",
        TourID: 12781,
        TourTitle: "Quick Papa Ways (from Lima)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 1240,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/12781/facebook_share_1200x630.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/12781/facebook_share_1200x630.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 983,
            MainThemeName: "Nature & Adventure",
            MainThemeValue: "1",
          },
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a7",
            name: "Peru",
            lemaxId: 212,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 14,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3f5c8b26d98556777bbfe",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 527,
          PriceFormatted: "527.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5dbb70ecd1c9216b339af597",
        TourID: 16214,
        TourTitle: "Buddha Bamba Ways (from Bangkok / Clockwise)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 21169,
            PhotoUrl:
              "https://bambatest.itravelsoftware.com/fotografije_itravel/16214/expressawfullyfastmildlyoverweightdeitythatshellacooltbh-1.jpg",
            ThumbnailUrl:
              "https://bambatest.itravelsoftware.com/fotografije_itravel/16214/expressawfullyfastmildlyoverweightdeitythatshellacooltbh-1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409e3",
            name: "Laos",
            lemaxId: 508,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b57340a00",
            name: "Vietnam",
            lemaxId: 580,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409c4",
            name: "Cambodia",
            lemaxId: 445,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 5,
        DurationDD: 37,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5dbb70ecd1c9216b339af598",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1744,
          PriceFormatted: "1,744.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3f656b26d98556777bc01",
        TourID: 16627,
        TourTitle: "Mai Pen Rai Ways (from Hanoi)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 29565,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/16627/MaiPenRaiWaysFromHanoi1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/16627/MaiPenRaiWaysFromHanoi1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b57340a00",
            name: "Vietnam",
            lemaxId: 580,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409c4",
            name: "Cambodia",
            lemaxId: 445,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409e4",
            name: "Malaysia",
            lemaxId: 43,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409f7",
            name: "Singapore",
            lemaxId: 40,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 5,
        DurationDD: 43,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3f656b26d98556777bc02",
          Discount: {
            Amount: "1864",
            Percent: "20",
            Discount: 372.8,
            Total: 1491.2,
          },
          SpecialOffer: null,
          PriceOriginal: 1864,
          PriceOriginalFormatted: "1864.00",
          Price: 1491.2,
          PriceFormatted: "1491.20",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3f6d8b26d98556777bc05",
        TourID: 17534,
        TourTitle: "Satay Ways (from Singapore)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 27121,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/17534/SatayWaysfromSingaporek1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/17534/SatayWaysfromSingaporek1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409f7",
            name: "Singapore",
            lemaxId: 40,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409e4",
            name: "Malaysia",
            lemaxId: 43,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 3,
        DurationDD: 20,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3f6d8b26d98556777bc06",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 843,
          PriceFormatted: "843.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3f7e6b26d98556777bc0d",
        TourID: 30909,
        TourTitle: "Lorenzo Express Ways (from Lima)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 21357,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/30909/expressloren-1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/30909/expressloren-1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a7",
            name: "Peru",
            lemaxId: 212,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bca21ebb6dcd102d6a865a1",
            name: "Bolivia",
            lemaxId: 122,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 2,
        DurationDD: 27,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3f7e6b26d98556777bc0e",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1350,
          PriceFormatted: "1,350.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3f9c6b26d98556777bc1d",
        TourID: 34698,
        TourTitle: "Conchita Ways (from Cancun)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 21232,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34698/conchita3_1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34698/conchita3_1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a5",
            name: "Mexico",
            lemaxId: 652,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409c1",
            name: "Belize",
            lemaxId: 608,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409d5",
            name: "Guatemala",
            lemaxId: 635,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 3,
        DurationDD: 15,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3f9c6b26d98556777bc1e",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 681,
          PriceFormatted: "681.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3fb04b26d98556777bc27",
        TourID: 34867,
        TourTitle: "Rice Field Bamba Express Ways (from Bangkok)",
        Type: 162,
        TypeName: "FIT tour",
        Galery: [
          {
            PhotoID: 27118,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34867/RiceFieldBambaExpressWaysfromBangkok1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34867/RiceFieldBambaExpressWaysfromBangkok1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 989,
            MainThemeName: "Backpacking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409c4",
            name: "Cambodia",
            lemaxId: 445,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b57340a00",
            name: "Vietnam",
            lemaxId: 580,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 3,
        DurationDD: 25,
        DurationHR: null,
        Style: [{ Permalink: "Travel-Passes", shortName: "TRAVEL PASSES" }],
        PriceInfo: {
          _id: "5db3fb04b26d98556777bc28",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 898,
          PriceFormatted: "898.00",
          BillingTypeName: "",
        },
      },
    ],
    s6BElements: [
      {
        _id: "5cc1f21e944ebe400cdd2e2d",
        key: "step-1",
        name: "STEP 1",
        __v: 0,
        description: "Buy your travel pass & add to your Bamba profile.",
        image: {
          public_id: "hk2ep0bxlffiyuepnou4",
          version: 1558477164,
          signature: "2ffdb538132dd96af247ee6b47d10100bba50927",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477164/hk2ep0bxlffiyuepnou4.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477164/hk2ep0bxlffiyuepnou4.png",
        },
      },
      {
        _id: "5cc1f29a944ebe400cdd2e2e",
        key: "step-2",
        name: "STEP 2",
        __v: 0,
        description: "Select your purchased travel pass to start customizing.",
        image: {
          public_id: "rllubec3rfzvsrli2w7i",
          version: 1558477031,
          signature: "be16e25aeeb0efe018a10a75d9caa33409c851eb",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477031/rllubec3rfzvsrli2w7i.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477031/rllubec3rfzvsrli2w7i.png",
        },
      },
      {
        _id: "5cc1f304944ebe400cdd2e2f",
        key: "step-3",
        name: "STEP 3",
        __v: 0,
        description: "Request your transport and activity departure dates.",
        image: {
          public_id: "xujhj8ubrh9a8fgpkgpt",
          version: 1558477044,
          signature: "0b4d385d2cf9a9447d6f50999b0445d564ae95ab",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477044/xujhj8ubrh9a8fgpkgpt.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477044/xujhj8ubrh9a8fgpkgpt.png",
        },
      },
      {
        _id: "5cc1f318944ebe400cdd2e30",
        key: "step-4",
        name: "STEP 4",
        __v: 0,
        description: "Change your dates and times before or during your trip.*",
        image: {
          public_id: "wdobkjtejbxkqlvzhxwg",
          version: 1558477054,
          signature: "205d32a3dccc4d303dd3c1a196d9c151e62e4f1e",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477054/wdobkjtejbxkqlvzhxwg.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477054/wdobkjtejbxkqlvzhxwg.png",
        },
      },
      {
        _id: "5cc1f32b944ebe400cdd2e31",
        key: "step-5",
        name: "STEP 5",
        __v: 0,
        description: "Book nights of accommodation via the Bamba app.",
        image: {
          public_id: "qgeouzrnsqknnarfkgbs",
          version: 1558477066,
          signature: "b5b4caeed2b0d4825020d4be13db760db8a50849",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477066/qgeouzrnsqknnarfkgbs.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477066/qgeouzrnsqknnarfkgbs.png",
        },
      },
      {
        _id: "5cc1f33f944ebe400cdd2e32",
        key: "step-6",
        name: "STEP 6",
        __v: 0,
        description: "Add additional experiences to your travel pass.",
        image: {
          public_id: "cgduc9hkwsygrietjqx6",
          version: 1558477075,
          signature: "743fefd6eecc5545d397793d04fe975052011808",
          width: 208,
          height: 210,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477075/cgduc9hkwsygrietjqx6.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1558477075/cgduc9hkwsygrietjqx6.png",
        },
      },
    ],
    s5themeGrid: [],
    s4elements: [
      {
        _id: "5cc1f3fc944ebe400cdd2e33",
        key: "flexibility",
        name: "Flexibility",
        image: [
          {
            public_id: "opfixwshf7ulcxyjtk5d",
            version: 1558477221,
            signature: "cfbf2da278bc03354421b8710a157eb905799b80",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477221/opfixwshf7ulcxyjtk5d.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477221/opfixwshf7ulcxyjtk5d.png",
            _id: "5ce479a64e8d75166d4a13ce",
          },
        ],
        __v: 4,
        description:
          "It’s up to you how long you want to stay in each destination and when you want to do your experiences. You’re free to change your plans as you go*",
      },
      {
        _id: "5cc1f428944ebe400cdd2e37",
        key: "create-your-own-trip",
        name: "Create your own trip",
        image: [
          {
            public_id: "wofx00kho3xpnfmw9bpv",
            version: 1558477242,
            signature: "8c41f5479a2885ddb33f5a815a3993e01378ac3f",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477242/wofx00kho3xpnfmw9bpv.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477242/wofx00kho3xpnfmw9bpv.png",
            _id: "5ce479ba4e8d75166d4a13cf",
          },
        ],
        __v: 2,
        description:
          "The App does more than just sort your transport. You can add, remove and change the dates* of your incredible experiences on there too.",
      },
      {
        _id: "5cc1f445944ebe400cdd2e3b",
        key: "tons-of-experiences",
        name: "Tons of Experiences",
        image: [
          {
            public_id: "wrtjynjrtkpoylsf5rzn",
            version: 1558477304,
            signature: "db1548000c2ef0eefbb2e2fb710a7300eeaa43f6",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477304/wrtjynjrtkpoylsf5rzn.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477304/wrtjynjrtkpoylsf5rzn.png",
            _id: "5ce479f84e8d75166d4a13d0",
          },
        ],
        __v: 2,
        description:
          "This is what your journey is about!  A mix of incredible day and multi-day trips of cultural, adventure, local experiences for a well-rounded journey!",
      },
      {
        _id: "5cc1f466944ebe400cdd2e3f",
        key: "book-your-own-accommodation",
        name: "Book your own Accommodation",
        image: [
          {
            public_id: "joqxghb95q7vzprzontc",
            version: 1558477340,
            signature: "a425a186f427f7f38976754b2ee090944f2b6af8",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477340/joqxghb95q7vzprzontc.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477340/joqxghb95q7vzprzontc.png",
            _id: "5ce47a1d4e8d75166d4a13d2",
          },
        ],
        __v: 3,
        description:
          "To keep your trip flexible you’ll be booking your own accommodation, but don’t worry, you can do that on our incredible App too!",
      },
      {
        _id: "5cc1f47f944ebe400cdd2e43",
        key: "transportation",
        name: "Transportation",
        image: [
          {
            public_id: "q0rm3lwf4hg5jr0taefu",
            version: 1558477494,
            signature: "bea975ec33e6f0983935654ef2c78ada42e93083",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477494/q0rm3lwf4hg5jr0taefu.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477494/q0rm3lwf4hg5jr0taefu.png",
            _id: "5ce47ab74e8d75166d4a13d3",
          },
        ],
        __v: 2,
        description:
          "Travelling by local transport is convenient. We have you covered while considering your travel plans and the duration of your stay.",
      },
      {
        _id: "5cc1f49a944ebe400cdd2e47",
        key: "bamba-app",
        name: "Bamba App",
        image: [
          {
            public_id: "zfj3jtpa7ok68w0caclr",
            version: 1558477859,
            signature: "0fe50254497505323187c855ec4156bfb0b6017b",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477859/zfj3jtpa7ok68w0caclr.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558477859/zfj3jtpa7ok68w0caclr.png",
            _id: "5ce47c244e8d75166d4a13d4",
          },
        ],
        __v: 2,
        description:
          "24/7 access to your trip details, transport itineraries and just about everything else! Your pocket guide to your dream adventure!",
      },
    ],
    s3image: {
      public_id: "x1mtddrfpezq2rxvgbht",
      version: 1558477130,
      signature: "2c796311e9a0c070449b7214cddd073f6f5476dc",
      width: 643,
      height: 380,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558477130/x1mtddrfpezq2rxvgbht.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558477130/x1mtddrfpezq2rxvgbht.png",
    },
    s2icons: [],
    s1landingCoverPhoto: {
      public_id: "qxbrxboiynfb4tqw0uj2",
      version: 1556571174,
      signature: "f90f87d7a2feb1b6f7f3a651290a2cb5ea137cb9",
      width: 2560,
      height: 1177,
      format: "jpg",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556571174/qxbrxboiynfb4tqw0uj2.jpg",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556571174/qxbrxboiynfb4tqw0uj2.jpg",
    },
    coverPhoto: {
      public_id: "vfguhothzf0ji3jdlj8s",
      version: 1567109691,
      signature: "4028ba70f15c6d6b040eb1292c666fe36c1dd446",
      width: 544,
      height: 404,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109691/vfguhothzf0ji3jdlj8s.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109691/vfguhothzf0ji3jdlj8s.png",
    },
  },
  {
    _id: "5bbe55b912b2fe13949894ed",
    title: "Independent Trips",
    lemaxId: 3,
    seoDescription: "Customizable Independent Trips\r\n",
    seoKeywords: "Customizable Independent Trips",
    shortDescription:
      "and experiences to navigate on your own on a pre-set customizable itinerary that can fit your exact needs.\r\n",
    shortName: "INDEPENDENT",
    Permalink: "Independent",
    staticContentId: "",
    s1buttonName: "All Independent trips",
    s1buttonUrl: "Independent",
    s1slogan:
      "Perfect style for those who want to travel solo or with their own travel partners.",
    s2text:
      "<p>Include all of your <strong>accommodation</strong>, <strong>transport</strong> and <strong>experiences</strong> on a pre-set trip <strong>that you can customize</strong> to perfectly suit your travel needs.</p>",
    s2title: "",
    s3text:
      "<p>Feel like a true traveller as you <strong>navigate your own way</strong> getting from one great destination to the next with you included transfers.</p>\r\n<p><strong>With plenty of free time</strong> to wander and loads of optional experiences, it&rsquo;s the ideal option for the independent traveller.&nbsp;</p>\r\n<p>Choose your favorite Bamba itinerary with its <strong>included experiences</strong> and add more days and activities as you please!</p>",
    s4title: "Independent trips Pros!",
    s5description:
      "<p>We have Independent trips of all styles, all over the world that you have the option to customize! We have <strong>surf</strong> trips, <strong>safaris</strong> and mountain <strong>treks, volunteers</strong> and <strong>culture</strong> tours, <strong>sailing</strong> and <strong>festivals</strong>, yoga and <strong>food</strong> tours. Do what you know you love, or discover something new! <strong>You can do it all with Bamba.</strong></p>",
    s5title: "Independent trips types",
    s6BTitle: "",
    s6buttonName: "",
    s6buttonUrl: "",
    s6description: "",
    s6title: "",
    s6BNote: "",
    s7title: "Top Independent trips",
    productDetailIcons: [
      {
        _id: "5c5dd30377ef8c4ba5eea05a",
        key: "stress-free-travel",
        description: "Have everything sorted before you go",
        __v: 0,
        image: {
          public_id: "ntsuvmzxouq7t7tpgop9",
          version: 1549652752,
          signature: "a46f2837b8c33616196edacf23349c0d19911686",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652752/ntsuvmzxouq7t7tpgop9.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652752/ntsuvmzxouq7t7tpgop9.svg",
        },
        name: "Stress-Free Travel",
      },
      {
        _id: "5c5dd32d77ef8c4ba5eea05b",
        key: "customization",
        description: "Find a trip that you like and customize it",
        __v: 0,
        image: {
          public_id: "zydf74i6fswgzpwzldc6",
          version: 1549652795,
          signature: "1135f26530d27b260ac50806d73e82172c58ffa9",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652795/zydf74i6fswgzpwzldc6.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652795/zydf74i6fswgzpwzldc6.svg",
        },
        name: "Customization",
      },
    ],
    s7recommendedForYou: [
      {
        _id: "5db3d112b26d98556777b5c7",
        TourID: 5175,
        TourTitle: "Cairo Experience 5D/4N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 6054,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5175/CairoExperience5D_provider_3.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5175/CairoExperience5D_provider_3.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 881, MainThemeName: "Cultural", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409cf",
            name: "Egypt",
            lemaxId: 35,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 5,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d112b26d98556777b5c8",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 454,
          PriceFormatted: "454.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d1c2b26d98556777b5e1",
        TourID: 5620,
        TourTitle: "Masai Mara Voluntour 10D/9N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28555,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5620/MasaiMaraVoluntour10D13.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5620/MasaiMaraVoluntour10D13.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 986,
            MainThemeName: "Volunteer / Voluntour",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409e1",
            name: "Kenya",
            lemaxId: 65,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 10,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d1c2b26d98556777b5e2",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1323,
          PriceFormatted: "1,323.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d32cb26d98556777b60b",
        TourID: 6197,
        TourTitle: "Mount Toubkal Trek 3D/2N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 6117,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6197/MountToubkalTrek3D_provider_1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6197/MountToubkalTrek3D_provider_1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 984,
            MainThemeName: "Hiking & Trekking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a6",
            name: "Morocco",
            lemaxId: 28,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 3,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d32cb26d98556777b60c",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 383,
          PriceFormatted: "383.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d5d3b26d98556777b651",
        TourID: 6996,
        TourTitle: "Chiang Mai & Elephant Conservation 3D/2N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 6748,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6996/ChiangMaiAndElephantsConservation3D__7.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6996/ChiangMaiAndElephantsConservation3D__7.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 983,
            MainThemeName: "Nature & Adventure",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865ab",
            name: "Thailand",
            lemaxId: 559,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 3,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d5d3b26d98556777b652",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 540,
          PriceFormatted: "540.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d5e6b26d98556777b657",
        TourID: 7079,
        TourTitle: "Japan Explorer 13D/12N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 6446,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/7079/JapanExplorer13D_provider_13.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/7079/JapanExplorer13D_provider_13.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 881, MainThemeName: "Cultural", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409de",
            name: "Japan",
            lemaxId: 494,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 13,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d5e6b26d98556777b658",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1909,
          PriceFormatted: "1,909.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d9bbb26d98556777b6d3",
        TourID: 15403,
        TourTitle: "Galapagos Island Hopping Adventure 5D/4N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 6952,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/15403/GalapagosIslandHoppingAdv5D_blogger_2.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/15403/GalapagosIslandHoppingAdv5D_blogger_2.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 983,
            MainThemeName: "Nature & Adventure",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409ce",
            name: "Ecuador",
            lemaxId: 196,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 5,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3d9bbb26d98556777b6d4",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1212,
          PriceFormatted: "1,212.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3e0d7b26d98556777b7af",
        TourID: 41237,
        TourTitle: "North Island Explorer 10D/9N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 7931,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/41237/Hobbiton_02_provider.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/41237/Hobbiton_02_provider.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 983,
            MainThemeName: "Nature & Adventure",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409eb",
            name: "New Zealand",
            lemaxId: 431,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 10,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3e0d7b26d98556777b7b0",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 896,
          PriceFormatted: "896.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3e658b26d98556777b83d",
        TourID: 47342,
        TourTitle:
          "Costa Rica Voluntour: Learn Spanish at Jungle Surf Camp 14D/13N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28689,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/47342/CostaRicaVoluntourLearnSpanishatJungleSurfCamp14D04.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/47342/CostaRicaVoluntourLearnSpanishatJungleSurfCamp14D04.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 986,
            MainThemeName: "Volunteer / Voluntour",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a7",
            name: "Peru",
            lemaxId: 212,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bd10f44d572272b573409c9",
            name: "Costa Rica",
            lemaxId: 594,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 2,
        DurationDD: 14,
        DurationHR: null,
        Style: [{ Permalink: "Independent", shortName: "INDEPENDENT" }],
        PriceInfo: {
          _id: "5db3e658b26d98556777b83e",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1546,
          PriceFormatted: "1,546.00",
          BillingTypeName: "",
        },
      },
    ],
    s6BElements: [],
    s5themeGrid: [],
    s4elements: [
      {
        _id: "5cc1f529944ebe400cdd2e4b",
        key: "customization",
        name: "Customization",
        image: [
          {
            public_id: "rk3fr7x3sdi6a2ix1ohr",
            version: 1558990191,
            signature: "4a288a3497e56008160e38ce5c5330b296d748e2",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990191/rk3fr7x3sdi6a2ix1ohr.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990191/rk3fr7x3sdi6a2ix1ohr.png",
            _id: "5cec4d6f4e8d75166d4a13d5",
          },
        ],
        __v: 2,
        description:
          "Select your accommodation based on your budget and add extra activities in each destination.",
      },
      {
        _id: "5cc1f54e944ebe400cdd2e4f",
        key: "transport-and-accommodation",
        name: "Transport and Accommodation",
        image: [
          {
            public_id: "p80ydtmr2nzngda4sw6k",
            version: 1558990462,
            signature: "524dd4dd142294141107662dd3f1448ae83329e1",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990462/p80ydtmr2nzngda4sw6k.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990462/p80ydtmr2nzngda4sw6k.png",
            _id: "5cec4e7f4e8d75166d4a13da",
          },
        ],
        __v: 2,
        description:
          "Have everything sorted before you go. Local transport, accommodation, and activities all pre-booked for a hassle-free trip.",
      },
      {
        _id: "5cc1f56c944ebe400cdd2e53",
        key: "tons-of-experiences-1",
        name: "Tons of Experiences",
        image: [
          {
            public_id: "nlrvgzsrsnr5wsdrptyn",
            version: 1558990897,
            signature: "e3eeff90150e73a5a9713673bd2255ae383c29b4",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990897/nlrvgzsrsnr5wsdrptyn.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990897/nlrvgzsrsnr5wsdrptyn.png",
            _id: "5cec50314e8d75166d4a13df",
          },
        ],
        __v: 4,
        description:
          "More experiences, more fun! Some curated by us and some chosen by you!",
      },
      {
        _id: "5cc1f588944ebe400cdd2e57",
        key: "guides-on-experiences-only",
        name: "Guides on Experiences Only",
        image: [
          {
            public_id: "q0vqdmffiyoquh9jmgyn",
            version: 1558990503,
            signature: "0d9762467c9f160ccb6b92b8a0bb23bf56207fd7",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990503/q0vqdmffiyoquh9jmgyn.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990503/q0vqdmffiyoquh9jmgyn.png",
            _id: "5cec4ea84e8d75166d4a13dc",
          },
        ],
        __v: 2,
        description:
          "Travel with great local guides on your included experiences only and have your free time to do with as you please!",
      },
      {
        _id: "5cc1f5a2944ebe400cdd2e5b",
        key: "freedom",
        name: "Freedom",
        image: [
          {
            public_id: "eu9mjkmx3gwasm62xhbj",
            version: 1558990517,
            signature: "2007b378b22e5ec1439fcfb1839fbbb2c64bba30",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990517/eu9mjkmx3gwasm62xhbj.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990517/eu9mjkmx3gwasm62xhbj.png",
            _id: "5cec4eb54e8d75166d4a13dd",
          },
        ],
        __v: 2,
        description:
          "Enjoy the liberty of having free time to explore at your own pace. It’s your holiday after all.",
      },
      {
        _id: "5cc1f5be944ebe400cdd2e5f",
        key: "travel-app",
        name: "Travel App",
        image: [
          {
            public_id: "fsigquwqxf2v84phtd1g",
            version: 1558990529,
            signature: "6ba6dee999bf576eef6b17ec9c3a3e553d704e59",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990529/fsigquwqxf2v84phtd1g.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990529/fsigquwqxf2v84phtd1g.png",
            _id: "5cec4ec14e8d75166d4a13de",
          },
        ],
        __v: 2,
        description:
          "Access all your accommodation, transport and activities on the go. You can even book services for before and after your trip.",
      },
    ],
    s3image: {
      public_id: "t3gurql3bgeb4tottxhm",
      version: 1558990446,
      signature: "1075f1f76fb319b3d63a2523f2ed1aba00556b00",
      width: 643,
      height: 380,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558990446/t3gurql3bgeb4tottxhm.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558990446/t3gurql3bgeb4tottxhm.png",
    },
    s2icons: [],
    s1landingCoverPhoto: {
      public_id: "zvtxvntp89b1qvkmrhww",
      version: 1556572306,
      signature: "1a33375a5508b904aab6a78ea5c5a71003878544",
      width: 2560,
      height: 1177,
      format: "jpg",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556572306/zvtxvntp89b1qvkmrhww.jpg",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556572306/zvtxvntp89b1qvkmrhww.jpg",
    },
    coverPhoto: {
      public_id: "fzv5qgx0g4vrvqigyn59",
      version: 1567109709,
      signature: "3384cbbe4cc20bee21242ebede72767cc1c0e845",
      width: 544,
      height: 404,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109709/fzv5qgx0g4vrvqigyn59.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109709/fzv5qgx0g4vrvqigyn59.png",
    },
  },
  {
    _id: "5bbe55e012b2fe13949894ee",
    title: "Group Adventures",
    lemaxId: 2,
    seoDescription: "Group Adventures\r\n",
    seoKeywords: "Group Adventures",
    shortDescription:
      "are designed to forget about all the travel logistics and let our guides take you to the best places with a group of like minded travelers.\r\n",
    shortName: "GROUP",
    Permalink: "Group-Adventures",
    staticContentId: "",
    s1buttonName: "All Group Adventures",
    s1buttonUrl: "Group-Adventures",
    s1slogan:
      "Embark on your dream adventure with people who are as excited as you to explore the world!",
    s2text:
      "<p>All the details are sorted out and you&rsquo;ll <strong>have a trip leader</strong> to take you to all of the <strong>best places</strong> with a group of <strong>like-minded travelers.</strong></p>",
    s2title: "",
    s3text:
      "<p>Experience more with passionate <strong>local guides</strong> who will make sure you get the most out of each destination!</p>\r\n<p>Our adventures are thrilling and<strong> memorable experiences</strong> that will teach you more about the destination and yourself!</p>\r\n<p>And if you want a guided trip with just your friends and/or family, you can always choose one of our <strong>private guided tours.</strong></p>",
    s4title: "Group Adventures Pros!",
    s5description: "",
    s5title: "",
    s6BTitle: "",
    s6buttonName: "Authentic backpacker experiences",
    s6buttonUrl:
      "https://bambatravel.com/shop/All/0/0/0/0/0/0/1009/0/0/Relevance-High-to-Low",
    s6description:
      "<p>Join a <strong>small group</strong> of like-minded <strong>18-30 somethings</strong> to explore unique destinations and discover the best they have to offer.&nbsp;</p>\r\n<p>A new kind of <strong>group adventure</strong> that not only lets you see the main highlights but also takes you on a journey to places that the typical backpacker doesn&rsquo;t get to see. Led by <strong>expert local Bamba guides</strong> these experiences will immerse you in the heart of your destination and allow you to find hidden gems, <strong>feel like a local</strong>, and revel in thrilling adventures.</p>",
    s6title: "Bamba Authentic Backpacker Adventures",
    s6BNote: "",
    s7title: "Top Group Adventures",
    productDetailIcons: [
      {
        _id: "5c5dd35577ef8c4ba5eea05c",
        key: "tour-leaders-guides",
        description: "Someone local to show you around",
        __v: 0,
        image: {
          public_id: "bkpookq0klla8tmbgdep",
          version: 1549652834,
          signature: "fcd15d5b7567f15bed3c61d616546703e36f5b47",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652834/bkpookq0klla8tmbgdep.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652834/bkpookq0klla8tmbgdep.svg",
        },
        name: "Tour Leaders / Guides",
      },
      {
        _id: "5c5dd39177ef8c4ba5eea05d",
        key: "group-travel",
        description: "Meet like-minded people and new friends",
        __v: 0,
        image: {
          public_id: "ettrg1w9u1df25tqaszo",
          version: 1549652894,
          signature: "3010bd27f356e78ad8be44ed36a82d2b1970fdcd",
          width: 40,
          height: 40,
          format: "svg",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652894/ettrg1w9u1df25tqaszo.svg",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1549652894/ettrg1w9u1df25tqaszo.svg",
        },
        name: "Group Travel",
      },
    ],
    s7recommendedForYou: [
      {
        _id: "5dc4e62095fc0160d28a35bb",
        TourID: 425,
        TourTitle:
          "Inca Trail Trek to Machu Picchu 4D/3N (Start Trek on Day 1)",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 25187,
            PhotoUrl:
              "https://bambatest.itravelsoftware.com/fotografije_itravel/425/IncaTrailTrekTripadv_12.jpg",
            ThumbnailUrl:
              "https://bambatest.itravelsoftware.com/fotografije_itravel/425/IncaTrailTrekTripadv_12.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 984,
            MainThemeName: "Hiking & Trekking",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a7",
            name: "Peru",
            lemaxId: 212,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 4,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5dc4e62095fc0160d28a35bc",
          Discount: { Amount: 780, Percent: "10", Discount: 78, Total: 702 },
          SpecialOffer: null,
          PriceOriginal: 780,
          PriceOriginalFormatted: "780.00",
          Price: 702,
          PriceFormatted: "702.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d129b26d98556777b5c9",
        TourID: 5200,
        TourTitle: "Croatia Sailing Adventure 8D/7N (Split to Dubrovnik)",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 3074,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5200/CroatiaSalingAdventure8D_provider_3.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/5200/CroatiaSalingAdventure8D_provider_3.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 243, MainThemeName: "Sailing", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5d010e595a5ed46475bd008f",
            name: "Croatia",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            __v: 0,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            lemaxId: 293,
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 8,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3d129b26d98556777b5ca",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 871,
          PriceFormatted: "871.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d548b26d98556777b643",
        TourID: 6873,
        TourTitle:
          "Sedona, Monument Valley & Antelope Canyon Experience 3D/2N (from Las Vegas)",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28219,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6873/SedonaMonumentValleyAntelopeCanyonExperience3D01.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/6873/SedonaMonumentValleyAntelopeCanyonExperience3D01.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 983,
            MainThemeName: "Nature & Adventure",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409ff",
            name: "USA",
            lemaxId: 2,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 3,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3d548b26d98556777b644",
          Discount: { Amount: "635", Percent: "20", Discount: 127, Total: 508 },
          SpecialOffer: null,
          PriceOriginal: 635,
          PriceOriginalFormatted: "635.00",
          Price: 508,
          PriceFormatted: "508.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3d8e6b26d98556777b6b9",
        TourID: 10298,
        TourTitle: "Mount Kilimanjaro Bamba Trek (Machame Route) 9D/8N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28890,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/10298/MountKilimanjaroBambaTrek9D4.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/10298/MountKilimanjaroBambaTrek9D4.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 1011,
            MainThemeName: "Bamba Group Adventure",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409fa",
            name: "Tanzania",
            lemaxId: 89,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 9,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3d8e6b26d98556777b6ba",
          Discount: {
            Amount: "2426",
            Percent: "20",
            Discount: 485.20000000000005,
            Total: 1940.8,
          },
          SpecialOffer: null,
          PriceOriginal: 2426,
          PriceOriginalFormatted: "2426.00",
          Price: 1940.8,
          PriceFormatted: "1940.80",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3da6eb26d98556777b6e9",
        TourID: 21607,
        TourTitle:
          "Highlights of Tanzania Safari 6D/5N (Tarangire, Serengeti & Ngorongoro)",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 29155,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/21607/HighlightsofTanzaniaSafari6DTarangireSerengetiNgorongoro1.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/21607/HighlightsofTanzaniaSafari6DTarangireSerengetiNgorongoro1.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 985, MainThemeName: "Safari", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409fa",
            name: "Tanzania",
            lemaxId: 89,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 6,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3da6eb26d98556777b6ea",
          Discount: {
            Amount: "1619",
            Percent: "20",
            Discount: 323.8,
            Total: 1295.2,
          },
          SpecialOffer: null,
          PriceOriginal: 1619,
          PriceOriginalFormatted: "1619.00",
          Price: 1295.2,
          PriceFormatted: "1295.20",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3db7bb26d98556777b70d",
        TourID: 29805,
        TourTitle:
          "Authentic Backpacker Peru & Bolivia Group Adventure 14D/13N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28641,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/29805/AuthenticBackpackerPeruBoliviaGroupAdventure14D02.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/29805/AuthenticBackpackerPeruBoliviaGroupAdventure14D02.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          {
            MainThemeID: 1009,
            MainThemeName: "Bamba Authentic Backpacker",
            MainThemeValue: "1",
          },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a1",
            name: "Bolivia",
            lemaxId: 122,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
          {
            _id: "5bca21ebb6dcd102d6a865a7",
            name: "Peru",
            lemaxId: 212,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 2,
        DurationDD: 14,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3db7bb26d98556777b70e",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 1460,
          PriceFormatted: "1,460.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3e04bb26d98556777b78b",
        TourID: 34238,
        TourTitle: "Day of the Dead Experience 5D/4N (from Mexico City)",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 28357,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34238/DayoftheDeadExperience5D18.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/34238/DayoftheDeadExperience5D18.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 881, MainThemeName: "Cultural", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5bca21ebb6dcd102d6a865a5",
            name: "Mexico",
            lemaxId: 652,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 5,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3e04bb26d98556777b78c",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 452,
          PriceFormatted: "452.00",
          BillingTypeName: "",
        },
      },
      {
        _id: "5db3e67ab26d98556777b843",
        TourID: 47738,
        TourTitle: "Beijing to Shanghai Group Adventure 11D/10N",
        Type: 122,
        TypeName: "Simple tour",
        Galery: [
          {
            PhotoID: 23158,
            PhotoUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/47738/BeijingtoShanghai GroupAdventure11D-6.jpg",
            ThumbnailUrl:
              "https://bamba.itravelsoftware.com/fotografije_itravel/47738/BeijingtoShanghai GroupAdventure11D-6.jpg",
            Position: 0,
            ObjectID: 0,
            UnitID: 0,
            PackageTourID: 0,
            TransportationID: 0,
          },
        ],
        MainTheme: [
          { MainThemeID: 881, MainThemeName: "Cultural", MainThemeValue: "1" },
        ],
        Countries: [
          {
            _id: "5bd10f44d572272b573409c7",
            name: "China",
            lemaxId: 450,
            __v: 1,
            Permalink: "",
            coin: "",
            hashtag: "",
            language: "",
            seoDescription: "",
            seoKeywords: "",
            shortDescription: "",
            topFlexible: [],
            topGroup: [],
            topIndependent: [],
            tripInfo: "",
            videoYoutube: "",
            weather: "",
            wordpressCategory: "",
          },
        ],
        NumberOfCountries: 1,
        DurationDD: 11,
        DurationHR: null,
        Style: [{ Permalink: "Group-Adventures", shortName: "GROUP" }],
        PriceInfo: {
          _id: "5db3e67ab26d98556777b844",
          Discount: null,
          SpecialOffer: null,
          PriceOriginal: null,
          PriceOriginalFormatted: null,
          Price: 944,
          PriceFormatted: "944.00",
          BillingTypeName: "",
        },
      },
    ],
    s6BElements: [],
    s6image: {
      public_id: "vaexq3gzuelgfo7ux6rq",
      version: 1561555849,
      signature: "df0ab1b14665fd851702da4dfe4b68b03b80ef3c",
      width: 525,
      height: 411,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1561555849/vaexq3gzuelgfo7ux6rq.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1561555849/vaexq3gzuelgfo7ux6rq.png",
    },
    s5themeGrid: [],
    s4elements: [
      {
        _id: "5cc1f626944ebe400cdd2e63",
        key: "tour-leaders-guides",
        name: "Tour Leaders / Guides",
        image: [
          {
            public_id: "jornm1yde0hf1bm4rwmj",
            version: 1558990339,
            signature: "436b8d6cbe3930f8e4f24e5e89251d09e5ac4bcd",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990339/jornm1yde0hf1bm4rwmj.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990339/jornm1yde0hf1bm4rwmj.png",
            _id: "5cec4e044e8d75166d4a13d7",
          },
        ],
        __v: 2,
        description:
          "Someone local to show you the ropes and make sure you make the most of every destination and experience!",
      },
      {
        _id: "5cc1f643944ebe400cdd2e67",
        key: "group-travel",
        name: "Group Travel",
        image: [
          {
            public_id: "a5wkrnocmca7ddunzrxm",
            version: 1558990360,
            signature: "4afb9a3014b6d253d2a8d3a349cc859b1c4a146b",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990360/a5wkrnocmca7ddunzrxm.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990360/a5wkrnocmca7ddunzrxm.png",
            _id: "5cec4e184e8d75166d4a13d8",
          },
        ],
        __v: 2,
        description:
          "A chance to meet new people and make new friends, while still maintaining your freedom and independence!",
      },
      {
        _id: "5cc1f65b944ebe400cdd2e6b",
        key: "tons-of-experiences-2",
        name: "Tons of Experiences",
        image: [
          {
            public_id: "xrfeqqq7eu2hevk7wwp4",
            version: 1560810356,
            signature: "b317378919eec974eee37b40dd621ef2413ae073",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1560810356/xrfeqqq7eu2hevk7wwp4.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1560810356/xrfeqqq7eu2hevk7wwp4.png",
            _id: "5d0813745a5ed46475bd00a0",
          },
        ],
        __v: 2,
        description:
          "The best bit! And they are the best! A mix of cultural, adventure, and local experiences to round out your trip nicely!",
      },
      {
        _id: "5cc1f676944ebe400cdd2e6f",
        key: "accommodation",
        name: "Accommodation",
        image: [
          {
            public_id: "qospf2orougpat6goxoe",
            version: 1558990409,
            signature: "7101f945eee003a3db3792298211922f694458d7",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990409/qospf2orougpat6goxoe.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990409/qospf2orougpat6goxoe.png",
            _id: "5cec4e494e8d75166d4a13d9",
          },
        ],
        __v: 2,
        description:
          "Sorting out where you’re going to stay it’s not fun, but we’ve done the research and we have solved it for you.",
      },
      {
        _id: "5cc1f68f944ebe400cdd2e73",
        key: "transportation-1",
        name: "Transportation",
        image: [
          {
            public_id: "ldlpaxdbr3n73dnzuqrt",
            version: 1560810453,
            signature: "39edfe7d7f35a5716051898abf75094d859ebe06",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1560810453/ldlpaxdbr3n73dnzuqrt.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1560810453/ldlpaxdbr3n73dnzuqrt.png",
            _id: "5d0813d65a5ed46475bd00a1",
          },
        ],
        __v: 2,
        description:
          "We’ll get you from A to B, and C, D and E as well. You’ll have to sort out your own flights there though.",
      },
      {
        _id: "5cc1f6a5944ebe400cdd2e77",
        key: "meals",
        name: "Meals",
        image: [
          {
            public_id: "idfbqwvv9aeior0cqkyf",
            version: 1558990324,
            signature: "9944d7da3f1227e189152154c5769789f411b1a8",
            width: 310,
            height: 200,
            format: "png",
            resource_type: "image",
            url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990324/idfbqwvv9aeior0cqkyf.png",
            secure_url:
              "https://res.cloudinary.com/keystone-demo/image/upload/v1558990324/idfbqwvv9aeior0cqkyf.png",
            _id: "5cec4df44e8d75166d4a13d6",
          },
        ],
        __v: 2,
        description:
          "Tasting local dishes and flavors is an essential part of any experience. We'll make sure you're filling your belly.",
      },
    ],
    s3image: {
      public_id: "cdmsdskkmxvhn9lkqac4",
      version: 1558990300,
      signature: "2274326f229370067719f01e83aec56f87200a88",
      width: 643,
      height: 380,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558990300/cdmsdskkmxvhn9lkqac4.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1558990300/cdmsdskkmxvhn9lkqac4.png",
    },
    s2icons: [],
    s1landingCoverPhoto: {
      public_id: "ypne3u8dnqmijy0zfwsy",
      version: 1556573233,
      signature: "92595099386c169d4a83657a928c7f015a7c1b27",
      width: 1366,
      height: 628,
      format: "jpg",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556573233/ypne3u8dnqmijy0zfwsy.jpg",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556573233/ypne3u8dnqmijy0zfwsy.jpg",
    },
    coverPhoto: {
      public_id: "nualkz0gl0qchppxo47f",
      version: 1567109720,
      signature: "0d7ab50abbd7da4babf108fff01d852b3383a7f8",
      width: 544,
      height: 404,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109720/nualkz0gl0qchppxo47f.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109720/nualkz0gl0qchppxo47f.png",
    },
  },
  {
    _id: "5bbe560412b2fe13949894ef",
    title: "Tailor-made",
    lemaxId: 4,
    seoDescription: "Tailor-made\r\n",
    seoKeywords: "Tailor-made",
    shortDescription:
      "your experience by letting us know what you like, how you like it and let our travel experts design a dream trip for you and your friends.\r\n",
    shortName: "Tailor-made",
    Permalink: "Tailor-Made",
    staticContentId: "",
    s1buttonName: "CREATE YOUR TRIP",
    s1buttonUrl: "Tailor-Made",
    s1slogan:
      "Create a tailor-made trip to experience the world the way you want it!",
    s2text: "",
    s2title: "Customize the trip of your dreams:",
    s3text:
      '<h4 style="font-family: Khand; font-weight: bold; font-size: 30px;">Tailor made trips pros!</h4>\r\n<p>Our unique, hand-crafted and experience-driven adventures let you enjoy every destination to the fullest. You\'ll <strong>meet locals</strong>, immerse yourself in nature and <strong>learn about new cultures</strong>.</p>\r\n<p><strong>Your full satisfaction</strong> is our priority!&nbsp;bamba allows you to book securely and <strong>travel easily</strong> with expert staff and a dedicated customer service team.</p>',
    s4title: "",
    s5description: "",
    s5title: "",
    s6BTitle: "",
    s6buttonName: "",
    s6buttonUrl: "",
    s6description: "",
    s6title: "",
    s6BNote: "",
    s7title: "",
    productDetailIcons: [],
    s7recommendedForYou: [],
    s6BElements: [],
    s5themeGrid: [],
    s4elements: [],
    s3image: {
      public_id: "tbjvuxmo8874fytbg0pe",
      version: 1561653056,
      signature: "dfb76929f61878b0b97792f9dcd9687fa24887e9",
      width: 643,
      height: 380,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1561653056/tbjvuxmo8874fytbg0pe.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1561653056/tbjvuxmo8874fytbg0pe.png",
    },
    s2icons: [
      {
        _id: "5c63425d0369a12602848609",
        key: "01-tell-us-about-your-travel-plans",
        name: "01 Tell us about your travel plans",
        __v: 0,
        description:
          '<p>Answer the quiz or&nbsp;<a title="describe your trip" href="https://bambaworld.com/tailor-made">describe your trip</a></p>',
        image: {
          public_id: "i0d0lrqfsolx9hiyhf2y",
          version: 1550008951,
          signature: "60739f1916e6f94db5b10ce815113c8b69191a2e",
          width: 311,
          height: 308,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550008951/i0d0lrqfsolx9hiyhf2y.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550008951/i0d0lrqfsolx9hiyhf2y.png",
        },
      },
      {
        _id: "5c6342860369a1260284860a",
        key: "02-chat-with-a-bamba-travel-expert",
        name: "02 Chat with a Bamba travel expert",
        __v: 0,
        description: "<p>To refine your itinerary until you are happy</p>",
        image: {
          public_id: "a9rqvfqeq9m7q4rjnzpo",
          version: 1550008986,
          signature: "f9a06555d4d456952c4ca063a44ee80e73339297",
          width: 321,
          height: 294,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550008986/a9rqvfqeq9m7q4rjnzpo.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550008986/a9rqvfqeq9m7q4rjnzpo.png",
        },
      },
      {
        _id: "5c6342a50369a1260284860b",
        key: "03-book-the-trip-of-your-dreams",
        name: "03 Book the trip of your dreams",
        __v: 0,
        description: "<p>Get your perfect itinerary &amp; experience life!</p>",
        image: {
          public_id: "iqsb7ojvsonqcanrp8pp",
          version: 1550009010,
          signature: "51a3ebf71c2cd21445687c3c6d45f919b2a3b32f",
          width: 256,
          height: 224,
          format: "png",
          resource_type: "image",
          url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550009010/iqsb7ojvsonqcanrp8pp.png",
          secure_url:
            "https://res.cloudinary.com/keystone-demo/image/upload/v1550009010/iqsb7ojvsonqcanrp8pp.png",
        },
      },
    ],
    s1landingCoverPhoto: {
      public_id: "gwbdcvvxbi2atyx35rft",
      version: 1556572650,
      signature: "ca7354c64c28c485923edc7adbeb2038d80d3976",
      width: 2560,
      height: 1177,
      format: "jpg",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556572650/gwbdcvvxbi2atyx35rft.jpg",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1556572650/gwbdcvvxbi2atyx35rft.jpg",
    },
    coverPhoto: {
      public_id: "ho7ajkhzfrr7v7h3iy33",
      version: 1567109732,
      signature: "55e656924d99344953fac23565a73b2c6933ffc8",
      width: 544,
      height: 404,
      format: "png",
      resource_type: "image",
      url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109732/ho7ajkhzfrr7v7h3iy33.png",
      secure_url:
        "https://res.cloudinary.com/keystone-demo/image/upload/v1567109732/ho7ajkhzfrr7v7h3iy33.png",
    },
  },
];
