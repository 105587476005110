import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ConfirmComponent} from './confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  /*private readonly defaultConfig = {yes: 'delete', no: 'no'};*/
  private readonly defaultConfig = {yes: 'OK' , no: 'Cancel'};

  constructor(private dialog: MatDialog) {
  }

  open(title: string, message: string, config?: {
    yes: string,
    no: string
  }): Promise<boolean> {
    const data = {message, title, config: config ? config : this.defaultConfig};
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {...data}
    });
    return dialogRef.afterClosed().toPromise();
  }
}
