import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { SearchSelect } from "../parallax/parallax.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Search } from "../../models/search.model";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Homev2Service } from "../../services/homev2.service";
import { IHome } from "../../models/homev2.interface";
import {
  ISearchAutocomplete,
  Product,
} from "../../models/search-autocomplete.interface";
import { Router } from "@angular/router";
import * as slugify from "@sindresorhus/slugify";
import { QuitSpacesService } from "../../services/quit-spaces.service";
import { generateProductUrl } from "../../const";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: "app-search-autocomplete",
  templateUrl: "./search-autocomplete.component.html",
  styleUrls: ["./search-autocomplete.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchAutocompleteComponent implements OnInit {
  @Input() onAgents: boolean;
  logged = false;
  Object = Object;
  search: Search;
  resultSearch: ISearchAutocomplete;
  placeholderText = "Where's your next experience?";
  travelStyleAgents: SearchSelect[] = [
    { value: "Transport", viewValue: "Transport and experiences travel pass" },
    { value: "Independent", viewValue: "Independent and customizable trips" },
    { value: "Group", viewValue: "Group travel with great guides" },
  ];

  travelStyle: SearchSelect[] = [
    { value: "Transport", viewValue: "Transport and experiences travel pass" },
    { value: "Independent", viewValue: "Independent and customizable trips" },
    { value: "Group", viewValue: "Group travel with great guides" },
    { value: "TeilorMade", viewValue: "Unique, expertly tailor-made trips" },
  ];

  howLong: SearchSelect[] = [
    { value: "1", viewValue: "1 day" },
    { value: "2-7", viewValue: "2-7 days" },
    { value: "8-14", viewValue: "8-14 days" },
    { value: "15-21", viewValue: "15-21 days" },
    { value: ">21", viewValue: "> 21 days" },
  ];

  browserForm: FormGroup;

  unsubscribe = new Subject();

  @Input() home: IHome;

  type: string;

  textCloneBrowseText: string;

  constructor(
      private fb: FormBuilder,
      private homeService: Homev2Service,
      private router: Router,
      private _quitSpacesService: QuitSpacesService,
      private authService: AuthenticationService
  ) {
    this.logged = AuthenticationService.isAuthenticated();
    this.authService.getIsAuthenticated()
        .subscribe(value => {
          this.logged = AuthenticationService.isAuthenticated();
        });
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.browserForm = this.fb.group({
      browseText: [
        null,
        Validators.pattern("([a-zA-Z]|á|é|í|ó|ú|Á|É|Í|Ó|Ú| )*"),
      ],
      travel: [],
      howLong: [],
    });
    this.browserForm
        .get("browseText")
        .valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(300))
        .subscribe((value) => {
          this.autocomplete(value);
          if (value && typeof value === "string") {
            this.textCloneBrowseText = value;
          }
        });
  }

  async autocomplete(query: string): Promise<void> {
    this.resultSearch = await this.homeService
        .searchAutocomplete(query)
        .toPromise();
  }

  goToProduct(product: Product, type: string, viewAll = false): boolean {
    if (type === "products") {
      if (viewAll) {
        this.router.navigate([
          "shop",
          "All",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          "0",
          this.textCloneBrowseText,
          "Relevance-High-to-Low",
        ]);
      } else {
        this.router.navigate(generateProductUrl(product));
      }
      return true
    }else {
      if(this.logged) return true
    }

  }

  explore(): void {
    let type = this.type;
    if (!type) {
      type = "";
    }
    const product: Product = this.browserForm.get("browseText").value;
    if (this.isTailorMade(this.browserForm.get("travel").value)) {
      this.router.navigate(["tailor-made"]);
    } else {
      const url: string[] = [];
      if (type !== "Trips" || product.name.toLowerCase() === "view all") {
        // shop

        url.push("shop");
        url.push(
            this.browserForm.controls["travel"].value !== ""
                ? this.getIdTravelStyle(this.browserForm.controls["travel"].value)
                : "All"
        );
        let txt = "0";
        let txt2 = "0";
        if (
            type &&
            (type.toUpperCase() === "REGIONS" ||
                type.toUpperCase() === "COUNTRIES")
        ) {
          txt = type;
          txt2 = this.displayFn(product);
        }
        url.push(txt);
        url.push(txt2);
        url.push(
            this.browserForm.controls["howLong"].value !== ""
                ? this.getIdHowLong(this.browserForm.controls["howLong"].value)
                : "0"
        );
        url.push("0");
        url.push("0");
        url.push("0");
        txt = "0";
        txt2 = "0";
        if (type && type.toUpperCase() === "THEMES") {
          txt = product.lemaxId[0];
          localStorage.setItem("headerTxt", product.name);
        }
        url.push(txt);
        url.push("0");
        if (type === "") {
          url.push(
              this.browserForm.controls["browseText"].value === ""
                  ? "0"
                  : this.browserForm.controls["browseText"].value
          );
        } else {
          let ProductName = this.displayFn(product);
          if (ProductName.toLowerCase() === "view all") {
            url.push(product.name);
          } else {
            url.push("0");
          }
        }
        url.push("Relevance-High-to-Low");
      }
      this.router.navigate(url.map((q) => (!q ? "0" : q)));
      // this.browserForm.setValue(null);
    }
  }

  isTailorMade(value: string): boolean {
    return value === "TeilorMade";
  }

  getIdTravelStyle(ts: string): string {
    switch (ts) {
      case "Transport and experiences travel pass":
        ts = "Travel-Passes";
        break;
      case "Independent and customizable trips":
        ts = "Independent";
        break;
      case "Group travel with great guides":
        ts = "Group";
        break;
      default:
        ts = "All";
    }
    return this._quitSpacesService.quitSpaces(ts);
  }

  getIdHowLong(hl: string): string {
    switch (hl) {
      case "1 day":
        hl = "1,1";
        break;
      case "2-7 days":
        hl = "2,7";
        break;
      case "8-14 days":
        hl = "8,14";
        break;
      case "15-21 days":
        hl = "15,21";
        break;
      case "> 21 days":
        hl = "22,10000";
        break;
      default:
        hl = "0";
    }
    return hl;
  }

  displayFn(product): string | undefined {
    if (product && typeof product === "object") {
      let name = product.name || product.TourTitle || product.title;
      return name;
    } else {
      return product;
    }
  }
}
