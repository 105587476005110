<img
  *ngIf="logged"
  [useSrcset]="true"
  [lazyLoad]="logged ? imagesLogged : images"
  alt="parallax" [ngClass]="logged ?'h-img-logged':'h-img-logged-out'" class="img-responsivas position-absolute">
  <div *ngIf="!logged" style="z-index: -1; position: relative;">
    <div class="container-video">
      <div class="center-container">
        <div class="height overlay"></div>
        <div class="video-content">
          <app-vjs-player
            lazyLoad
            [options]="optionsVjs"
          ></app-vjs-player>
        </div>
      </div>
    </div>
  </div>
<div>
  <app-search-autocomplete [onAgents]="logged"></app-search-autocomplete>
</div>

