import {NgModule} from '@angular/core';
import {BambaSharedModule} from '../../shared/bamba-shared.module';
import {ParallaxComponent} from './parallax.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ResponsiveModule} from 'ngx-responsive';
import {SkeletonsLoaderModule} from '../skeletons-loader/skeletons-loader.module';

@NgModule({
  declarations: [ParallaxComponent],
  imports: [
    BambaSharedModule,
    ReactiveFormsModule,
    ResponsiveModule,
    SkeletonsLoaderModule,
  ],
  exports: [ParallaxComponent]
})
export class ParallaxModule {
}
