import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Observable, of} from 'rxjs';
import {SistemaService} from '../services/sistema/sistema.service';
import {Country, Destinations, Theme, Themes} from '../models/header/header.model';
import {map, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ThemesMenuService {

  destinations: Destinations[] = [];
  destinationsRes: any[] = [];
  themes: Themes[] = [];
  themesRes: any[] = [];
  url: string;

  private readonly key_theme_storage = '_theme_storage';

  private readonly key_destinations_storage = '_destinations_storage';


  constructor(private http: HttpClient, private _sistemaService: SistemaService) {
    this.url = _sistemaService.url;
  }

  getThemes(): Observable<any[]> {
    const homeStorage = this.getThemeStorage();
    if (homeStorage) {
      return of(homeStorage);
    } else {
      return this.http.get(`${this.url}/get-theme-menu/`).pipe(map(this.extractThemes),
        tap(theme => sessionStorage.setItem(this.key_theme_storage, JSON.stringify(theme))));
    }
  }

  private getThemeStorage() {
    return sessionStorage.getItem(this.key_theme_storage) ? JSON.parse(sessionStorage.getItem(this.key_theme_storage)) : null;
  }


  extractThemes(res: any) {
    this.themes = [];
    this.themesRes = res;
    localStorage.setItem('themes', JSON.stringify(res));
    for (let i = 0; i < res.length; i++) {
      let tmp: Themes = {
        _id: res[i]._id,
        name: res[i].name,
        themes: []
      };
      let themes: Theme[] = [];
      if (res[i].themes) {
        for (let k = 0; k < res[i].themes.length; k++) {
          if (k == 5) {
            break;
          } else {
            themes.push(res[i].themes[k]);
          }
        }
      }
      tmp.themes = themes;
      tmp.themes.sort(function (a, b) {
        return a.order - b.order;
      });
      this.themes.push(tmp);
    }
    return this.themes;
  }

  seeAllThemes(id: string): Themes {
    this.themesRes = eval(localStorage.getItem('themes'));
    let themesSelected: Themes = {
      _id: '',
      name: '',
      themes: []
    }
    for (let i = 0; i < this.themesRes.length; i++) {
      if (this.themesRes[i]._id == id) {
        let tmp: Themes = {
          _id: this.themesRes[i]._id,
          name: this.themesRes[i].name,
          themes: []
        };
        for (let j = 0; j < this.themesRes[i].themes.length; j++) {
          tmp.themes.push(this.themesRes[i].themes[j]);
        }
        themesSelected = tmp;
        break;
      }
    }
    return themesSelected;
  }

  getDestinations(): Observable<any[]> {
    if (sessionStorage.getItem(this.key_destinations_storage)) {
      return of(JSON.parse(sessionStorage.getItem(this.key_destinations_storage)));
    } else {
      return this.http.get(`${this.url}/get-region-menu`)
        .pipe(map(this.extractDestinations),
          tap(destinations => sessionStorage.setItem(this.key_destinations_storage, JSON.stringify(destinations))));
    }
  }

  extractDestinations(res: any) {
    this.destinations = [];
    this.destinationsRes = res;
    localStorage.setItem('destinations', JSON.stringify(res));

    for (let i = 0; i < res.length; i++) {
      let tmp: Destinations = {
        id: res[i]._id,
        title: res[i].title,
        order: res[i].order,
        regionKeyCountry: [],
        allCountry: []
      };
      let regionKeyCountry: Country[] = [];
      for (let k = 0; k < res[i].regionKeyCountry.length; k++) {
        if (k == 5) {
          break;
        } else {
          regionKeyCountry.push({
            name: res[i].regionKeyCountry[k].name,
            _id: res[i].regionKeyCountry[k]._id,
            lemaxId: res[i].regionKeyCountry[k].lemaxId
          });
        }
      }
      tmp.regionKeyCountry = regionKeyCountry;
      let allCountry: Country[] = [];
      for (let k = 0; k < res[i].allCountry.length; k++) {
        allCountry.push({
          name: res[i].allCountry[k].name,
          _id: res[i].allCountry[k]._id,
          lemaxId: res[i].allCountry[k].lemaxId
        });
      }
      tmp.allCountry = allCountry;
      this.destinations.push(tmp);
    }
    this.destinations.sort(function (a, b) {
      return a.order - b.order;
    });
    return this.destinations;
  }


  seeAllDestinations(id: string): Destinations {
    this.destinationsRes = eval(localStorage.getItem('destinations'));
    let destinationsSelected: Destinations = {
      id: '',
      title: '',
      order: 0,
      regionKeyCountry: [],
      allCountry: []
    };
    for (let i = 0; i < this.destinationsRes.length; i++) {
      if (this.destinationsRes[i]._id == id) {
        let tmp: Destinations = {
          id: this.destinationsRes[i]._id,
          title: this.destinationsRes[i].title,
          order: this.destinationsRes[i].order,
          regionKeyCountry: [],
          allCountry: []
        };
        for (let k = 0; k < this.destinationsRes[i].allCountry.length; k++) {
          tmp.allCountry.push({
            name: this.destinationsRes[i].allCountry[k].name,
            _id: this.destinationsRes[i].allCountry[k]._id,
            lemaxId: this.destinationsRes[i].allCountry[k].lemaxId
          });
        }
        destinationsSelected = tmp;
        break;
      }
    }
    return destinationsSelected;
  }

  getCountDestinations(): number {
    this.destinationsRes = eval(localStorage.getItem('destinations'));
    return this.destinationsRes.length;
  }

}
